import { useQuery } from "@shane32/graphql";
import { ColDef } from "ag-grid-community";
import { AgGridReact } from "ag-grid-react";
import { PropsWithChildren, useEffect, useLayoutEffect, useRef, useState } from "react";
import { Card } from "react-bootstrap";
import { useParams } from "react-router-dom";
import Loading from "../../components/loading/Loading";
import ErrorDisplay from "../../components/misc/ErrorDisplay";
import PageHeader from "../../components/pageheader/PageHeader";
import useQueryString from "../../hooks/useQueryString";
import {
    IReport,
    IReportExecuteResult,
    IReportQueryResult,
    IReportQueryVariables,
    IReportExecuteVariables,
    ReportExecuteMutation,
    ReportQuery,
    IDataRow,
    IDataSet,
} from "./ReportQueries";
import { ConvertReportParameter } from "./ReportsIndex";

interface IProps {
    reportId: string;
}

const ReportView = (params: PropsWithChildren<IProps>) => {
    const reportId = useParams<{ id: string }>().id;
    const { data, error, refetch } = useQuery<IReportQueryResult, IReportQueryVariables>(ReportQuery, {
        fetchPolicy: "cache-and-network",
        variables: { id: reportId },
    });
    return (
        <>
            <PageHeader>Reports{data ? " - " + data.report.name : null}</PageHeader>
            {error ? <ErrorDisplay onClick={refetch}>{error.message}</ErrorDisplay> : null}
            {data ? <ReportView2 report={data.report} /> : <Loading />}
        </>
    );
};

interface IProps2 {
    report: IReport;
}

const ReportView2 = (params: PropsWithChildren<IProps2>) => {
    const [gridHeight, setGridHeight] = useState(300);
    const cardRef = useRef<HTMLDivElement | null>(null);
    const gridRef = useRef<HTMLDivElement | null>(null);
    const queryParams = useQueryString();

    // Calculate the height based on the window's size
    const calculateHeight = () => {
        // Calculate the available height as a number
        const cardY = cardRef.current ? cardRef.current.getBoundingClientRect().top : 0;
        const cardMargin =
            cardRef.current && gridRef.current
                ? cardRef.current.getBoundingClientRect().height - gridRef.current.getBoundingClientRect().height
                : 0;
        const windowHeight = window.innerHeight;
        const availableHeight = windowHeight - cardY - cardMargin - 50;

        // Enforce a minimum height of 100px
        setGridHeight(availableHeight < 100 ? 100 : availableHeight);
    };

    useEffect(() => {
        // Update the grid height when the window resizes
        window.addEventListener("resize", calculateHeight);

        return () => {
            window.removeEventListener("resize", calculateHeight);
        };
    }, []);

    const props: Record<string, string | number | boolean> = {};
    params.report.metadata?.parameters?.forEach((param) => {
        const name = param.name || "unknown";
        const value = queryParams.getString(name) || "";
        const newValue = ConvertReportParameter(param, value);
        if (newValue !== undefined) {
            props[name] = newValue;
        }
    });
    const { data, error, refetch } = useQuery<IReportExecuteResult, IReportExecuteVariables>(ReportExecuteMutation, {
        fetchPolicy: "no-cache",
        variables: { id: params.report.id, params: props },
    });

    // Update the grid height when the data is loaded
    const displaying = !!data && !error;
    useLayoutEffect(() => {
        // useLayoutEffect is used to avoid flickering
        // Initialize grid height
        calculateHeight();
    }, [displaying]);

    if (error) return <ErrorDisplay onClick={refetch}>{error.message}</ErrorDisplay>;
    if (!data) return <p>Loading...</p>;
    const dataSet = data.api.importExport.export.report.data;

    const renderGrids = () => {
        const preprocessedDataSet = preprocessData(dataSet);
        return Object.keys(preprocessedDataSet).map((tableName) => {
            const tableData = preprocessedDataSet[tableName];
            const columnDefs: ColDef<IDataRow>[] =
                tableData.length > 0
                    ? Object.keys(tableData[0]).map((key) => ({
                          headerName: key,
                          field: key,
                          resizable: true,
                          sortable: true,
                          filter: true,
                          // ag-grid formats dates by default with date only, not date/time
                          valueFormatter: isDateTime(dataSet[tableName][0][key])
                              ? (x) => (x.data && x.data[key] ? formatDate(x.data[key] as Date) : "")
                              : undefined,
                      }))
                    : [];

            return (
                <Card ref={cardRef} key={tableName} className="mb-4 border-primary">
                    <Card.Header className="bg-primary text-white">{tableName}</Card.Header>
                    <Card.Body>
                        {tableData.length > 0 ? (
                            <div ref={gridRef} className="ag-theme-alpine" style={{ height: gridHeight /*'300px'*/ }}>
                                <AgGridReact<IDataRow> columnDefs={columnDefs} rowData={tableData} /*domLayout="autoHeight"*/ />
                            </div>
                        ) : (
                            <div>No data available for this table.</div>
                        )}
                    </Card.Body>
                </Card>
            );
        });
    };

    return <>{renderGrids()}</>;
};

function formatDate(date: Date): string {
    return date
        .toLocaleString("en-US", {
            year: "numeric",
            month: "2-digit",
            day: "2-digit",
            hour: "2-digit",
            minute: "2-digit",
            second: "2-digit",
        })
        .replace(",", "");
}

function isDateOnly(value: string) {
    // date only
    const isoDatePattern = /^\d{4}-\d{2}-\d{2}$/;
    const isoDateZeroTimePattern = /^\d{4}-\d{2}-\d{2}T00:00:00$/;
    return isoDatePattern.test(value) || isoDateZeroTimePattern.test(value);
}

function isDateTime(value: any) {
    if (!(typeof value === "string")) return false;
    // date and time, maybe with offset
    const isoDatePattern = /^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}(?:[Z]|(?:[+-]\d{2}:\d{2}))?$/;
    const isoDateZeroTimePattern = /^\d{4}-\d{2}-\d{2}T00:00:00$/;
    return isoDatePattern.test(value) && !isoDateZeroTimePattern.test(value);
}

function preprocessData(rawData: IDataSet): IDataSet {
    // Regular expression to match specific ISO date formats
    const isoDatePattern = /^\d{4}-\d{2}-\d{2}(T\d{2}:\d{2}:\d{2}(?:[Z]|(?:[+-]\d{2}:\d{2}))?)?$/;

    const processedData: IDataSet = {};
    Object.keys(rawData).forEach((tableName) => {
        processedData[tableName] = rawData[tableName].map((row) => {
            const newRow: IDataRow = {};
            Object.keys(row).forEach((key) => {
                const value = row[key];
                newRow[key] = typeof value === "string" && isoDatePattern.test(value) ? parseDate(value) : value;
            });
            return newRow;
        });
    });
    return processedData;
}

function parseDate(value: string): Date {
    // parses date-only values as local date
    // parses date-time values as UTC date unless otherwise specified in the string
    if (isDateOnly(value)) {
        const [year, month, day] = value.split("-").map(Number);
        return new Date(year, month - 1, day);
    } else {
        return new Date(Date.parse(value));
    }
}

export default ReportView;
