import PrimaryHeader from "../../../../components/PrimaryHeader";
import SecondaryHeader from "../../../../components/SecondaryHeader";
import ManageCarts from "./ManageCarts";

const ManageCartsWrapper = () => {
    return (
        <>
            <PrimaryHeader
                title="Manage Carts"
                includeBackButton
                includeHomeButton
                backButtonText="< Admin Cart"
                customBackButtonPath="/mobile/admin/cart"
            />

            <SecondaryHeader title="Select a Cart" nextButtonHandler={async () => void 0} />

            <ManageCarts />
        </>
    );
};

export default ManageCartsWrapper;
