import React from "react";
import { Col, Container, Row } from "react-bootstrap";

interface IProps {
    title: string;
    secondaryTitle?: string;
    nextButtonHandler: () => void;
}

//*NOTE* The "forwardRef" type arguments are reversed for whatever reason...
const SecondaryHeader = React.forwardRef<HTMLDivElement, IProps>((props, ref) => {
    let titleElement = <div style={{ fontSize: 18, fontWeight: "bold" }}>{props.title}</div>;
    let secondaryTitleElement = (
        <div style={{ textOverflow: "ellipsis", overflow: "hidden", whiteSpace: "nowrap", fontSize: 22, fontWeight: "bold" }}>
            {props.secondaryTitle}
        </div>
    );

    return (
        <Container ref={ref} style={{ maxWidth: "100%" }}>
            <Row style={{ color: "white", fontWeight: "bold", height: "60px", backgroundColor: "#EDF0F4" }}>
                <Col xs={6} className="d-flex align-items-center justify-content-center" style={{ width: "100%", color: "#CC0A3B" }}>
                    <button
                        style={{
                            width: "100%",
                            height: 60,
                            borderRadius: 8,
                            border: "1px solid #B9BDCE",
                            backgroundColor: "white",
                            color: "#CC0A3B" /*boxShadow: "0px 8px 15px rgba(0, 0, 0, 0.10)"*/,
                        }}
                        onClick={() => props.nextButtonHandler()}
                    >
                        <div>
                            {titleElement}
                            {secondaryTitleElement}
                        </div>
                    </button>
                </Col>
            </Row>
        </Container>
    );
});

export default SecondaryHeader;
