import { createElement, useContext, useState } from "react";
import { GraphQLError, useMutation, useQuery } from "@shane32/graphql";
import PurchaseOrderStatus from "../../../../enums/PurchaseOrderStatus";
import { useHistory, useLocation } from "react-router-dom";
import MobileLoading from "../../components/MobileLoading";
import MobileButton from "../../components/buttons/MobileButton";
import PrimaryHeader from "../../components/PrimaryHeader";
import SecondaryHeader from "../../components/SecondaryHeader";
import MobileTable from "../../components/tables/MobileTable";
import ActiveProductImages from "../../components/images/ActiveProductImages";
import MobileButtonContainer from "../../components/buttons/MobileButtonContainer";
import MobileButtonRow from "../../components/buttons/MobileButtonRow";
import MobileButtonCol from "../../components/buttons/MobileButtonCol";
import SelectionType from "../../../../enums/SelectionType";
import useConfirm from "../../hooks/useConfirm";
import useLocationInputModal from "../../hooks/useLocationInputModal";
import ModelType from "../../../../enums/ModelType";
import {
    ISearchBarcodesQueryResult,
    ISearchBarcodesQueryVariables,
    formatMultipleMatchesString,
    searchBarcodesQuery,
} from "../../graphs/queries/SearchBarcodesQuery";
import useMultiMatchSelectorModal, { MultiMatchDataType } from "../../hooks/useMultiMatchSelectorModal";
import mapStringToEnum from "../../helpers/mapStringToEnum";
import useScanner from "../../hooks/useScanner";
import ScannerToneContext from "../../contexts/ScannerToneContext";

const purchaseOrdersQueryBase = `
purchaseOrders(
    statuses: $statuses
    productId: $productId
    supplierId: $supplierId
  ) {
    pageInfo {
      hasNextPage
      endCursor
    }
    items {
      id
      supplier {
        id
        name
      }
      orderDate
      invoices {
        id
        invoiceNumber
        invoiceDate
        lineItems {
          id
          expectedQuantity
          actualQuantity
          purchaseOrderLineItem {
            id
            product {
                id
                sku
                description
            }
          }
        }
      }
    }
  }
`;

const moveFromPurchaseInvoiceMutation = `
mutation (
    $purchaseInvoiceId: ID!,
    $productId: ID!,
    $toLocationId: ID!,
    $quantity: Decimal!,
    $productSelectionType: SelectionType!,
    $locationSelectionType: SelectionType!,
    $statuses: [PurchaseOrderStatus!],
    $supplierId: ID
) {
  inventory {
    moveFromPurchaseInvoice(
      purchaseInvoiceId: $purchaseInvoiceId
      productId: $productId
      locationId: $toLocationId
      quantity: $quantity
      productSelectionType: $productSelectionType
      locationSelectionType: $locationSelectionType
    )
  }
  query {
    ${purchaseOrdersQueryBase}
  }
}
`;

interface IMoveFromPurchaseInvoiceMutationResult {
    inventory: {
        moveFromPurchaseInvoice: boolean;
    };
    query: IPurchaseOrdersQueryResult;
}

interface IMoveFromPurchaseInvoiceMutationVariables {
    purchaseInvoiceId: string;
    productId: string;
    toLocationId: string;
    quantity: number;
    productSelectionType: SelectionType;
    locationSelectionType: SelectionType;
    statuses: Array<PurchaseOrderStatus>;
    supplierId: string | null;
}

const purchaseOrdersQuery = `
query ($statuses: [PurchaseOrderStatus!], $productId: ID, $supplierId: ID) {
  ${purchaseOrdersQueryBase}
}
`;

interface IPurchaseOrder {
    id: string;
    supplier: {
        id: string;
        name: string;
    } | null;
    orderDate: Date | null;
    invoices: Array<{
        id: string;
        invoiceNumber: string;
        invoiceDate: string;
        lineItems: Array<{
            id: string;
            expectedQuantity: number;
            actualQuantity: number;
            purchaseOrderLineItem: {
                id: string;
                product: {
                    id: string;
                    sku: string;
                    description: string;
                } | null;
            };
        }>;
    }>;
}

interface IPurchaseOrdersQueryResult {
    purchaseOrders: {
        pageInfo: {
            hasNextPage: boolean;
            endCursor: string | null;
        };
        items: Array<IPurchaseOrder>;
    };
}

interface IPurchaseOrdersQueryVariables {
    statuses: Array<PurchaseOrderStatus>;
    productId: string | null;
    supplierId: string | null;
}

const IntakeProduct = () => {
    const { playPositiveTone, playNegativeTone } = useContext(ScannerToneContext);

    const history = useHistory();
    const location = useLocation();
    const { confirmModal, showConfirmModal, open: isConfirmModalOpen } = useConfirm();
    const { locationInputModal, showLocationInputModal, open: isLocationInputModalOpen } = useLocationInputModal();
    const { multiMatchSelectorModal, showMultiMatchSelectorModal, open: isMultiMatchSelectorModalOpen } = useMultiMatchSelectorModal();

    const [numberInHand, setNumberInHand] = useState(0);
    const [manualLoading, setManualLoading] = useState(false);

    const urlSearchParams = new URLSearchParams(location.search);
    const urlSearchParamsProductId = urlSearchParams.get("productId");
    const urlSearchParamsSupplierId = urlSearchParams.get("supplierId");
    const urlSearchParamsProductSelectionType =
        mapStringToEnum(SelectionType, urlSearchParams.get("productSelectionType")) ?? SelectionType.None;

    const {
        loading: purchaseOrdersLoading,
        data: purchaseOrdersData,
        error: purchaseOrdersError,
    } = useQuery<IPurchaseOrdersQueryResult, IPurchaseOrdersQueryVariables>(purchaseOrdersQuery, {
        variables: {
            statuses: [PurchaseOrderStatus.Invoiced, PurchaseOrderStatus.Receiving],
            productId: urlSearchParamsProductId,
            supplierId: urlSearchParamsSupplierId,
        },
        skip: !urlSearchParamsProductId || !urlSearchParamsSupplierId,
    });

    const [runSearchBarcodes] = useMutation<ISearchBarcodesQueryResult, ISearchBarcodesQueryVariables>(searchBarcodesQuery);
    const [runMoveFromPurchaseInvoiceMutation] = useMutation<
        IMoveFromPurchaseInvoiceMutationResult,
        IMoveFromPurchaseInvoiceMutationVariables
    >(moveFromPurchaseInvoiceMutation);

    const onScan = async (scannerValue: string) => {
        if (manualLoading || !scannerValue || isConfirmModalOpen || isLocationInputModalOpen || isMultiMatchSelectorModalOpen) return;
        try {
            setManualLoading(true);
            const res = await runSearchBarcodes({ variables: { search: scannerValue, userEntered: false } });
            const barcodeProducts = res.data.searchBarcodes.filter((x) => x.type === ModelType.Product);
            const barcodeLocations = res.data.searchBarcodes.filter((x) => x.type === ModelType.Location);
            //check if there are matches for products and locations from the scanned value
            if (barcodeProducts.length > 0 && barcodeLocations.length > 0) {
                await showConfirmModal(
                    formatMultipleMatchesString(res.data.searchBarcodes, scannerValue),
                    "Multiple Matches In Database",
                    { confirm: "Confirm" },
                    true
                );
            } else if (barcodeProducts.length > 0) {
                let matchingProductId = barcodeProducts[0].id;
                if (barcodeProducts.length > 1) {
                    const selectedProduct = await showMultiMatchSelectorModal(
                        "Select Correct Product SKU",
                        barcodeProducts.map((x) => x.id),
                        MultiMatchDataType.Product
                    );
                    if (!selectedProduct) return;
                    //find the purchase order line item that matches the selected product id
                    matchingProductId = selectedProduct.id;
                }
                //make sure the matching product is within the purchase orders
                if (purchaseOrderInvoiceProducts.map((poip) => poip.purchaseOrderLineItemProduct?.id).includes(matchingProductId)) {
                    increment();
                    playPositiveTone();
                } else {
                    playNegativeTone();
                }
            } else if (barcodeLocations.length > 0) {
                let matchingLocation = { id: barcodeLocations[0].id, name: scannerValue };
                if (barcodeLocations.length > 1) {
                    const selectedLocation = await showMultiMatchSelectorModal(
                        "Select Correct Location",
                        barcodeLocations.map((x) => x.id),
                        MultiMatchDataType.Location
                    );
                    if (!selectedLocation) return;
                    matchingLocation = selectedLocation;
                }
                await stow({
                    locationSelectionType: SelectionType.Scanned,
                    productSelectionType: urlSearchParamsProductSelectionType,
                    toLocation: matchingLocation,
                });
            } else {
                playNegativeTone();
            }
        } catch (error: any) {
            await showConfirmModal(
                (error as GraphQLError)?.message ?? (error as Error)?.message ?? "An unknown error has occurred",
                "Error",
                { confirm: "Ok" },
                true
            );
        } finally {
            setManualLoading(false);
        }
    };
    useScanner(onScan);

    const purchaseOrderInvoiceProducts = (purchaseOrdersData?.purchaseOrders?.items ?? [])
        .flatMap((purchaseOrder) => {
            return purchaseOrder.invoices.flatMap((invoice) => {
                return invoice.lineItems
                    .filter(
                        (lineItem) =>
                            lineItem.purchaseOrderLineItem.product && lineItem.purchaseOrderLineItem.product.id === urlSearchParamsProductId
                    )
                    .map((lineItem) => {
                        return {
                            purchaseOrderId: purchaseOrder.id,
                            purchaseOrderDate: purchaseOrder.orderDate,
                            invoiceId: invoice.id,
                            invoiceDate: invoice.invoiceDate,
                            invoiceLineItemId: lineItem.id,
                            invoiceLineItemExpectedQuantity: lineItem.expectedQuantity,
                            invoiceLineItemActualQuantity: lineItem.actualQuantity,
                            purchaseOrderLineItemId: lineItem.purchaseOrderLineItem.id,
                            purchaseOrderLineItemProduct: lineItem.purchaseOrderLineItem.product,
                        };
                    });
            });
        })
        .sort(
            (a, b) =>
                //(a.purchaseOrderDate && b.purchaseOrderDate ? a.purchaseOrderDate.getTime() - b.purchaseOrderDate.getTime() : 0) ||
                new Date(a.invoiceDate).valueOf() - new Date(b.invoiceDate).valueOf()
        );

    console.log("poip", purchaseOrderInvoiceProducts);

    const product = purchaseOrderInvoiceProducts.find((x) => x)?.purchaseOrderLineItemProduct;
    const maxAmountOfProductToIntake =
        purchaseOrderInvoiceProducts.reduce((acc, val) => acc + val.invoiceLineItemExpectedQuantity, 0) -
        purchaseOrderInvoiceProducts.reduce((acc, val) => acc + val.invoiceLineItemActualQuantity, 0);

    const stow = async (stowProps: {
        toLocation?: { id: string; name: string };
        locationSelectionType: SelectionType;
        productSelectionType: SelectionType;
    }) => {
        if (numberInHand < 1 || !urlSearchParamsProductId || !urlSearchParamsSupplierId || !purchaseOrdersData) return;
        let moveFromPurchaseInvoiceInputArray: IMoveFromPurchaseInvoiceMutationVariables[] = [];
        let amountToMove = numberInHand;
        try {
            setManualLoading(true);
            if (!stowProps.toLocation) {
                const locationName = await showLocationInputModal({ modalTitle: "Enter Bin Name", productId: urlSearchParamsProductId });
                if (!locationName) return;
                const res = await runSearchBarcodes({ variables: { search: locationName, userEntered: false } });
                const barcodeLocations = (res.data?.searchBarcodes ?? []).filter((x) => x.type === ModelType.Location);
                if (barcodeLocations.length < 1) return;
                stowProps.toLocation = { id: barcodeLocations[0].id, name: locationName };
                if (barcodeLocations.length > 1) {
                    const selectedLocation = await showMultiMatchSelectorModal(
                        "Select Location",
                        barcodeLocations.map((x) => x.id),
                        MultiMatchDataType.Location
                    );
                    if (!selectedLocation) return;
                    stowProps.toLocation = selectedLocation;
                }
            }
            //purchaseOrderInvoiceProducts is sorted by date already.
            for (let poip of purchaseOrderInvoiceProducts) {
                if (amountToMove < 1) break;
                if (poip.invoiceLineItemActualQuantity >= poip.invoiceLineItemExpectedQuantity) continue;
                const amountStillNeeded = poip.invoiceLineItemExpectedQuantity - poip.invoiceLineItemActualQuantity;
                const maxPossibleAmountToMoveForThisLineItem = Math.min(amountToMove, amountStillNeeded);
                amountToMove -= maxPossibleAmountToMoveForThisLineItem;
                moveFromPurchaseInvoiceInputArray.push({
                    purchaseInvoiceId: poip.invoiceId,
                    productId: urlSearchParamsProductId,
                    toLocationId: stowProps.toLocation.id,
                    quantity: maxPossibleAmountToMoveForThisLineItem,
                    locationSelectionType: stowProps.locationSelectionType,
                    productSelectionType: stowProps.productSelectionType,
                    statuses: [PurchaseOrderStatus.Invoiced, PurchaseOrderStatus.Receiving],
                    supplierId: urlSearchParamsSupplierId,
                });
            }
            //check for remaining amount to move put them on the oldest purchase invoice
            if (
                amountToMove > 0 &&
                purchaseOrderInvoiceProducts.every((poip) => poip.invoiceLineItemActualQuantity >= poip.invoiceLineItemExpectedQuantity)
            ) {
                const oldestPurchaseInvoice = purchaseOrderInvoiceProducts[0];
                moveFromPurchaseInvoiceInputArray.push({
                    purchaseInvoiceId: oldestPurchaseInvoice.invoiceId,
                    productId: urlSearchParamsProductId,
                    toLocationId: stowProps.toLocation.id,
                    quantity: amountToMove,
                    locationSelectionType: stowProps.locationSelectionType,
                    productSelectionType: stowProps.productSelectionType,
                    statuses: [PurchaseOrderStatus.Invoiced, PurchaseOrderStatus.Receiving],
                    supplierId: urlSearchParamsSupplierId,
                });
                amountToMove = 0;
            }
            for (let moveFromPurchaseInvoiceInput of moveFromPurchaseInvoiceInputArray) {
                const response = await runMoveFromPurchaseInvoiceMutation({ variables: moveFromPurchaseInvoiceInput });
                purchaseOrdersData.purchaseOrders.items = response.data.query.purchaseOrders.items;
            }
        } catch (error: any) {
            await showConfirmModal(
                (error as GraphQLError)?.message ?? (error as Error)?.message ?? "An unknown error has occurred",
                "Error",
                { confirm: "Ok" },
                true
            );
        } finally {
            setManualLoading(false);
            setNumberInHand(amountToMove);
        }
    };

    const increment = () => (!product ? void 0 : setNumberInHand(numberInHand + 1));

    const decrement = () => (numberInHand < 1 ? void 0 : setNumberInHand(numberInHand - 1));

    const max = () => setNumberInHand(maxAmountOfProductToIntake);

    const addOrPrintBarcode = () => {
        if (!product || !urlSearchParamsProductId || !urlSearchParamsSupplierId) return;
        let newURLSP = new URLSearchParams();
        newURLSP.set("productId", product.id);
        newURLSP.set("supplierId", urlSearchParamsSupplierId);
        history.push(`/mobile/blindintake/addorprintbarcode?${newURLSP.toString()}`);
    };

    return (
        <>
            {(purchaseOrdersLoading || manualLoading) && <MobileLoading fullscreen />}
            {purchaseOrdersError && (
                <div>
                    {purchaseOrdersError.message}
                    <MobileButton onClick={() => window.location.reload()}>Reload</MobileButton>
                </div>
            )}

            <PrimaryHeader
                title={"Intake Product"}
                includeBackButton
                backButtonText="< Select PO"
                customBackButtonPath={`/mobile/intake/selectpurchaseOrder?${
                    urlSearchParamsSupplierId ? `supplierId=${urlSearchParamsSupplierId}` : ""
                }`}
                includeHomeButton
            />

            <SecondaryHeader title="Scan Barcode" nextButtonHandler={() => void 0} />

            <MobileTable isActiveTable>
                <thead>
                    <tr>
                        <td>Shipment</td>
                        <td>Hand</td>
                        <td>Bin</td>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td>{!product ? "-" : maxAmountOfProductToIntake - numberInHand}</td>
                        <td>{!product ? "-" : numberInHand}</td>
                        <td>{!product ? "-" : "-"}</td>
                    </tr>
                    <tr>
                        <td>{"-"}</td>
                        <td style={{ color: "#CC0A3B" }}>{!product ? "-" : product.sku ?? "(No sku)"}</td>
                        <td>{}</td>
                    </tr>
                </tbody>
            </MobileTable>

            <div style={{ padding: "10px 10px 0 10px" }}>
                <ActiveProductImages sku={product?.sku} />
            </div>

            {/* category */}
            <div style={{ display: "flex", justifyContent: "space-between", fontSize: 12, margin: "5px 10px 0px 10px" }}>
                <div>
                    Category: <b>{product?.description ?? "(None)"}</b>
                </div>
            </div>

            <MobileButtonContainer>
                {/* buttons 1 */}
                <MobileButtonRow>
                    <MobileButtonCol>
                        <MobileButton
                            disabled={!product}
                            onClick={async () => {
                                await stow({
                                    locationSelectionType: SelectionType.Searched,
                                    productSelectionType: urlSearchParamsProductSelectionType,
                                });
                            }}
                        >
                            {`Stow (${numberInHand}) to Any`}
                        </MobileButton>
                    </MobileButtonCol>
                    <MobileButtonCol>
                        <MobileButton disabled={!product /*|| autoStow*/} onClick={decrement}>
                            -
                        </MobileButton>
                        <MobileButton disabled={!product /*|| autoStow*/} onClick={increment}>
                            +
                        </MobileButton>
                        <MobileButton disabled onClick={max}>
                            Max
                        </MobileButton>
                    </MobileButtonCol>
                </MobileButtonRow>

                {/* buttons 2 */}
                <MobileButtonRow>
                    <MobileButtonCol>
                        {/* <MobileButton disabled={!activeItem || locationsOnCart.length > 1} onClick={verifySetAutoStow}> */}
                        <MobileButton disabled>Auto Stow (Off)</MobileButton>
                        <MobileButton disabled>Lost Bin</MobileButton>
                        <MobileButton disabled>Done</MobileButton>
                    </MobileButtonCol>
                </MobileButtonRow>

                {/* buttons 3 */}
                <MobileButtonRow>
                    <MobileButtonCol>
                        <MobileButton disabled={!product} onClick={addOrPrintBarcode}>
                            Add Or Print Barcode
                        </MobileButton>
                    </MobileButtonCol>
                </MobileButtonRow>
            </MobileButtonContainer>

            {createElement(confirmModal)}
            {createElement(locationInputModal)}
            {createElement(multiMatchSelectorModal)}
        </>
    );
};

export default IntakeProduct;
