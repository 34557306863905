import { GraphQLError, useMutation, useQuery } from "@shane32/graphql";
import { createElement, useEffect, useRef, useState } from "react";
import { useHistory } from "react-router-dom";
import ErrorDisplay from "../../../../components/misc/ErrorDisplay";
import ModelType from "../../../../enums/ModelType";
import PurchaseOrderStatus from "../../../../enums/PurchaseOrderStatus";
import MobileLoading from "../../components/MobileLoading";
import PrimaryHeader from "../../components/PrimaryHeader";
import SecondaryHeader from "../../components/SecondaryHeader";
import MobileTable from "../../components/tables/MobileTable";
import {
    ISearchBarcodesQueryResult,
    ISearchBarcodesQueryVariables,
    formatMultipleMatchesString,
    searchBarcodesQuery,
} from "../../graphs/queries/SearchBarcodesQuery";
import useConfirm from "../../hooks/useConfirm";
import useScanner from "../../hooks/useScanner";
import { ISelectPurchaseInvoiceHistoryState } from "./SelectInvoice";
import MobileButton from "../../components/buttons/MobileButton";
import useQuickIntakeModal from "../../hooks/useQuickIntakeModal";
import MobileButtonContainer from "../../components/buttons/MobileButtonContainer";
import {
    IZbdbInterchangeSearchQueryResult,
    IZbdbInterchangeSearchQueryVariables,
    zbdbInterchangeSearchQuery,
} from "../../graphs/queries/SearchZbDbQuery";
import { Modal } from "react-bootstrap";
import AddOrPrintBarcode from "../../components/add_or_print_barcode/AddOrPrintBarcode";
import useMultiMatchSelectorModal, { MultiMatchDataType } from "../../hooks/useMultiMatchSelectorModal";
import SelectionType from "../../../../enums/SelectionType";

const purchaseOrdersQuery = `
query ($statuses: [PurchaseOrderStatus!], $productId: ID, $supplierId: ID) {
    purchaseOrders(
      statuses: $statuses
      productId: $productId
      supplierId: $supplierId
    ) {
      items {
        id
        supplier {
          name
        }
        orderDate
        invoices {
          id
          lineItems {
            expectedQuantity
            actualQuantity
            purchaseOrderLineItem {
              productId
            }
          }
        }
      }
    }
  }
`;

interface IPurchaseOrdersQueryResult {
    purchaseOrders: {
        items: Array<{
            id: string;
            supplier: {
                name: string;
            };
            orderDate: string;
            invoices: Array<{
                id: string;
                lineItems: Array<{
                    expectedQuantity: number;
                    actualQuantity: number;
                    purchaseOrderLineItem: {
                        productId: string | null;
                    };
                }>;
            }>;
        }>;
    };
}

interface IPurchaseOrdersQueryVariables {
    statuses: Array<PurchaseOrderStatus>;
    productId: string | null;
    supplierId: string | null;
}

const productQuery = `
query ($productId: ID!) {
    product(id: $productId) {
      id
      sku
    }
  }
`;

interface IProductQueryResult {
    product: {
        id: string;
        sku: string;
    };
}

interface IProductQueryVariables {
    productId: string;
}

const SelectPurchaseOrder = () => {
    const history = useHistory<ISelectPurchaseInvoiceHistoryState>();
    const urlSearchParams = new URLSearchParams(history.location.search);
    const urlSearchParamsSupplierId = urlSearchParams.get("supplierId");
    const { quickIntakeModal, showQuickIntakeModal, open: isQuickIntakeModalOpen } = useQuickIntakeModal();
    const [filterValue, setFilterValue] = useState("");
    const [manualLoading, setManualLoading] = useState(false);
    const [productIdToSearch, setProductIdToSearch] = useState<string | null>(null);
    const [productSelectionType, setProductSelectionType] = useState<SelectionType>(SelectionType.None);
    const {
        loading: purchaseOrderLoading,
        data,
        refetch,
        error,
    } = useQuery<IPurchaseOrdersQueryResult, IPurchaseOrdersQueryVariables>(purchaseOrdersQuery, {
        fetchPolicy: "no-cache",
        variables: {
            statuses: [PurchaseOrderStatus.Invoiced, PurchaseOrderStatus.Receiving],
            productId: productIdToSearch,
            supplierId: urlSearchParamsSupplierId,
        },
    });
    const {
        loading: productLoading,
        data: productData,
        refetch: productRefetch,
        error: productError,
    } = useQuery<IProductQueryResult, IProductQueryVariables>(productQuery, {
        fetchPolicy: "no-cache",
        variables: !productIdToSearch
            ? undefined
            : {
                  productId: productIdToSearch,
              },
        skip: !productIdToSearch,
    });
    const [runSearchBarcodes] = useMutation<ISearchBarcodesQueryResult, ISearchBarcodesQueryVariables>(searchBarcodesQuery);
    const [runZbDbInterchangeSearch] = useMutation<IZbdbInterchangeSearchQueryResult, IZbdbInterchangeSearchQueryVariables>(
        zbdbInterchangeSearchQuery
    );
    const { confirmModal, showConfirmModal } = useConfirm();
    const { multiMatchSelectorModal, showMultiMatchSelectorModal } = useMultiMatchSelectorModal();
    const searchInputRef = useRef<HTMLInputElement>(null);
    const [showAddOrPrintBarcodeModal, setShowAddOrPrintBarcodeModal] = useState<boolean>(false);

    const allProductIdsInPurchaseOrders = new Set(
        (data?.purchaseOrders?.items ?? [])
            .flatMap((x) => x.invoices)
            .flatMap((x) => x.lineItems)
            .map((x) => x.purchaseOrderLineItem.productId ?? "")
    );

    useEffect(() => {
        if (!productIdToSearch) refetch();
    }, [productIdToSearch]); //eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        if (productData && urlSearchParamsSupplierId && (data?.purchaseOrders?.items ?? []).length > 0) {
            if (allProductIdsInPurchaseOrders.has(productData.product.id)) {
                let urlSearchParams = new URLSearchParams();
                urlSearchParams.set("productId", productData.product.id);
                urlSearchParams.set("supplierId", urlSearchParamsSupplierId);
                urlSearchParams.set("productSelectionType", productSelectionType.toString());
                history.push(`/mobile/blindintake/intakeproduct?${urlSearchParams.toString()}`);
            }
        }
    }, [data]); //eslint-disable-line react-hooks/exhaustive-deps

    const searchBarcodes = async (search: string, userEntered: boolean, vendorSpecific: boolean) => {
        if (loading) return;
        try {
            if ("clipboard" in navigator) await navigator.clipboard.writeText(search);
            else document.execCommand("copy", true, search); //fallback for old browsers
            if (searchInputRef.current && !userEntered) searchInputRef.current.value = "";
            setManualLoading(true);
            const res = await runSearchBarcodes({
                variables: { search: search, userEntered: userEntered, vendorSpecific: vendorSpecific },
            });
            const barcodeProducts = res.data.searchBarcodes.filter((x) => x.type === ModelType.Product);
            let productId: string | undefined = undefined;
            if (barcodeProducts.length < 1) {
                //run zbdb interchange search
                if (
                    !(await showConfirmModal(
                        `No products in Hive found matching value '${search}'.\n
                    Select 'Continue' to search ZBDB for interchanges.\n`,
                        "No Matches In Hive",
                        { confirm: "Continue" },
                        true
                    ))
                )
                    return;
                const zbdbRes = (await runZbDbInterchangeSearch({ variables: { interchangePartNumber: search } })).data.mobile
                    .searchZbDbByInterchange;
                if (zbdbRes.length < 1) {
                    throw new Error(`No products in Hive or ZBDB found matching value '${search}'.`);
                }
                const selectedProduct = await showMultiMatchSelectorModal(
                    `SKUs with Interchanges Containing '${search}'`,
                    zbdbRes.map((x) => x.productId),
                    MultiMatchDataType.Product
                );
                if (!selectedProduct) return;
                productId = selectedProduct.id;
            } else if (barcodeProducts.length > 1) {
                if (
                    !(await showConfirmModal(
                        `${formatMultipleMatchesString(
                            barcodeProducts,
                            search
                        )}. \n Please select the correct product on the next modal...`,
                        "Multiple Matches In Database",
                        { confirm: "Next" },
                        true
                    ))
                )
                    return;
                const selectedProduct = await showMultiMatchSelectorModal(
                    "Select Product SKU",
                    barcodeProducts.map((x) => x.id),
                    MultiMatchDataType.Product
                );
                if (!selectedProduct) return;
                productId = selectedProduct.id;
            } else {
                productId = barcodeProducts[0].id;
            }
            setProductIdToSearch(productId);
        } catch (e: any) {
            await showConfirmModal(
                (e as GraphQLError)?.message ?? (e as Error)?.message ?? "An unknown error occurred while searching database for product.",
                "Error",
                { confirm: "Ok" },
                true
            );
        } finally {
            setManualLoading(false);
            await new Promise((resolve) => setTimeout(resolve, 0));
            searchInputRef.current?.blur();
        }
    };

    const onScan = async (scannerValue: string) => {
        if (isQuickIntakeModalOpen || showAddOrPrintBarcodeModal) return;
        setProductSelectionType(SelectionType.Scanned);
        await searchBarcodes(scannerValue, false, true);
    };
    useScanner(onScan);

    if (error || (!purchaseOrderLoading && !data))
        return <ErrorDisplay onClick={refetch}>{error?.message ?? "Fetching data for purchase orders failed"}</ErrorDisplay>;

    if (productError)
        return (
            <ErrorDisplay onClick={productRefetch}>
                {productError?.message ?? `Fetching data for product id ${productIdToSearch} failed`}
            </ErrorDisplay>
        );

    const purchaseOrders = (data?.purchaseOrders?.items ?? []).filter((po) => {
        const inputValue = filterValue.trim().toUpperCase();
        if (inputValue) return po.id.toUpperCase().includes(inputValue);
        else return po;
    });

    const loading = [purchaseOrderLoading, manualLoading, productLoading].some((x) => x);

    const onClickPurchaseOrderHandler = async (purchaseOrderId: string) => {
        const purchaseOrder = purchaseOrders.find((x) => x.id === purchaseOrderId);
        if (!purchaseOrder) {
            await showConfirmModal("No purchase order found with Id: " + purchaseOrderId, "Error", { confirm: "Ok" }, true);
            return;
        }
        history.push(
            `/mobile/intake/${purchaseOrderId}/selectinvoice?${urlSearchParams.toString()}`,
            !productIdToSearch
                ? undefined
                : {
                      purchaseInvoiceIdsMatchingPreviouslyScannedProduct: purchaseOrder.invoices
                          .filter((x) => x.lineItems.some((y) => y.purchaseOrderLineItem.productId === productIdToSearch))
                          .map((x) => x.id),
                      previouslyScannedProductId: productIdToSearch,
                  }
        );
    };

    const search = async (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        const input = searchInputRef.current?.value?.trim();
        if (!input) {
            await showConfirmModal("Please enter a value to search for.", "No Search Value", { confirm: "Ok" }, true);
            return;
        }
        setProductSelectionType(SelectionType.Searched);
        await searchBarcodes(input, true, true);
    };

    const intakeSkuToLocation = async () => {
        if (!productData || loading) return;
        await showQuickIntakeModal(productData.product);
    };

    return (
        <>
            {loading ? <MobileLoading fullscreen /> : <></>}

            <PrimaryHeader
                title={`Shipments`}
                includeBackButton
                backButtonText={urlSearchParamsSupplierId ? "< Suppliers" : "< Roles"}
                customBackButtonPath={urlSearchParamsSupplierId ? `/mobile/blindintake/selectsupplier` : `/mobile/selectrole`}
                includeHomeButton
            />

            <SecondaryHeader title="Select ZPO Number" nextButtonHandler={() => void 0} />

            <form onSubmit={(e) => search(e)} style={{ padding: "10px 10px 0 10px", backgroundColor: "#EDF0F4", display: "flex" }}>
                <input
                    ref={searchInputRef}
                    onFocus={(e) => e.currentTarget.select()}
                    style={{
                        padding: 10,
                        alignSelf: "stretch",
                        height: 40,
                        borderRadius: 4,
                        border: "1px solid #798394",
                        marginRight: 10,
                        width: "100%",
                    }}
                    placeholder="Search by product..."
                />
                <button
                    style={{ alignSelf: "stretch", height: 40, borderRadius: 4, border: "1px solid #798394", minWidth: 112 }}
                    type="submit"
                >
                    <b>Search</b>
                </button>
            </form>

            <div style={{ padding: "10px 10px 5px 10px", backgroundColor: "#EDF0F4", display: "flex" }}>
                <input
                    onChange={(e) => setFilterValue(e.currentTarget.value)}
                    onFocus={(e) => e.currentTarget.select()}
                    style={{ padding: 10, alignSelf: "stretch", height: 40, borderRadius: 4, border: "1px solid #798394", width: "100%" }}
                    placeholder="Filter Purchase Orders..."
                />
            </div>

            {urlSearchParamsSupplierId && productData && !allProductIdsInPurchaseOrders.has(productData.product.sku) && !loading ? (
                <div style={{ display: "flex", justifyContent: "center", margin: "5px 10px 0 10px" }}>
                    No purchase orders found containing SKU {productData.product.sku} for selected supplier.
                </div>
            ) : purchaseOrders.length < 1 && !loading && productIdToSearch ? (
                <div style={{ display: "flex", justifyContent: "center", margin: "5px 10px 0 10px" }}>
                    No purchase orders found containing{" "}
                    {productData?.product?.sku ? `SKU ${productData.product.sku}` : `product id ${productIdToSearch}`}
                </div>
            ) : (
                <MobileTable ignoreSpacing>
                    <thead>
                        <tr>
                            <td>Supplier</td>
                            <td>ZPO #</td>
                            {!productIdToSearch ? <></> : <td>Remaining</td>}
                            <td>Date</td>
                        </tr>
                    </thead>
                    <tbody>
                        {purchaseOrders.map((po, a) => {
                            return (
                                <tr key={po.id} id={po.id} onClick={async () => await onClickPurchaseOrderHandler(po.id)}>
                                    <td>{po.supplier.name}</td>
                                    <td>ZPO-{po.id}</td>
                                    {!productIdToSearch ? (
                                        <></>
                                    ) : (
                                        <td>
                                            {
                                                //get all invoices that have the product we are searching for, then get the line item that has the product we are searching for,
                                                //then get the expected quantity of that line item, then subtract the actual quantity of that line item, then add up the total amount to get the remaining quantity
                                                po.invoices
                                                    .flatMap((invoice) => invoice.lineItems)
                                                    .filter((lineItem) => lineItem.purchaseOrderLineItem.productId === productIdToSearch)
                                                    .reduce((a, b) => a + (b.expectedQuantity - b.actualQuantity), 0)
                                            }
                                        </td>
                                    )}
                                    <td>{new Date(po.orderDate).toLocaleDateString()}</td>
                                </tr>
                            );
                        })}
                    </tbody>
                </MobileTable>
            )}

            {productData && (
                <>
                    <MobileButtonContainer>
                        <MobileButton onClick={async () => setProductIdToSearch(null)}>Clear Product Search</MobileButton>
                        <MobileButton onClick={async () => await intakeSkuToLocation()}>
                            Intake {productData.product.sku} to Location
                        </MobileButton>
                        <MobileButton onClick={() => setShowAddOrPrintBarcodeModal(true)}>Add or Print Barcode</MobileButton>
                    </MobileButtonContainer>
                    <Modal show={showAddOrPrintBarcodeModal} fullscreen>
                        <PrimaryHeader
                            title={productData.product.sku}
                            includeHomeButton
                            includeBackButton
                            backButtonText="Close"
                            customBackButtonFunction={() => setShowAddOrPrintBarcodeModal(false)}
                        />

                        <SecondaryHeader title="Add Or Print Barcode" nextButtonHandler={() => void 0} />

                        <AddOrPrintBarcode
                            productId={productData.product.id}
                            onBarcodeSaveAction={() => setShowAddOrPrintBarcodeModal(false)}
                        />
                    </Modal>
                </>
            )}

            {createElement(confirmModal)}

            {createElement(multiMatchSelectorModal)}

            {createElement(quickIntakeModal)}
        </>
    );
};

export default SelectPurchaseOrder;
