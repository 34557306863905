import { CSSProperties, useEffect, useRef } from "react";

interface IProps extends React.DetailedHTMLProps<React.ButtonHTMLAttributes<HTMLButtonElement>, HTMLButtonElement> {
    children: React.ReactNode;
    onClick?: () => void;
    allowLongPress?: boolean;
}

const MobileButton = ({ allowLongPress = false, children, disabled, onClick, style, ...buttonAttributes }: IProps) => {
    const intervalRef = useRef<NodeJS.Timeout | null>(null);
    const timeoutRef = useRef<NodeJS.Timeout | null>(null);
    const isLongPressRef = useRef(false);
    const mouseDownRef = useRef(false); // Flag to check mouse is pressed down
    const scrollDetectedRef = useRef(false); // Flag to detect scroll

    const startInterval = () => {
        if (!allowLongPress) return;
        isLongPressRef.current = true;
        intervalRef.current = setInterval(() => {
            if (onClick) {
                onClick();
            }
        }, 100); // Change for quicker repetition during long press
    };

    const stopInterval = () => {
        if (timeoutRef.current) {
            clearTimeout(timeoutRef.current);
            timeoutRef.current = null;
        }
        if (intervalRef.current) {
            clearInterval(intervalRef.current);
            intervalRef.current = null;
        }
        if (isLongPressRef.current) {
            isLongPressRef.current = false;
        } else if (mouseDownRef.current && !scrollDetectedRef.current && onClick) {
            // Fire onClick only if mouse was actually down and no scroll detected
            onClick();
        }
        mouseDownRef.current = false;
        scrollDetectedRef.current = false; // Reset scroll detection
    };

    const handleMouseDown = () => {
        mouseDownRef.current = true; // Set flag when mouse is down
        if (allowLongPress) {
            timeoutRef.current = setTimeout(startInterval, 1000); // Start long press after delay
        }
    };

    const handleTouchMove = () => {
        scrollDetectedRef.current = true; // Set scroll detected flag
    };

    const handleTouchEnd = (event: React.TouchEvent) => {
        event.preventDefault(); // Prevent default onClick from firing
        stopInterval(); // Ensure any ongoing intervals or timeouts are stopped
    };

    useEffect(() => {
        // Cleanup on unmount
        return () => {
            if (intervalRef.current) clearInterval(intervalRef.current);
            if (timeoutRef.current) clearTimeout(timeoutRef.current);
        };
    }, []);

    const buttonStyle: CSSProperties = {
        backgroundColor: "EDF0F4",
        border: "1px",
        borderRadius: 4,
        boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.55)",
        fontWeight: 500,
        height: 38,
        width: "100%",
        padding: 0,
        userSelect: "none",
        //eslint-disable-next-line
        WebkitUserSelect: "none", // Disabled text selection on mobile
    };

    // Merge the passed-in style so it overrides the defaultStyle
    const defaultStyle = {
        ...buttonStyle,
        ...style,
    };

    return (
        <button
            {...buttonAttributes}
            style={defaultStyle}
            onContextMenu={(e) => e.preventDefault()} // Prevent context menu on right click
            onMouseDown={handleMouseDown}
            onMouseUp={() => stopInterval()}
            onMouseLeave={() => mouseDownRef.current && stopInterval()}
            onTouchStart={handleMouseDown}
            onTouchMove={handleTouchMove} // Detect scroll movement
            onTouchEnd={handleTouchEnd}
        >
            {children}
        </button>
    );
};

export default MobileButton;
