import { GraphQLContext } from "@shane32/graphql";
import { ColDef, GridApi, ICellRendererParams, IDatasource } from "ag-grid-community";
import { AgGridReact } from "ag-grid-react";
import { FormEvent, useContext, useEffect, useState } from "react";
import { Button, Col, Dropdown, DropdownButton, Form, Modal, ProgressBar, Row } from "react-bootstrap";
import { Link, useHistory } from "react-router-dom";
import PageHeader from "../../components/pageheader/PageHeader";
import QueryString from "../../helpers/querystring";
import { IReport, IReportParameter, IReportsQueryResult, IReportsVariables, ReportsQuery } from "./ReportQueries";

const pageSize = 100;

const doSaveBase64 = (data: string, mimeType: string, defaultFilename: string) => {
    // Create a Blob object representing the file using the Base64-encoded data
    var file = new Blob([Uint8Array.from(window.atob(data), (c) => c.charCodeAt(0))], { type: mimeType });

    // Create a URL for the Blob object
    var fileURL = URL.createObjectURL(file);

    // Create and configure an anchor element to facilitate the download of the file
    var fileLink = document.createElement("a");
    fileLink.href = fileURL;
    fileLink.download = defaultFilename;

    // Trigger a download of the file to the Downloads folder
    fileLink.click();
};

const doSaveText = (data: string, mimeType: string, defaultFilename: string) => {
    // Create a Blob object representing the file using the plain text data
    var file = new Blob([data], { type: mimeType });

    // Create a URL for the Blob object
    var fileURL = URL.createObjectURL(file);

    // Create and configure an anchor element to facilitate the download of the file
    var fileLink = document.createElement("a");
    fileLink.href = fileURL;
    fileLink.download = defaultFilename;

    // Trigger a download of the file to the Downloads folder
    fileLink.click();
};

const ReportsIndex = () => {
    const history = useHistory();
    const [gridApi, setGridApi] = useState<GridApi<IReport> | null>(null);
    const graphQLClient = useContext(GraphQLContext).client;
    const [report, setReport] = useState<IReport | null>(null);
    const [params, setParams] = useState<Array<string | undefined>>([]);
    const [downloading, setDownloading] = useState(false);

    const onClickReport = (report: IReport) => {
        setReport(report);
        const props: Array<string> = [];
        report.metadata?.parameters?.forEach((param) => {
            props.push(
                param.defaultValue === true ? "1" : param.defaultValue ? param.defaultValue.toString() : param.type === "boolean" ? "0" : ""
            );
        });
        setParams(props);
    };

    // define grid columns
    const columns: ColDef<IReport, any>[] = [
        {
            field: "name",
            initialWidth: 300,
            //filter: true,
            //sortable: true,
            resizable: true,
            cellRenderer: (params: ICellRendererParams<IReport>) => {
                return params.data ? (
                    <Button variant="link" onClick={() => onClickReport(params.data!)}>
                        {params.data.name}
                    </Button>
                ) : null;
            },
        },
        {
            field: "description",
            initialWidth: 600,
            resizable: true,
        },
    ];

    // define grid source
    useEffect(() => {
        if (gridApi) {
            gridApi.showLoadingOverlay();
            const datasource: IDatasource = {
                getRows(params) {
                    //ask for 100 rows after this index
                    const after = params.startRow === 0 ? null : (params.startRow - 1).toString();
                    graphQLClient
                        .ExecuteQueryRaw<IReportsQueryResult, IReportsVariables>({
                            query: ReportsQuery,
                            variables: {
                                after,
                                first: pageSize,
                            },
                        })
                        .result.then(
                            (response) => {
                                gridApi.hideOverlay();
                                if (response.errors?.length) {
                                    console.error("Error fetching data: 1", response.errors);
                                    return;
                                }
                                params.successCallback(response.data?.reports.items ?? [], response.data?.reports.totalCount ?? 0);
                            },
                            (error) => {
                                gridApi.hideOverlay();
                                params.failCallback();
                                console.error("Error fetching data: 2", error);
                            }
                        );
                },
            };
            gridApi.setDatasource(datasource);
        }
    }, [gridApi, graphQLClient]);

    const onSubmit = (e: FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        if (!report) return;
        const props: Record<string, string> = {};
        report.metadata?.parameters?.forEach((param, index) => {
            props[param.name || "unknown"] = params[index] || (param.type === "boolean" ? "0" : "");
        });
        history.push({
            pathname: "/reports/id/" + report.id,
            search: QueryString.stringify(props),
        });
        return false;
    };

    const onDownload = (type: "csv" | "csvZip" | "excel" | "xml" | "json" | "xmlSchema") => {
        if (!report) return false;
        const queryParams: Record<string, string | number | boolean> = {};
        let i = 0;
        report.metadata?.parameters?.forEach((param) => {
            const setValue = ConvertReportParameter(param, params[i++]);
            if (setValue !== undefined) {
                queryParams[param.name || "unknown"] = setValue;
            }
        });
        setDownloading(true);
        graphQLClient
            .ExecuteQueryRaw<{ api: { importExport: { export: { report: any } } } }, { id: string; params: any }>({
                query: `mutation ($id: ID!, $params: Complex) { api { importExport { export { report (id: $id, parameters: $params) { ${type} }}}}}`,
                variables: {
                    id: report.id,
                    params: queryParams,
                },
            })
            .result.then(
                (data) => {
                    setDownloading(false);
                    if (data.errors && data.errors.length) {
                        console.error("Error executing query", data.errors);
                        alert(data.errors[0].message);
                        return;
                    }
                    if (!data.data) return;
                    const actualData = data.data.api.importExport.export.report[type];
                    if (type === "csvZip") doSaveBase64(actualData, "application/zip", "Download.zip");
                    else if (type === "excel")
                        doSaveBase64(actualData, "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet", "Download.xlsx");
                    else if (type === "csv") doSaveText(actualData, "text/csv", "Download.csv");
                    else if (type === "xml") doSaveText(actualData, "application/xml", "Download.xml");
                    else if (type === "xmlSchema") doSaveText(actualData, "application/xml", "Download-Schema.xml");
                    else if (type === "json") doSaveText(actualData, "application/json", "Download.json");
                    else alert("Unknown file format");
                },
                (error) => {
                    setDownloading(false);
                    console.error("Error executing query", error);
                    alert("Error executing query");
                }
            );
        return false;
    };

    return (
        <>
            <PageHeader>Reports</PageHeader>
            <div>
                <ul>
                    <li>
                        <Link to="/reports/warehouse">Warehouse Metrics / Mail Innovations Fulfillments / User Scores Report</Link>
                    </li>
                </ul>
            </div>

            <div className="ag-theme-alpine mt-3">
                <AgGridReact
                    columnDefs={columns}
                    pagination={true}
                    paginationPageSize={pageSize}
                    domLayout="autoHeight"
                    enableCellTextSelection={true}
                    ensureDomOrder={true}
                    onGridReady={(params) => setGridApi(params.api)}
                    rowModelType="infinite"
                    loadingOverlayComponentParams={{ loadingMessage: "Loading" }}
                />
            </div>

            <Modal show={!!report} onHide={() => setReport(null)} size={"lg"}>
                <Form onSubmit={onSubmit}>
                    <Modal.Header closeButton>
                        <Modal.Title>Run Report</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <Row>
                            <Form.Label column sm={3}>
                                Report
                            </Form.Label>
                            <Col sm={9}>
                                <Form.Control plaintext type="text" readOnly value={report?.name} />
                            </Col>
                        </Row>
                        {report?.description ? (
                            <Row>
                                <Form.Label column sm={3}>
                                    Description
                                </Form.Label>
                                <Col sm={9}>
                                    <Form.Control plaintext type="text" readOnly value={report?.description} />
                                </Col>
                            </Row>
                        ) : null}
                        {report?.metadata?.parameters?.map((row, index) => {
                            if (row.type === "boolean") {
                                return (
                                    <Form.Group as={Row} className="mb-3" controlId={"param" + index} key={index}>
                                        <Form.Label column sm={3}>
                                            {row.name}
                                        </Form.Label>
                                        <Col className="d-flex align-items-center" sm={9}>
                                            <Form.Check
                                                checked={params[index] === "1"}
                                                onChange={(e) => {
                                                    const paramCopy = [...params];
                                                    paramCopy[index] = e.currentTarget.checked ? "1" : "0";
                                                    setParams(paramCopy);
                                                }}
                                            />
                                        </Col>
                                    </Form.Group>
                                );
                            }
                            const pattern = row.pattern || (row.type === "number" ? "\\d*.?\\d*" : undefined);
                            const inputType =
                                row.type === "date"
                                    ? "date"
                                    : row.type === "datetimeoffset"
                                    ? "datetime-local"
                                    : row.type === "dateoffset"
                                    ? "date"
                                    : "text";
                            return (
                                <Form.Group as={Row} className="mb-3" controlId={"param" + index} key={index}>
                                    <Form.Label column sm={3}>
                                        {row.name}
                                    </Form.Label>
                                    <Col className="d-flex align-items-center" sm={9}>
                                        <Form.Control
                                            type={inputType}
                                            pattern={pattern}
                                            value={params[index] || ""}
                                            required={row.required || false}
                                            onChange={(e) => {
                                                const paramCopy = [...params];
                                                paramCopy[index] = e.currentTarget.value;
                                                setParams(paramCopy);
                                            }}
                                        />
                                    </Col>
                                </Form.Group>
                            );
                        })}
                    </Modal.Body>
                    <Modal.Footer>
                        <Button type="submit" variant="primary">
                            View
                        </Button>
                        <DropdownButton title="Download" variant="white">
                            <Dropdown.Item href="#" onClick={() => onDownload("excel")}>
                                Excel
                            </Dropdown.Item>
                            <Dropdown.Item href="#" onClick={() => onDownload("csv")}>
                                Csv
                            </Dropdown.Item>
                            <Dropdown.Item href="#" onClick={() => onDownload("csvZip")}>
                                Csv Zip
                            </Dropdown.Item>
                            <Dropdown.Item href="#" onClick={() => onDownload("xml")}>
                                Xml
                            </Dropdown.Item>
                            <Dropdown.Item href="#" onClick={() => onDownload("xmlSchema")}>
                                Xml Schema
                            </Dropdown.Item>
                            <Dropdown.Item href="#" onClick={() => onDownload("json")}>
                                Json
                            </Dropdown.Item>
                        </DropdownButton>
                        {downloading ? <ProgressBar now={100} max={100} animated style={{ width: 150 }} /> : null}
                        <Button variant="secondary" onClick={() => setReport(null)} className="ms-auto">
                            Close
                        </Button>
                    </Modal.Footer>
                </Form>
            </Modal>
        </>
    );
};

export function ConvertReportParameter(param: IReportParameter, data: string | undefined) {
    let val: string | number | boolean | undefined = data;
    if (val !== undefined) {
        if (param.type === "datetimeoffset") {
            val = val ? new Date(val).toISOString() : undefined;
        } else if (param.type === "dateoffset") {
            val = val ? new Date(val + "T00:00:00").toISOString() : undefined;
        } else if (param.type === "number") {
            val = val !== "" ? parseFloat(val) : undefined;
        } else if (param.type === "boolean") {
            val = val === "1";
        }
    }
    return val === "" || val === undefined ? param.defaultValue : val;
}

export default ReportsIndex;
