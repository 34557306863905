import { createElement, useContext, useState } from "react";
import { useHistory } from "react-router";
import { Redirect } from "react-router-dom";
import ErrorDisplay from "../../../../components/misc/ErrorDisplay";
import { GraphQLError, useMutation } from "@shane32/graphql";
import MobileLoading from "../../components/MobileLoading";
import PrimaryHeader from "../../components/PrimaryHeader";
import SecondaryHeader from "../../components/SecondaryHeader";
import MobileTable from "../../components/tables/MobileTable";
import ScannerToneContext from "../../contexts/ScannerToneContext";
import { ISearchBarcodesQueryResult, ISearchBarcodesQueryVariables, searchBarcodesQuery } from "../../graphs/queries/SearchBarcodesQuery";
import useScanner from "../../hooks/useScanner";
import { useSettingsValue } from "../../../../hooks/useSettingsValue";
import useConfirm from "../../hooks/useConfirm";
import useAvailableCarts, { findValidCart } from "../../hooks/useAvailableCarts";

const ScanCart = () => {
    const history = useHistory();
    const [manualLoading, setManualLoading] = useState(false);
    const [titleClickCount, setTitleClickCount] = useState(0);
    const [runSearchBarcodes] = useMutation<ISearchBarcodesQueryResult, ISearchBarcodesQueryVariables>(searchBarcodesQuery);
    const { playPositiveTone, playNegativeTone } = useContext(ScannerToneContext);
    const { confirmModal, showConfirmModal, open: isConfirmModalOpen } = useConfirm();
    const {
        settingsValue: defaultWarehouseId,
        error: defaultWarehouseIdError,
        loading: defaultWarehouseIdLoading,
        refetch: defaultWarehouseIdRefetch,
    } = useSettingsValue("defaultWarehouseId");
    const { availableCarts, availableCartsLoading } = useAvailableCarts(defaultWarehouseId);

    const onScan = async (scannerValue: string) => {
        if (isConfirmModalOpen) return;
        if (!scannerValue) return alert("Please select a cart");
        if (!availableCarts.length) return alert("No empty carts found");
        if (isLoading) return;
        try {
            setManualLoading(true);
            const res = await runSearchBarcodes({ variables: { search: scannerValue, userEntered: false } });
            const validCart = findValidCart(availableCarts, res.data.searchBarcodes, scannerValue);
            if (!validCart) return playNegativeTone();
            if (!(await checkCartQuantity(validCart.pickZoneId))) return;
            playPositiveTone();
            urlSearchParams.set("cartId", validCart.pickZoneId);
            history.push(`/mobile/pick/scanbin/scanbin?${urlSearchParams.toString()}`);
        } catch (error: any) {
            alert((error as GraphQLError)?.message ?? "Unknown error");
        } finally {
            setManualLoading(false);
        }
    };
    useScanner(onScan);

    const isLoading = [availableCartsLoading, manualLoading, defaultWarehouseIdLoading].some((x) => x === true);

    const urlSearchParams = new URLSearchParams(history.location.search);
    const aisleEquipments = urlSearchParams.getAll("aisleEquipment");

    if (aisleEquipments.length < 1) return <Redirect to="/mobile/pick/selectlist" />;

    if (defaultWarehouseIdError) return <ErrorDisplay onClick={defaultWarehouseIdRefetch}>{defaultWarehouseIdError.message}</ErrorDisplay>;

    const onClickCart = async (cartId: string) => {
        if (titleClickCount < 5) return;
        if (!(await checkCartQuantity(cartId))) return;
        urlSearchParams.set("cartId", cartId);
        history.push(`/mobile/pick/scanbin/scanbin?${urlSearchParams.toString()}`);
    };

    const checkCartQuantity = async (cartId: string): Promise<boolean> => {
        //find cart the user clicked on
        const cart = availableCarts.find((x) => x.pickZoneId === cartId);
        if (!cart) return false;
        let userResponse = true;
        //make sure that there are no products in the cart, and if there are, warn the user before continuing
        if (cart.locations.flatMap((x) => x.products).length > 0) {
            userResponse = await showConfirmModal(
                `This cart (${cart.pickZoneName}) has products on it. Are you sure you want to continue?`,
                "Warning",
                { confirm: "Continue", cancel: "Cancel" }
            );
        }
        return userResponse;
    };

    return (
        <>
            {isLoading ? <MobileLoading fullscreen /> : <></>}

            <PrimaryHeader
                title="Carts"
                onTitleClick={() => setTitleClickCount(titleClickCount + 1)}
                includeHomeButton
                includeBackButton
                backButtonText="< Select List"
                customBackButtonPath="/mobile/pick/selectlist"
            />

            <SecondaryHeader title="║▌Scan Cart" nextButtonHandler={() => void 0} />

            <MobileTable>
                <thead>
                    <tr>
                        <td>Cart</td>
                        <td>Last Location</td>
                        <td>Items</td>
                    </tr>
                </thead>
                <tbody>
                    {availableCarts.map((cart) => {
                        return (
                            <tr key={cart.pickZoneId} onClick={async () => await onClickCart(cart.pickZoneId)}>
                                <td>{cart.pickZoneName}</td>
                                <td>{cart.lastLocation?.name ?? cart.stationSet.name}</td>
                                <td>
                                    {cart.locations.flatMap((location) => location.products).reduce((val, acc) => acc.stockOnHand + val, 0)}
                                </td>
                            </tr>
                        );
                    })}
                </tbody>
            </MobileTable>

            {titleClickCount < 5 ? <></> : <div style={{ margin: "10px", color: "#CC0A3B", fontWeight: "bold" }}>Select Cart Enabled</div>}

            {createElement(confirmModal)}
        </>
    );
};

export default ScanCart;
