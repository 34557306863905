import { createElement, useContext, useEffect, useRef, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import ModelType from "../../../../enums/ModelType";
import { GraphQLError, useMutation } from "@shane32/graphql";
import MobileButton from "../../components/buttons/MobileButton";
import MobileButtonCol from "../../components/buttons/MobileButtonCol";
import MobileButtonContainer from "../../components/buttons/MobileButtonContainer";
import MobileButtonRow from "../../components/buttons/MobileButtonRow";
import ActiveProductImages from "../../components/images/ActiveProductImages";
import MobileLoading from "../../components/MobileLoading";
import PrimaryHeader from "../../components/PrimaryHeader";
import SecondaryHeader from "../../components/SecondaryHeader";
import MobileTable from "../../components/tables/MobileTable";
import ScannerToneContext from "../../contexts/ScannerToneContext";
import { ISearchBarcodesQueryResult, ISearchBarcodesQueryVariables, searchBarcodesQuery } from "../../graphs/queries/SearchBarcodesQuery";
import useConfirm from "../../hooks/useConfirm";
import useInputModal from "../../hooks/useInputModal";
import useScanner from "../../hooks/useScanner";
import useSelectModal, { ISelect } from "../../hooks/useSelectModal";
import forgeURLSearchParams from "../../helpers/forgeURLSearchParameters";
import { IStockTransferSearchParams } from "./StockTransferController";
import SelectionType from "../../../../enums/SelectionType";
import transferListStyles from "./GenerateCustomTransferList.module.scss";
import { Button } from "react-bootstrap";
import { useSettingsValue } from "../../../../hooks/useSettingsValue";
import GroupBy from "../../../../helpers/GroupBy";
import { sortPickList } from "../../helpers/aisle_sorting/aisleSortOrder";
import MobileToggle from "../../components/toggles/MobileToggle";
import advancedFilterStyles from "./AdvancedFiltersModal.module.scss";
import typedParse from "../../helpers/typedParse";

const locationQuery = `
query ($locationId: ID!) {
  location(id: $locationId) {
    id
    name
    warehouse {
      name
    }
  }
}
`;

interface ILocationQueryVariables {
    locationId: string;
}

interface ILocation {
    id: string;
    name?: string;
    warehouse?: {
        name?: string;
    };
}

interface ILocationQueryResult {
    location: ILocation;
}

type FilterPlinth = {
    table: string;
    property: string;
};

type PossibleEqualitySymbols = "" | "=" | "!=" | ">" | "<";

type FilterVariations =
    | {
          type: "string";
          equalitySymbol: "=" | "!=";
          value: string;
      }
    | {
          type: "number";
          equalitySymbol: ">" | "<" | "=" | "!=";
          value: number;
      }
    | {
          type: "date";
          equalitySymbol: ">" | "<" | "=" | "!=";
          value: string;
      }
    | {
          type: "";
          equalitySymbol: "";
          value: "";
      };

export type Filter = FilterPlinth & FilterVariations;

interface IPropertySet {
    table: string;
    friendlyName: string;
    property: string;
    type: "string" | "number" | "date";
}

const propertySets: IPropertySet[] = [
    { table: "SaleLineItem", friendlyName: "Quantity Sold", property: "Quantity", type: "number" },
    { table: "Sale", friendlyName: "Sales Date Range", property: "OrderDate", type: "date" },
    { table: "Location", friendlyName: "Rack", property: "Rack", type: "string" },
    { table: "Location", friendlyName: "Shelf", property: "Shelf", type: "string" },
    { table: "Location", friendlyName: "Side", property: "Side", type: "string" },
    { table: "Product", friendlyName: "Sku", property: "Sku", type: "string" },
];

const userQueryFilterMutation = `
query ($userQueryFilters: [UserQueryFilter!]!, $warehouseId: ID!) {
    mobile {
      getLocationProductsByUserQuery(userQueryFilters: $userQueryFilters, warehouseId: $warehouseId) {
        location {
          id
          name
          aisle
          side
          rack
          shelf
          bin
        }
        product {
          id
          sku
          description
        }
        stockOnHand
      }
    }
  }
`;

interface IUserQueryFilterVariables {
    warehouseId: string;
    userQueryFilters: Array<IUserQueryFilter>;
}

export interface ILocationProduct {
    location: {
        id: string;
        name: string;
        aisle: string | null;
        side: string | null;
        rack: string | null;
        shelf: string | null;
        bin: string | null;
    };
    product: {
        id: string;
        sku: string;
        description: string;
    };
    stockOnHand: number;
    direction: number | null;
}

interface IUserQueryFilterResult {
    mobile: {
        getLocationProductsByUserQuery: Array<ILocationProduct>;
    };
}

interface IUserQueryFilter {
    table: string;
    property: string;
    equalitySymbol: string;
    value: string;
}

const ScanBinComponent = () => {
    const history = useHistory<Filter[]>();
    const location = useLocation();
    const rawURLSearchParams = new URLSearchParams(decodeURIComponent(location.search));
    const urlSearchParams = typedParse<IStockTransferSearchParams>(rawURLSearchParams.get("urlsp"));
    const { settingsValue: defaultWarehouseId, loading: defaultWarehouseIdLoading } = useSettingsValue("defaultWarehouseId");
    const scannerToneContext = useContext(ScannerToneContext);
    const [manualLoading, setManualLoading] = useState(false);
    const [showAdvancedFiltersModal, setShowAdvancedFiltersModal] = useState(false);
    const [showGenerateCustomTransferListModal, setShowGenerateCustomTransferListModal] = useState(false);
    const [filterList, setFilterList] = useState<Filter[]>(history.location.state ?? []);
    const [runSearchBarcodes] = useMutation<ISearchBarcodesQueryResult, ISearchBarcodesQueryVariables>(searchBarcodesQuery);
    const [runLocationQuery] = useMutation<ILocationQueryResult, ILocationQueryVariables>(locationQuery);
    const { inputModal, showInputModal } = useInputModal();
    const { selectModal, showSelectModal } = useSelectModal();
    const { confirmModal, showConfirmModal } = useConfirm();
    const [userQueryFilterLocationProducts, setUserQueryFilterLocationProducts] = useState<ILocationProduct[]>([]);
    const mounted = useRef(true);
    const userQueryFiltersKey = "userQueryFilters";

    useEffect(() => {
        return () => {
            mounted.current = false;
        };
    }, [location.pathname]);

    const onScan = async (scannedValue: string) => {
        await locationSearchAsync(scannedValue, false);
    };
    useScanner(onScan);

    useEffect(() => {
        if (showGenerateCustomTransferListModal) document.documentElement.style.overflow = "hidden";
        else document.documentElement.style.overflow = "auto";

        return () => {
            document.documentElement.style.overflow = "auto";
        };
    }, [showGenerateCustomTransferListModal]);

    //re-fetch the userQueryFilterLocationProducts when the defaultWarehouseId is loaded
    useEffect(() => {
        if (!defaultWarehouseIdLoading) {
            if (history.location.state?.length > 0) {
                (async () => {
                    await onSubmitUserFiltersAsync();
                })();
            } else if (localStorage.getItem(userQueryFiltersKey)) {
                const filters: Filter[] = JSON.parse(localStorage.getItem(userQueryFiltersKey) ?? "");
                setFilterList(filters.filter((x) => propertySets.map((ps) => ps.property).includes(x.property)));
            }
        }
    }, [defaultWarehouseIdLoading]); // eslint-disable-line react-hooks/exhaustive-deps

    const [runUserQueryFilterMutation] = useMutation<IUserQueryFilterResult, IUserQueryFilterVariables>(userQueryFilterMutation);

    const locationProductsByLocationId = GroupBy(userQueryFilterLocationProducts, (x) => x.location.id);

    const nextButtonHandler = async () => {
        const input = (await showInputModal("Enter a Location Name"))?.trim();
        if (!input) return;
        await locationSearchAsync(input, true);
    };

    const locationSearchAsync = async (value: string, userEntered: boolean) => {
        if (manualLoading) return;
        try {
            setManualLoading(true);
            let locationId: string | undefined = undefined;
            const res = await runSearchBarcodes({ variables: { search: value, userEntered: userEntered } });
            const barcodeLocations = res.data.searchBarcodes.filter((x) => x.type === ModelType.Location);
            if (barcodeLocations.length > 0) {
                if (barcodeLocations.length > 1) {
                    if (
                        !(await showConfirmModal(
                            `There are ${barcodeLocations.length} locations matching ${value}. Please click 'Continue' to load all of the locations data (The more matching locations, the longer the query will take).`,
                            "Multiple Locations Found!",
                            { confirm: "Continue", cancel: "Cancel" }
                        ))
                    )
                        return;

                    let locations: ILocation[] = [];
                    for (let bl of barcodeLocations) {
                        if (mounted.current) {
                            const location = (await runLocationQuery({ variables: { locationId: bl.id } })).data.location;
                            locations.push(location);
                        }
                    }

                    if (locations.length < 1) {
                        await showConfirmModal("No locations found. Please try again!", "No Locations Found!", undefined, true);
                        return;
                    }

                    locationId = (
                        await showSelectModal(
                            "Select Location",
                            locations
                                .filter((x) => x.name && x.warehouse?.name)
                                .sort((a, b) => (b.name ?? "").localeCompare(a.name ?? ""))
                                .map((x) => ({ description: `${x.name ?? ""} (${x.warehouse?.name ?? ""})`, id: x.id } as ISelect))
                        )
                    )?.id;
                } else locationId = barcodeLocations[0].id;
            } else return scannerToneContext.playNegativeTone();

            if (!locationId) return scannerToneContext.playNegativeTone();

            if (
                userQueryFilterLocationProducts.length > 0 &&
                !userQueryFilterLocationProducts.map((x) => x.location.id).includes(locationId)
            ) {
                const result = await showConfirmModal(
                    "The location you searched is not in your custom transfer list! Do you want to continue?",
                    "Location Confirm",
                    { confirm: "Yes", cancel: "No" },
                    false
                );
                if (!result) return;
            }

            scannerToneContext.playPositiveTone();
            history.push(
                `/mobile/stocktransfer/pickbin/${locationId}?${forgeURLSearchParams<IStockTransferSearchParams>({
                    ...urlSearchParams,
                    fromLocationSelectionType: userEntered ? SelectionType.Searched : SelectionType.Scanned,
                    matchingProductIds: locationProductsByLocationId.get(locationId)?.map((x) => x.product.id) ?? [],
                })}`,
                history.location.state
            );
        } catch (error: any) {
            alert((error as GraphQLError)?.message ?? "Unknown error");
        } finally {
            setManualLoading(false);
        }
    };

    const addFilter = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
        e.preventDefault();
        setFilterList([...filterList, { table: "", property: "", type: "", equalitySymbol: "", value: "" }]);
    };

    const deleteFilter = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>, i: number) => {
        e.preventDefault();
        let filterListCopy = [...filterList];
        const newList = filterListCopy.filter((x, index) => index !== i);
        setFilterList(newList);
    };

    const handlePropertySelectChange = (e: React.ChangeEvent<HTMLSelectElement>, i: number) => {
        let filterListCopy = [...filterList];
        const type = propertySets.find((x) => x.property === e.target.value)?.type;
        if (!type) return alert("Current filter does not have valid type.");
        const table = propertySets.find((x) => x.property === e.target.value)?.table;
        if (!table) return alert("Current filter does not have valid table.");

        filterListCopy[i].property = e.target.value;
        filterListCopy[i].type = type;
        filterListCopy[i].equalitySymbol = "";
        filterListCopy[i].value = "";
        filterListCopy[i].table = table;
        setFilterList(filterListCopy);
    };

    const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>, i: number) => {
        const filterListCopy = [...filterList];
        const filter = filterListCopy[i];

        switch (filter.type) {
            case "number":
                filter.value = +e.target.value;
                break;
            case "string":
            case "date":
                filter.value = e.target.value;
                break;
            default:
                break;
        }
        setFilterList(filterListCopy);
    };

    const handleEqualityChange = (e: React.ChangeEvent<HTMLSelectElement>, i: number) => {
        let filterListCopy = [...filterList];
        const filter = filterListCopy[i];
        filter.equalitySymbol = e.target.value as PossibleEqualitySymbols;
        setFilterList(filterListCopy);
    };

    const submitAdvancedFilters = (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        history.replace(
            `${location.pathname}?${forgeURLSearchParams<IStockTransferSearchParams>({
                ...urlSearchParams,
                showOneLocation: e.currentTarget["showOneLocation"].checked,
                removeSkuAfterScan: e.currentTarget["removeSkuAfterScan"].checked,
            })}`,
            history.location.state
        );
        setShowAdvancedFiltersModal(false);
    };

    const onSubmitUserFiltersAsync = async () => {
        //remove this requirement when we figure out how to handle extremely large lists.
        //for now this is a temporary solution to prevent the app from lagging.
        // if (filterList.length === 1 && filterList[0].property === "Side") {
        //     return alert("You must add at least one filter OTHER THAN the default 'Side' requirement.");
        // }

        if (!defaultWarehouseId) return alert("Unable to find default warehouse id.");
        try {
            setManualLoading(true);
            let userQueryFilters: IUserQueryFilter[] = [];
            // Convert all entries to lowercase
            const lowercaseSkusToRemove = (urlSearchParams.skusToRemove ?? []).map((x) => x.toLowerCase().trim());
            filterList.forEach((x) => {
                // This is a special check for the pair table = "Product" and property = "Sku".
                // If the user has selected this pair and has enabled "Remove SKU After Scan",
                // use the values from the "skusToRemove" url search param to filter out any matching sku.
                let value = x.value.toString();

                if (x.table === "Product" && x.property === "Sku" && urlSearchParams.removeSkuAfterScan) {
                    // Convert the comma-separated string into an array
                    const skuStringArray = x.value
                        .toString()
                        .toLowerCase()
                        .split(",")
                        .map((x) => x.trim());
                    // Filter out the SKUs that appear in the urlSearchParams.skusToRemove array
                    const filteredSkuArray = skuStringArray.filter((sku) => !lowercaseSkusToRemove.includes(sku));
                    // Convert the filtered array back into a comma-separated string
                    value = filteredSkuArray.join(",");
                }

                if (value) {
                    userQueryFilters.push({
                        table: x.table,
                        property: x.property,
                        equalitySymbol: x.equalitySymbol,
                        value: value,
                    });
                }
            });

            if (userQueryFilters.length < 1) return;

            const queryResult = await runUserQueryFilterMutation({
                variables: {
                    warehouseId: defaultWarehouseId,
                    userQueryFilters: userQueryFilters,
                },
            });

            const locationProductsByUserQuery = queryResult.data.mobile.getLocationProductsByUserQuery;
            let locationProductsByUserQueryCopy = [...locationProductsByUserQuery];

            // If there are any advanced filters enabled, we need to apply them to the userQueryFilterLocationProducts
            if (urlSearchParams.showOneLocation) {
                const groupedByLocationId = GroupBy(locationProductsByUserQueryCopy, (x) => x.product.sku);
                const firstInstances: ILocationProduct[] = [];
                groupedByLocationId.forEach((products) => {
                    if (products.length > 0) {
                        firstInstances.push(products[0]);
                    }
                });
                locationProductsByUserQueryCopy = firstInstances;
            }

            if (urlSearchParams.removeSkuAfterScan) {
                locationProductsByUserQueryCopy = locationProductsByUserQueryCopy.filter(
                    (x) => !lowercaseSkusToRemove.includes(x.product.sku.toLowerCase())
                );
            }

            setUserQueryFilterLocationProducts(locationProductsByUserQueryCopy);
            localStorage.setItem(userQueryFiltersKey, JSON.stringify(filterList));
        } catch (error: any) {
            alert((error as GraphQLError)?.message ?? (error as Error)?.message ?? "Unknown error");
        } finally {
            setShowGenerateCustomTransferListModal(false);
            setManualLoading(false);
            history.replace(`${location.pathname}?${forgeURLSearchParams({ ...urlSearchParams })}`, filterList);
        }
    };

    const sortedUserQueryFilterLocationProducts = sortPickList(userQueryFilterLocationProducts);

    const firstProduct = sortedUserQueryFilterLocationProducts.find((x) => x);

    const otherProducts = sortedUserQueryFilterLocationProducts.slice(1);

    return (
        <>
            {manualLoading || defaultWarehouseIdLoading ? <MobileLoading fullscreen /> : <></>}

            {!showGenerateCustomTransferListModal ? (
                <>
                    <PrimaryHeader
                        title={"Scan Bin"}
                        includeHomeButton
                        includeBackButton
                        backButtonText={`< Roles`}
                        customBackButtonPath={`/mobile/selectrole`}
                    />

                    <SecondaryHeader title={"Scan Bin to Pick From"} nextButtonHandler={async () => await nextButtonHandler()} />

                    <MobileTable isActiveTable>
                        <thead>
                            <tr>
                                <td>Bin</td>
                                <td>Hand</td>
                                <td>Bin</td>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td>{firstProduct?.stockOnHand ?? "-"}</td>
                                <td>-</td>
                                <td>-</td>
                            </tr>
                            <tr>
                                <td>{firstProduct?.location?.name ?? "-"}</td>
                                <td>{firstProduct?.product?.sku ?? "-"}</td>
                                <td>Any</td>
                            </tr>
                        </tbody>
                    </MobileTable>

                    {/* image set */}
                    <div style={{ padding: "10px 10px 0 10px" }}>
                        <ActiveProductImages sku={firstProduct?.product?.sku} />
                    </div>
                    {/* category */}
                    <div style={{ fontSize: 12, margin: "5px 10px 0 10px" }}>
                        Description: <b>{firstProduct?.product?.description}</b>
                    </div>

                    <MobileButtonContainer>
                        <MobileButtonRow>
                            <MobileButtonCol>
                                <MobileButton disabled>Stow ({0}) to Any</MobileButton>
                            </MobileButtonCol>
                            <MobileButtonCol>
                                <MobileButton disabled>-</MobileButton>
                                <MobileButton disabled>+</MobileButton>
                                <MobileButton disabled>Max</MobileButton>
                            </MobileButtonCol>
                        </MobileButtonRow>
                        <MobileButtonRow>
                            <MobileButton disabled>Add Product</MobileButton>
                            <MobileButton disabled>Lost Sku</MobileButton>
                        </MobileButtonRow>
                        <MobileButtonRow>
                            <MobileButton disabled>{`Move all Products to Any`}</MobileButton>
                            <MobileButton disabled>{`Add or Print Barcode`}</MobileButton>
                        </MobileButtonRow>
                        <MobileButtonRow>
                            <MobileButton onClick={() => setShowGenerateCustomTransferListModal(true)}>
                                {filterList.length ? `Edit Custom Transfer List` : `Generate Custom Transfer List`}
                            </MobileButton>
                        </MobileButtonRow>
                    </MobileButtonContainer>

                    {otherProducts.length > 0 && (
                        <MobileTable>
                            <thead>
                                <tr>
                                    <td>Location</td>
                                    <td>Sku</td>
                                    <td>Qty</td>
                                </tr>
                            </thead>
                            <tbody>
                                {otherProducts.map((locationProduct, i) => {
                                    return (
                                        <tr
                                            key={i}
                                            onClick={() => {
                                                history.push(
                                                    `/mobile/stocktransfer/pickbin/${
                                                        locationProduct.location.id
                                                    }?${forgeURLSearchParams<IStockTransferSearchParams>({
                                                        ...urlSearchParams,
                                                        fromLocationSelectionType: SelectionType.Selected,
                                                        matchingProductIds:
                                                            locationProductsByLocationId
                                                                .get(locationProduct.location.id)
                                                                ?.map((x) => x.product.id) ?? [],
                                                    })}`,
                                                    history.location.state
                                                );
                                            }}
                                        >
                                            <td>{locationProduct.location.name}</td>
                                            <td>{locationProduct.product.sku}</td>
                                            <td>{locationProduct.stockOnHand}</td>
                                        </tr>
                                    );
                                })}
                            </tbody>
                        </MobileTable>
                    )}

                    {createElement(inputModal)}

                    {createElement(selectModal)}

                    {createElement(confirmModal)}
                </>
            ) : (
                <>
                    {showAdvancedFiltersModal && (
                        <div>
                            <div className={advancedFilterStyles.backdrop}>
                                <form onSubmit={(e) => submitAdvancedFilters(e)} className={advancedFilterStyles.container}>
                                    <button onClick={() => setShowAdvancedFiltersModal(false)} className={advancedFilterStyles.closeButton}>
                                        X
                                    </button>
                                    <div className={advancedFilterStyles.titleContainer}>
                                        <div className={advancedFilterStyles.title}>Advanced Filters</div>
                                    </div>
                                    <div className={advancedFilterStyles.filtersScrollContainer}>
                                        <div className={advancedFilterStyles.filtersDirectionContainer}>
                                            <div className={advancedFilterStyles.filterContainerToggle}>
                                                <div className={advancedFilterStyles.filterTitle}>Show One Location Per SKU</div>
                                                <MobileToggle
                                                    defaultChecked={urlSearchParams.showOneLocation}
                                                    name="showOneLocation"
                                                    id="showOneLocation"
                                                />
                                            </div>
                                            <div className={advancedFilterStyles.filterContainerToggle}>
                                                <div className={advancedFilterStyles.filterTitle}>Remove SKU After Scan</div>
                                                <MobileToggle
                                                    defaultChecked={urlSearchParams.removeSkuAfterScan}
                                                    name="removeSkuAfterScan"
                                                    id="removeSkuAfterScan"
                                                />
                                            </div>
                                        </div>
                                    </div>
                                    <div>
                                        <button type="submit" className={advancedFilterStyles.applyFiltersButton}>
                                            Apply Advanced Filters
                                        </button>
                                    </div>
                                </form>
                            </div>
                        </div>
                    )}

                    <div className={transferListStyles.container}>
                        <form
                            className={transferListStyles.content}
                            onSubmit={async (e) => {
                                e.preventDefault();
                                await onSubmitUserFiltersAsync();
                            }}
                        >
                            <div className={transferListStyles.titleContainer}>
                                <div className={transferListStyles.title}>
                                    <div>Add Filters</div>
                                    <button
                                        onClick={(e) => {
                                            e.preventDefault();
                                            setShowAdvancedFiltersModal(true);
                                        }}
                                        className={transferListStyles.advanced}
                                    >
                                        Advanced
                                    </button>
                                </div>
                                <button className={transferListStyles.addButton} onClick={addFilter}>
                                    <div className={transferListStyles.circle}>
                                        <div className={transferListStyles.vertical}></div>
                                        <div className={transferListStyles.horizontal}></div>
                                    </div>
                                </button>
                            </div>
                            <div className={transferListStyles.main}>
                                {filterList.map((filter, i) => {
                                    const typeInputs = (() => {
                                        switch (filter.type) {
                                            case "number":
                                                return (
                                                    <>
                                                        <select
                                                            name={"equalitySymbol"}
                                                            value={filter.equalitySymbol}
                                                            onChange={(e) => handleEqualityChange(e, i)}
                                                        >
                                                            <option value="">{""}</option>
                                                            <option value=">">{">"}</option>
                                                            <option value=">=">{">="}</option>
                                                            <option value="<">{"<"}</option>
                                                            <option value="<=">{"<="}</option>
                                                            <option value="=">{"="}</option>
                                                            <option value="!=">{"!="}</option>
                                                        </select>
                                                        <input
                                                            type="number"
                                                            name="value"
                                                            value={filter.value}
                                                            onChange={(e) => handleInputChange(e, i)}
                                                            onKeyDown={(e) => {
                                                                if (e.key === "Enter") {
                                                                    e.preventDefault();
                                                                    e.currentTarget.blur();
                                                                }
                                                            }}
                                                        />
                                                    </>
                                                );
                                            case "string":
                                                return (
                                                    <>
                                                        <select
                                                            name={"equalitySymbol"}
                                                            value={filter.equalitySymbol}
                                                            onChange={(e) => handleEqualityChange(e, i)}
                                                        >
                                                            <option value="">{""}</option>
                                                            <option value={"="}>{"="}</option>
                                                            <option value={"!="}>{"!="}</option>
                                                        </select>
                                                        <input
                                                            type="text"
                                                            name="value"
                                                            value={filter.value}
                                                            onChange={(e) => handleInputChange(e, i)}
                                                            onKeyDown={(e) => {
                                                                if (e.key === "Enter") {
                                                                    e.preventDefault();
                                                                    e.currentTarget.blur();
                                                                }
                                                            }}
                                                        />
                                                    </>
                                                );
                                            case "date":
                                                return (
                                                    <>
                                                        <select
                                                            name={"equalitySymbol"}
                                                            value={filter.equalitySymbol}
                                                            onChange={(e) => handleEqualityChange(e, i)}
                                                        >
                                                            <option value="">{""}</option>
                                                            <option value=">">{"After"}</option>
                                                            <option value="<">{"Before"}</option>
                                                            <option value="=">{"On"}</option>
                                                            <option value="!=">{"Not On"}</option>
                                                        </select>
                                                        <input
                                                            type="date"
                                                            name="value"
                                                            value={filter.value}
                                                            onChange={(e) => handleInputChange(e, i)}
                                                            onKeyDown={(e) => {
                                                                if (e.key === "Enter") {
                                                                    e.preventDefault();
                                                                    e.currentTarget.blur();
                                                                }
                                                            }}
                                                        />
                                                    </>
                                                );
                                            default:
                                                return <></>;
                                        }
                                    })();

                                    const filterPropertySelect = (
                                        <>
                                            <div className={transferListStyles.propertyContainer}>
                                                <select
                                                    name="property"
                                                    value={filter.property}
                                                    onChange={(e) => handlePropertySelectChange(e, i)}
                                                >
                                                    <option value=""></option>
                                                    {propertySets?.map((x) => (
                                                        <option key={i + x.property} value={x.property}>
                                                            {x.friendlyName}
                                                        </option>
                                                    ))}
                                                </select>
                                            </div>
                                            <div className={transferListStyles.valueRow}>{typeInputs}</div>
                                        </>
                                    );

                                    return (
                                        <div key={i} className={transferListStyles.filterRowContainer}>
                                            <button onClick={(e) => deleteFilter(e, i)} className={transferListStyles.removeButton}>
                                                X
                                            </button>
                                            <div className={transferListStyles.propertyRow}>{filterPropertySelect}</div>
                                        </div>
                                    );
                                })}
                            </div>
                            <div className={transferListStyles.bottomButtonsContainer}>
                                <Button
                                    variant="outline-danger"
                                    onClick={() => {
                                        setFilterList([]);
                                        localStorage.removeItem(userQueryFiltersKey);
                                        history.replace(`${location.pathname}?${forgeURLSearchParams({})}`);
                                        setUserQueryFilterLocationProducts([]);
                                    }}
                                >
                                    <b>Reset</b>
                                </Button>
                                <Button className="btn-secondary" onClick={() => setShowGenerateCustomTransferListModal(false)}>
                                    <b>Close</b>
                                </Button>
                                <Button type="submit">
                                    <b>Submit</b>
                                </Button>
                            </div>
                        </form>
                    </div>
                </>
            )}
        </>
    );
};

export default ScanBinComponent;
