import React, { createElement, useEffect, useRef, useState } from "react";
import { useHistory } from "react-router";
import PrimaryHeader from "../../components/PrimaryHeader";
import SecondaryHeader from "../../components/SecondaryHeader";
import IAisleEquipment from "../../models/AisleEquipment";
import { useQuery } from "@shane32/graphql";
import ErrorDisplay from "../../../../components/misc/ErrorDisplay";
import MobileLoading from "../../components/MobileLoading";
import MobileTable from "../../components/tables/MobileTable";
import MobileTableTitle from "../../components/tables/MobileTableTitle";
import MobileToggle from "../../components/toggles/MobileToggle";
import { useSettingsValue } from "../../../../hooks/useSettingsValue";
import MobileCheckbox from "../../components/checkboxes/MobileCheckbox";
import pickListFilterStyles from "./PickListFilter.module.scss";
import MobileInput from "../../components/inputs/MobileInput";
import useConfirm from "../../hooks/useConfirm";
import * as Queries from "./SelectListQueries.g";

export interface IFilters {
    shipByToday: boolean;
    /** These should be ids */
    salesChannels: string[];
    saleIds: string[];
}

const SelectList = () => {
    const history = useHistory();
    const [selectedAisleEquipments, setSelectedAisleEquipments] = useState<IAisleEquipment[]>([]);
    const filtersDataRef = useRef<boolean>(false);
    const { confirmModal, showConfirmModal } = useConfirm();
    const [filters, setFilters] = useState<IFilters>({
        salesChannels: [],
        shipByToday: true,
        saleIds: [],
    });
    const [isFilterModalShowing, setIsFilterModalShowing] = useState(false);
    const {
        settingsValue: defaultWarehouseId,
        error: defaultWarehouseIdError,
        loading: defaultWarehouseIdLoading,
        refetch: defaultWarehouseIdRefetch,
    } = useSettingsValue("defaultWarehouseId");
    const {
        data: displayAreasData,
        error: displayAreasError,
        refetch: displayAreasRefetch,
        loading,
    } = useQuery(Queries.SelectListDisplayAreasToPickDocument, {
        fetchPolicy: "no-cache",
        variables: !defaultWarehouseId
            ? undefined
            : {
                  warehouseId: defaultWarehouseId,
                  displayPicksFiltersInputModel: filters,
              },
        skip: !defaultWarehouseId,
    });

    const { data: filtersData, loading: filtersLoading } = useQuery(Queries.SelectListFiltersDocument, {
        fetchPolicy: "no-cache",
    });

    useEffect(() => {
        setSelectedAisleEquipments([]);
    }, [filters.saleIds, filters.salesChannels, filters.shipByToday]);

    useEffect(() => {
        if (filtersDataRef.current === false && filtersData && !filtersLoading) {
            filtersDataRef.current = true;
            setFilters({
                ...filters,
                salesChannels: filtersData.salesChannels.items.filter((x) => x.name !== "Amazon Vendor").map((x) => x.id),
            });
        }
    }, [filters, filtersData, filtersLoading]);

    useEffect(() => {
        switch (isFilterModalShowing) {
            case true:
                document.documentElement.style.overflow = "hidden";
                break;
            case false:
                document.documentElement.style.overflow = "auto";
                break;
        }

        return () => {
            document.documentElement.style.overflow = "auto";
        };
    }, [isFilterModalShowing]);

    const isLoading = [loading, defaultWarehouseIdLoading, filtersLoading].some((x) => x === true);

    if (displayAreasError) return <ErrorDisplay onClick={displayAreasRefetch}>{displayAreasError.message}</ErrorDisplay>;
    if (defaultWarehouseIdError) return <ErrorDisplay onClick={defaultWarehouseIdRefetch}>{defaultWarehouseIdError.message}</ErrorDisplay>;

    const tableRowClickHandler = (e: React.MouseEvent<HTMLTableRowElement, MouseEvent>) => {
        const keys = JSON.parse(e.currentTarget.id) as IAisleEquipment;
        let copy = [...selectedAisleEquipments];
        const index = copy.findIndex((x) => x.aisle === keys.aisle && x.equipmentId === keys.equipmentId);
        if (index > -1) copy.splice(index, 1);
        else copy.push(keys);
        copy.sort((a, b) => a.aisle.localeCompare(b.aisle));
        setSelectedAisleEquipments(copy);
    };

    const nextButtonHandler = () => {
        if (selectedAisleEquipments.length < 1) return alert("Please select a list(s)");

        const urlSearchParams = new URLSearchParams();
        selectedAisleEquipments.forEach((aisleEquipment) => {
            urlSearchParams.append("aisleEquipment", JSON.stringify(aisleEquipment));
        });
        urlSearchParams.set("displayPicksFilters", JSON.stringify(filters));

        history.push(`/mobile/pick/scancart?${urlSearchParams.toString()}`);
    };

    const submitFilters = (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        setFilters({
            ...filters,
            salesChannels: Array.from(
                e.currentTarget.querySelectorAll("input[type=checkbox][name=salesChannel]:checked") as NodeListOf<HTMLInputElement>
            ).map((x) => x.id),
            shipByToday: (e.currentTarget.querySelector("input[type=checkbox][name=shipByToday]") as HTMLInputElement).checked,
            saleIds: (e.currentTarget.querySelector("input[type=search][name=saleIds]") as HTMLInputElement).value
                .split(",")
                .map((x) => x.trim())
                .filter((x) => x),
        });
        setIsFilterModalShowing(false);
    };

    const displayAreasToPick = displayAreasData?.mobile.displayAreasToPick ?? [];

    return (
        <>
            {isLoading ? <MobileLoading fullscreen /> : <></>}

            <PrimaryHeader
                title="Pick Lists"
                includeHomeButton
                includeBackButton
                backButtonText="< Roles"
                customBackButtonPath="/mobile/selectrole"
            />

            <SecondaryHeader title="Select List" nextButtonHandler={nextButtonHandler} />

            <div
                style={{
                    padding: "0 10px",
                    height: 40,
                    backgroundColor: "#EDF0F4",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                }}
            >
                <div style={{ display: "flex", gap: 10, alignItems: "center" }}>
                    <button
                        onClick={() => setIsFilterModalShowing(true)}
                        style={{
                            border: "none",
                            backgroundColor: "transparent",
                            color: "#2F2F2F",
                            fontSize: 36,
                            width: 50,
                            textAlign: "left",
                            height: "inherit",
                            margin: 0,
                            padding: 0,
                            display: "flex",
                            alignItems: "center",
                        }}
                    >
                        <i
                            className="bi bi-filter"
                            style={{
                                marginTop: 10,
                                fontSize: 40,
                                lineHeight: 0,
                                height: "inherit",
                                display: "inline-block",
                            }}
                        />
                    </button>
                    {displayAreasToPick.length < 1 ? (
                        <></>
                    ) : (
                        <button
                            onClick={() => {
                                if (selectedAisleEquipments.length === displayAreasToPick.length) {
                                    setSelectedAisleEquipments([]);
                                } else {
                                    setSelectedAisleEquipments(
                                        displayAreasToPick.map((x) => ({ aisle: x.aisle ?? "", equipmentId: x.equipment.id }))
                                    );
                                }
                            }}
                            style={{
                                border: "1px solid black",
                                backgroundColor: "transparent",
                                color: "#2F2F2F",
                                fontSize: 13,
                                height: 30,
                                width: 80,
                                margin: "5px 0 0 0",
                                padding: 0,
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "center",
                                borderRadius: 4,
                            }}
                        >
                            {selectedAisleEquipments.length === displayAreasToPick.length ? "Deselect All" : "Select All"}
                        </button>
                    )}
                </div>

                <div
                    style={{
                        display: "flex",
                        alignItems: "center",
                        gap: 10,
                    }}
                >
                    <div style={{ fontWeight: 550 }}>Ship By Today</div>
                    {!isFilterModalShowing && (
                        <MobileToggle
                            defaultChecked={filters.shipByToday}
                            onClick={() => setFilters({ ...filters, shipByToday: !filters.shipByToday })}
                            name="shipByTodayQuickToggle"
                            id="shipByTodayQuickToggle"
                        />
                    )}
                </div>
            </div>

            {displayAreasToPick.length > 0 ? (
                <MobileTable>
                    <thead>
                        <tr>
                            <td>Items</td>
                            <td>Aisle</td>
                            <td>Equipment</td>
                        </tr>
                    </thead>
                    <tbody>
                        {displayAreasToPick.map((x, i) => {
                            const keys = { aisle: x.aisle ?? "", equipmentId: x.equipment.id } as IAisleEquipment;
                            const bgColor =
                                selectedAisleEquipments.findIndex((x) => x.aisle === keys.aisle && x.equipmentId === keys.equipmentId) > -1
                                    ? "#CFE3FA"
                                    : "white";
                            return (
                                <tr
                                    key={i}
                                    id={JSON.stringify({ aisle: x.aisle ?? "", equipmentId: x.equipment.id } as IAisleEquipment)}
                                    onClick={(e) => tableRowClickHandler(e)}
                                    style={{ backgroundColor: bgColor }}
                                >
                                    <td>{x.quantity}</td>
                                    <td>{x.aisle}</td>
                                    <td>{x.equipment.name}</td>
                                </tr>
                            );
                        })}
                    </tbody>
                </MobileTable>
            ) : (
                <MobileTableTitle>📦 Nothing to pick! 📦</MobileTableTitle>
            )}

            {isFilterModalShowing && (
                <div className={pickListFilterStyles.backdrop}>
                    <form onSubmit={(e) => submitFilters(e)} className={pickListFilterStyles.container}>
                        <button onClick={() => setIsFilterModalShowing(false)} className={pickListFilterStyles.closeButton}>
                            X
                        </button>
                        <div className={pickListFilterStyles.titleContainer}>
                            <div className={pickListFilterStyles.title}>Filters</div>
                        </div>
                        <div className={pickListFilterStyles.filtersScrollContainer}>
                            <div className={pickListFilterStyles.filtersDirectionContainer}>
                                <div className={pickListFilterStyles.filterContainerToggle}>
                                    <div className={pickListFilterStyles.filterTitle}>Ship By Today</div>
                                    <MobileToggle defaultChecked={filters.shipByToday} name="shipByToday" id="shipByToday" />
                                </div>
                                <div className={pickListFilterStyles.filterContainerCheckboxes}>
                                    <div className={pickListFilterStyles.filterTitle}>Sales Channel</div>
                                    <div className={pickListFilterStyles.checkboxesContainer}>
                                        {(filtersData?.salesChannels?.items ?? []).map((x) => {
                                            const checked = filters.salesChannels.findIndex((y) => y === x.id) > -1;
                                            return (
                                                <MobileCheckbox id={x.id} defaultChecked={checked} label={x.name} name={"salesChannel"} />
                                            );
                                        })}
                                    </div>
                                </div>
                                <div className={pickListFilterStyles.filterContainerInput}>
                                    <div className={pickListFilterStyles.filterTitle}>
                                        Sale Ids
                                        <button
                                            style={{
                                                border: "none",
                                                backgroundColor: "transparent",
                                                fontSize: 18,
                                                cursor: "pointer",
                                                position: "absolute",
                                                right: 0,
                                                top: -3,
                                            }}
                                            onClick={async (e) => {
                                                e.preventDefault();
                                                await showConfirmModal(
                                                    "Enter a comma-separated list of sale ids to filter by.",
                                                    "Sale Ids",
                                                    { confirm: "Ok" },
                                                    true
                                                );
                                            }}
                                        >
                                            &#128712;
                                        </button>
                                    </div>
                                    <MobileInput defaultValue={filters.saleIds.join(", ")} name="saleIds" id="saleIds" />
                                </div>
                            </div>
                        </div>
                        <div>
                            <button type="submit" className={pickListFilterStyles.applyFiltersButton}>
                                Apply Filters
                            </button>
                        </div>
                    </form>
                </div>
            )}

            {createElement(confirmModal)}
        </>
    );
};

export default SelectList;
