export default class QueryString {
    static stringify(data: Record<string, any>, asCsv?: boolean) {
        if (!data) return "";
        const keys = Object.keys(data);
        const strings = [];
        for (let i = 0; i < keys.length; i++) {
            const key = keys[i];
            const obj = data[key];
            if (obj !== null && obj !== undefined) {
                let objs: string[];
                if (Array.isArray(obj)) {
                    objs = (obj as any[]).map((x) => x + "");
                } else {
                    objs = [obj + ""];
                }
                if (asCsv) {
                    if (objs.length > 0) {
                        strings.push(encodeURIComponent(key) + "=" + objs.map((x) => encodeURIComponent(x + "")).join(","));
                    }
                } else {
                    for (let j = 0; j < objs.length; j++) {
                        strings.push(encodeURIComponent(key) + "=" + encodeURIComponent(objs[j]));
                    }
                }
            }
        }
        if (strings.length === 0) return "";
        return `?${strings.join("&")}`;
    }

    private readonly _objects: { [key: string]: string[] };

    constructor(value: string) {
        this._objects = {};
        if (!value) return;
        if (value === "?" || value.slice(0, 1) !== "?") return;
        value = value.slice(1);
        const values = value.split("&");
        for (let i = 0; i < values.length; i++) {
            const values2 = values[i].split("=");
            if (values2.length >= 2) {
                const key = decodeURIComponent(values2[0]);
                const keyValue = decodeURIComponent(values2[1]);
                const array = this._objects[key];
                if (array === undefined) {
                    this._objects[key] = [keyValue];
                } else {
                    array.push(keyValue);
                }
            }
        }
    }

    getString = (key: string) => {
        return this.getStrings(key)[0] as string | undefined;
    };

    getStrings = (key: string) => {
        return this._objects[key] || [];
    };

    getCsvNumbers = (key: string) => {
        const values: number[] = [];
        const strs = this.getStrings(key);
        for (let i = 0; i < strs.length; i++) {
            const strs2 = strs[i].split(",");
            const vals2 = strs2.map((x) => parseFloat(x));
            for (let j = 0; j < vals2.length; j++) {
                values.push(vals2[j]);
            }
        }
        return values;
    };

    getNumber = (key: string) => {
        const value = this.getString(key);
        if (value === undefined) return undefined;
        return parseFloat(value);
    };

    getNumbers = (key: string) => {
        return this.getStrings(key).map((x) => parseFloat(x));
    };

    getKeys = () => Object.keys(this._objects);
}
