import { createElement, useState } from "react";
import { useQuery } from "@shane32/graphql";
import MobileLoading from "../../../../components/MobileLoading";
import useReload from "../../../../hooks/useReload";
import StationSetType from "../../../../../../enums/StationSetTypes";
import StationType from "../../../../../../enums/StationTypes";
import styles from "./ManageCarts.module.scss";
import { Modal } from "react-bootstrap";
import CartManager from "../CartManager/CartManager";
import PrimaryHeader from "../../../../components/PrimaryHeader";

const pickZonesQuery = `
query {
    pickZones {
      items {
        id
        name
        active
        pickable
        isCart
        station {
          id
          stationSet {
            id
            name
            type
            active
          }
          type
        }
        lastLocation {
          name
        }
      }
    }
  }
`;

interface IPickZoneQueryVariables {}

interface IPickZoneQueryResult {
    pickZones: {
        items: Array<{
            id: string;
            name: string;
            active: boolean;
            pickable: boolean;
            isCart: boolean;
            station: {
                id: string;
                stationSet: {
                    id: string;
                    name: string;
                    type: StationSetType;
                    active: boolean;
                };
                type: StationType;
            } | null;
            lastLocation: {
                name: string;
            } | null;
        }>;
    };
}

const ManageCarts = () => {
    const { reloadModal, showReloadModal } = useReload();
    const [selectedCart, setSelectedCart] = useState({
        pickZoneId: "",
        pickZoneName: "",
    });
    const {
        data: pickZoneData,
        loading: pickZoneLoading,
        error: pickZoneError,
    } = useQuery<IPickZoneQueryResult, IPickZoneQueryVariables>(pickZonesQuery, {
        fetchPolicy: "no-cache",
    });

    if (pickZoneError) {
        showReloadModal("Failed to Load Pick Zones", "An error occurred while loading the pick zones.");
    }

    const carts = (pickZoneData?.pickZones?.items ?? [])
        .filter((x) => x.isCart)
        .sort((a, b) =>
            a.name.localeCompare(b.name, undefined, {
                numeric: true,
            })
        );

    const loading = [pickZoneLoading].some((x) => x);

    return (
        <>
            {loading && <MobileLoading fullscreen />}

            <table className={styles.table}>
                <thead>
                    <tr>
                        <td>Cart Name</td>
                        {/* <td>Station</td> */}
                    </tr>
                </thead>
                <tbody>
                    {carts.map((pickZone, i) => {
                        // const stationSetName = pickZone.station?.stationSet?.name ?? "(No Station Set Name)";
                        // const stationType = pickZone.station?.type ?? "(No Station Type)";

                        return (
                            <tr key={i} onClick={() => setSelectedCart({ pickZoneId: pickZone.id, pickZoneName: pickZone.name })}>
                                <td>{pickZone.name}</td>
                                {/* <td>{`${stationSetName} (${stationType})`}</td> */}
                            </tr>
                        );
                    })}
                </tbody>
            </table>

            <Modal show={!!selectedCart.pickZoneId} false animation={false}>
                <PrimaryHeader
                    title="Manage Pick Zone"
                    includeBackButton
                    customBackButtonFunction={() => setSelectedCart({ pickZoneId: "", pickZoneName: "" })}
                    backButtonText="Close"
                    includeHomeButton
                />
                <CartManager pickZoneId={selectedCart.pickZoneId} />
            </Modal>

            {createElement(reloadModal)}
        </>
    );
};

export default ManageCarts;
