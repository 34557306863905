import React, { useContext, useState } from "react";
import { useHistory, useLocation } from "react-router";
import { Redirect } from "react-router-dom";
import ErrorDisplay from "../../../../components/misc/ErrorDisplay";
import ModelType from "../../../../enums/ModelType";
import { GraphQLError, useMutation } from "@shane32/graphql";
import useEffectOnce from "../../../../hooks/useEffectOnce";
import MobileButton from "../../components/buttons/MobileButton";
import MobileButtonCol from "../../components/buttons/MobileButtonCol";
import MobileButtonContainer from "../../components/buttons/MobileButtonContainer";
import MobileButtonRow from "../../components/buttons/MobileButtonRow";
import ActiveProductImages from "../../components/images/ActiveProductImages";
import MobileLoading from "../../components/MobileLoading";
import PrimaryHeader from "../../components/PrimaryHeader";
import SecondaryHeader from "../../components/SecondaryHeader";
import MobileTable from "../../components/tables/MobileTable";
import ScannerToneContext from "../../contexts/ScannerToneContext";
import SessionContext from "../../contexts/SessionContext";
import { ISearchBarcodesQueryResult, ISearchBarcodesQueryVariables, searchBarcodesQuery } from "../../graphs/queries/SearchBarcodesQuery";
import useScanner from "../../hooks/useScanner";
import IAisleEquipment from "../../models/AisleEquipment";
import { IDisplayPicksModel, IPickZoneLocation } from "./ScanBinController";
import SelectionType from "../../../../enums/SelectionType";
import { Modal } from "react-bootstrap";
import parseLetterRanges from "../../helpers/parseLetterRanges";

interface IProps {
    locationsOnCart: IPickZoneLocation[];
    isScanBinLoading: boolean;
    scanBinRefetch: () => void;
    displayPicks: IDisplayPicksModel[];
    scanBinError: GraphQLError | undefined;
    cartId: string;
    sortSku: string | null;
    urlSearchParams: URLSearchParams;
    activeSideFilters: string[];
    activeShelfFilters: string[];
}

const ScanBin = (props: IProps) => {
    const {
        cartId,
        displayPicks,
        isScanBinLoading,
        locationsOnCart,
        scanBinError,
        scanBinRefetch,
        sortSku,
        urlSearchParams,
        activeSideFilters,
        activeShelfFilters,
    } = props;
    const history = useHistory();
    const location = useLocation();
    const sessionContext = useContext(SessionContext);
    const [manualLoading, setManualLoading] = useState(false);
    const [runSearchBarcodes] = useMutation<ISearchBarcodesQueryResult, ISearchBarcodesQueryVariables>(searchBarcodesQuery);
    const aisleEquipments = urlSearchParams.getAll("aisleEquipment").map((aisleEquipment) => JSON.parse(aisleEquipment) as IAisleEquipment);
    const { playPositiveTone, playNegativeTone } = useContext(ScannerToneContext);
    const [isAisleFilterModalVisible, setIsAisleFilterModalVisible] = useState(false);
    const onScan = async (scannedLocationName: string) => {
        if (props.isScanBinLoading || manualLoading) return;
        try {
            setManualLoading(true);
            const res = await runSearchBarcodes({ variables: { search: scannedLocationName, userEntered: false } });
            const barcodesLocations = res.data.searchBarcodes.filter((x) => x.type === ModelType.Location);
            if (barcodesLocations.length < 1)
                return alert(`Found ${barcodesLocations.length} pick zones matching value ${scannedLocationName}.`);
            if (barcodesLocations.length > 1)
                return alert(
                    `Found ${barcodesLocations.length} pick zones matching value ${scannedLocationName}. There should only be one`
                );
            //verify that the scanned location id is in the list of items we need to pick
            const matchingIndex = displayPicks.findIndex((displayPick) => displayPick.location.id === barcodesLocations[0].id);
            if (matchingIndex < 0) return playNegativeTone();
            displayPicks.unshift(displayPicks.splice(matchingIndex, 1)[0]);
            playPositiveTone();
            urlSearchParams.set("sortLocationName", displayPicks[0].location.name);
            urlSearchParams.set("fromLocationSelectionType", SelectionType.Scanned);
            history.push(`/mobile/pick/scanbin/scansku/${displayPicks[0].location.id}?${urlSearchParams.toString()}`);
        } catch (error: any) {
            alert((error as GraphQLError)?.message ?? "Unknown error");
        } finally {
            setManualLoading(false);
        }
    };
    useScanner(onScan);

    useEffectOnce(() => scanBinRefetch());

    if (scanBinError) return <ErrorDisplay onClick={scanBinRefetch}>{scanBinError.message}</ErrorDisplay>;

    if (aisleEquipments.length < 1) return <Redirect to={`/mobile/pick/selectlist`} />;

    const tableRowClickHandler = (displayPick: IDisplayPicksModel) => {
        urlSearchParams.set("sortSku", encodeURIComponent(displayPick.product.sku));
        urlSearchParams.set("sortLocationName", displayPick.location.name);
        history.replace(`/mobile/pick/scanbin/scanbin?${urlSearchParams.toString()}`);
    };

    const nextButtonHandler = () => {
        if (displayPicks.length < 1) return alert("No display picks available");
        urlSearchParams.set("fromLocationSelectionType", SelectionType.Selected);
        history.push(`/mobile/pick/scanbin/scansku/${displayPicks[0].location.id}/` + sortSku + `?${urlSearchParams.toString()}`);
    };

    const cartFullHandler = () => history.push(`/mobile/pick/${cartId}/returncart`);

    const onSubmitAisleFilterHandler = (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();

        urlSearchParams.delete("activeSideFilters");
        urlSearchParams.delete("activeShelfFilters");

        const sidesInput = e.currentTarget.sides as HTMLInputElement;
        const shelfsInput = e.currentTarget.shelfs as HTMLInputElement;

        const sidesInputValue = sidesInput.value.trim().toUpperCase();
        const shelfsInputValue = shelfsInput.value.trim().toUpperCase();

        const sides = parseLetterRanges(sidesInputValue);
        const shelfs = parseLetterRanges(shelfsInputValue);

        sides.forEach((side) => urlSearchParams.append("activeSideFilters", side));
        shelfs.forEach((shelf) => urlSearchParams.append("activeShelfFilters", shelf));
        history.push(`${location.pathname}?${urlSearchParams.toString()}`);
        setIsAisleFilterModalVisible(false);
    };

    const onClickResetFilterHandler = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
        e.preventDefault();

        setIsAisleFilterModalVisible(false);

        const newSearchParams = new URLSearchParams(location.search);
        newSearchParams.delete("activeSideFilters");
        newSearchParams.delete("activeShelfFilters");

        history.push({
            pathname: location.pathname,
            search: newSearchParams.toString(),
        });
    };

    let aisles = Array.from(new Set(displayPicks.map((x) => x.location.aisle))).sort();

    //if the selected cart has more than one location (aka multiple bins on the cart),
    //disable the "auto stow" function, and require the user to scan where to place the scanned sku
    const doesCartHaveMultipleLocations = locationsOnCart.length > 1;

    //used for showing how many products are in the users cart that match the item that is first in the displayPicks list
    const numberOfMatchingProductsByLocation =
        locationsOnCart.length > 0
            ? locationsOnCart
                  .map((x) => x.products)
                  .reduce((acc, curVal) => acc.concat(curVal), [])
                  .filter((x) => x.product.sku === (displayPicks.length > 0 ? displayPicks[0].product.sku : ""))
                  .reduce((acc, curVal) => acc + curVal.stockOnHand, 0)
            : 0;

    let binName = doesCartHaveMultipleLocations ? "Any" : locationsOnCart.find((x) => x)?.name ?? "N/A";

    return (
        <>
            {isScanBinLoading || manualLoading ? <MobileLoading fullscreen /> : <></>}

            <PrimaryHeader
                title={`Picking ${aisles}`}
                includeBackButton
                includeHomeButton
                backButtonText="< Select List"
                customBackButtonPath="/mobile/pick/selectlist"
                customBackButtonFunction={() => history.push(`/mobile/pick/selectlist`)}
            />

            <SecondaryHeader
                title="║▌Scan Bin"
                secondaryTitle={displayPicks.length > 0 ? displayPicks[0].location.name : "Nothing"}
                nextButtonHandler={nextButtonHandler}
            />

            {displayPicks.length < 1 ? (
                <></>
            ) : (
                <>
                    <MobileTable isActiveTable>
                        <thead>
                            <tr>
                                <td>Req</td>
                                {/*Represents the amount available in each bin that you are able to pick for every order. Other name possibilities "Number Needed", "Required" */}
                                <td>Hand</td>
                                <td>Bin</td>
                            </tr>
                        </thead>
                        <tbody>
                            <tr
                                key={JSON.stringify({ sku: displayPicks[0].product.sku, bin: displayPicks[0].location.id }) + "Active-1"}
                                id={JSON.stringify({ sku: displayPicks[0].product.sku, bin: displayPicks[0].location.id })}
                            >
                                <td>{displayPicks[0].pickSaleModels.reduce((a, b) => a + b.quantityToPick, 0)}</td>
                                <td>-</td>
                                <td>{numberOfMatchingProductsByLocation}</td>
                            </tr>
                            <tr id={JSON.stringify({ sku: displayPicks[0].product.sku, bin: displayPicks[0].location.id }) + "Active-2"}>
                                <td style={{ color: "#CC0A3B" }}>{displayPicks[0].location.name}</td>
                                <td>{displayPicks[0].product.sku}</td>
                                <td>{binName}</td>
                            </tr>
                        </tbody>
                    </MobileTable>

                    <div style={{ padding: "10px 10px 0 10px" }}>
                        <ActiveProductImages sku={displayPicks[0].product.sku} />
                    </div>

                    {/* category */}
                    <div style={{ fontSize: 12, margin: "5px 10px 0 10px", display: "flex", justifyContent: "space-between" }}>
                        <div>
                            Category: <b>{displayPicks[0].product.description}</b>
                        </div>
                        {activeSideFilters.length < 1 ? (
                            <></>
                        ) : (
                            <div>
                                Side Filters: <b>{activeSideFilters.join(", ")}</b>
                            </div>
                        )}
                    </div>
                </>
            )}

            <MobileButtonContainer>
                <MobileButtonRow>
                    <MobileButtonCol>
                        <MobileButton disabled>Stow (0) to Bin {binName}</MobileButton>
                    </MobileButtonCol>
                    <MobileButtonCol>
                        <MobileButton disabled>-</MobileButton>
                        <MobileButton disabled>+</MobileButton>
                        <MobileButton disabled>Max</MobileButton>
                    </MobileButtonCol>
                </MobileButtonRow>
                <MobileButtonRow>
                    <MobileButtonCol>
                        <MobileButton
                            disabled={doesCartHaveMultipleLocations}
                            onClick={() => sessionContext.updateAutoStow(!sessionContext.autoStow)}
                        >
                            Auto Stow ({sessionContext.autoStow ? "On" : "Off"})
                        </MobileButton>
                        <MobileButton onClick={() => setIsAisleFilterModalVisible(true)}>Aisle Filter</MobileButton>
                        <MobileButton onClick={() => cartFullHandler()}>Cart Full</MobileButton>
                    </MobileButtonCol>
                </MobileButtonRow>
                <MobileButtonRow>
                    <MobileButtonCol>
                        <MobileButton disabled>Add or Print Barcode</MobileButton>
                    </MobileButtonCol>
                </MobileButtonRow>
            </MobileButtonContainer>

            {displayPicks.length < 2 ? (
                <></>
            ) : (
                <MobileTable>
                    <tbody>
                        {displayPicks.map((b, i) => {
                            if (i === 0) return <React.Fragment key={i.toString() + "a"}></React.Fragment>;

                            return (
                                <tr key={i.toString() + "a"} onClick={() => tableRowClickHandler(b)}>
                                    <td>{b.location.name}</td>
                                    <td>{b.product.sku}</td>
                                    <td>{binName}</td>
                                </tr>
                            );
                        })}
                    </tbody>
                </MobileTable>
            )}

            <Modal show={isAisleFilterModalVisible} onHide={() => setIsAisleFilterModalVisible(false)}>
                <form onSubmit={(e) => onSubmitAisleFilterHandler(e)}>
                    <Modal.Header closeButton>
                        <b style={{ fontSize: 18 }}>Aisle Filter</b>
                    </Modal.Header>
                    <Modal.Body style={{ display: "flex", flexDirection: "column", gap: 10 }}>
                        <div>
                            <div style={{ fontSize: 12, marginBottom: 5 }}>
                                Enter in a comma separated list of sides to filter by (Ranges work. Ex: A-Z)
                            </div>
                            <input className="form-control" name="sides" defaultValue={activeSideFilters.join(",")} />
                        </div>
                        <div>
                            <div style={{ fontSize: 12, marginBottom: 5 }}>
                                Enter in a comma separated list of shelfs to filter by (Ranges work. Ex: A-Z)
                            </div>
                            <input className="form-control" name="shelfs" defaultValue={activeShelfFilters.join(",")} />
                        </div>
                    </Modal.Body>
                    <Modal.Footer style={{ display: "flex", justifyContent: "space-between" }}>
                        <div>
                            <button type="button" className="btn btn-danger" onClick={onClickResetFilterHandler}>
                                Reset
                            </button>
                        </div>
                        <div style={{ display: "flex", gap: 10 }}>
                            <button className="btn btn-primary" type="submit">
                                Set Filter
                            </button>
                            <button type="button" className="btn btn-secondary" onClick={() => setIsAisleFilterModalVisible(false)}>
                                Close
                            </button>
                        </div>
                    </Modal.Footer>
                </form>
            </Modal>
        </>
    );
};

export default ScanBin;
