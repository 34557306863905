import { createElement, useRef, useState, useEffect } from "react";
import { useHistory, useLocation } from "react-router-dom";
import PrimaryHeader from "../../../../components/PrimaryHeader";
import SecondaryHeader from "../../../../components/SecondaryHeader";
import { GraphQLError, useMutation } from "@shane32/graphql";
import { ISearchBarcodesQueryResult } from "../../../../graphs/queries/SearchBarcodesQuery";
import { ISearchBarcodesQueryVariables } from "../../../../graphs/queries/SearchBarcodesQuery";
import { searchBarcodesQuery } from "../../../../graphs/queries/SearchBarcodesQuery";
import ModelType from "../../../../../../enums/ModelType";
import useConfirm from "../../../../hooks/useConfirm";
import MobileTable from "../../../../components/tables/MobileTable";
import {
    IZbdbInterchangeSearchQueryResult,
    IZbdbInterchangeSearchQueryVariables,
    zbdbInterchangeSearchQuery,
} from "../../../../graphs/queries/SearchZbDbQuery";
import MobileLoading from "../../../../components/MobileLoading";
import useEffectOnce from "../../../../../../hooks/useEffectOnce";
import useScanner from "../../../../hooks/useScanner";

interface IProduct {
    id: string;
    sku: string;
}

const productQuery = `
query ($productId: ID!) {
    product(id: $productId) {
      id
      sku
    }
  }
`;

interface IProductQueryVariables {
    productId: string;
}

interface IProductQueryResult {
    product: IProduct;
}

export interface IProductManagerHistory {
    previouslySearchedProducts: IProduct[];
    backPath?: string;
}

const ProductManager = () => {
    const history = useHistory<IProductManagerHistory>();
    const location = useLocation();
    const { confirmModal, showConfirmModal } = useConfirm();
    const [runSearchBarcodes] = useMutation<ISearchBarcodesQueryResult, ISearchBarcodesQueryVariables>(searchBarcodesQuery);
    const [runProductQuery] = useMutation<IProductQueryResult, IProductQueryVariables>(productQuery);
    const [runZbDbInterchangeSearch] = useMutation<IZbdbInterchangeSearchQueryResult, IZbdbInterchangeSearchQueryVariables>(
        zbdbInterchangeSearchQuery
    );
    const productSearchInputRef = useRef<HTMLInputElement>(null);
    const mounted = useRef(true);
    const [manualLoading, setManualLoading] = useState(false);
    const [products, setProducts] = useState<IProduct[]>([]);

    const loading = [manualLoading].some((x) => x);

    useEffect(() => {
        return () => {
            mounted.current = false;
        };
    }, [location.pathname]);

    const searchForProducts = async (searchValue: string, userEntered: boolean, vendorSpecific: boolean) => {
        if (loading) return;
        try {
            setManualLoading(true);
            const res = await runSearchBarcodes({
                variables: { search: searchValue, userEntered: userEntered, vendorSpecific: vendorSpecific },
            });
            const barcodes = res.data.searchBarcodes.filter((x) => x.type === ModelType.Product);
            var searchedProducts: IProduct[] = [];
            if (barcodes.length < 1) {
                const continue1 = await showConfirmModal(
                    `No products found in Hive matching value '${searchValue}'.\n
                Click 'Search ZBDB' to look for possible matches.`,
                    "No Products Found",
                    { confirm: "Search ZBDB", cancel: "Cancel" }
                );
                if (!continue1) {
                    setProducts([]); //wipe products when not continuing
                    return;
                }
                //search zbdb for interchanges
                const zbdbRes = (await runZbDbInterchangeSearch({ variables: { interchangePartNumber: searchValue } })).data.mobile
                    .searchZbDbByInterchange;
                if (zbdbRes.length < 1) {
                    setProducts([]); //wipe products on failure
                    throw new Error(`No products in Hive or ZBDB found matching value '${searchValue}'.`);
                }
                searchedProducts = zbdbRes.map((x) => ({ id: x.productId, sku: x.sku }));
            } else {
                //search for products based off of the productIds array
                for (let productId of barcodes.map((x) => x.id)) {
                    if (mounted.current) {
                        const productRes = (await runProductQuery({ variables: { productId: productId } })).data.product;
                        searchedProducts.push(productRes);
                    }
                }
            }
            setProducts(searchedProducts);
        } catch (e: any) {
            await showConfirmModal(
                (e as GraphQLError)?.message ?? (e as Error)?.message ?? "An unknown error occurred while searching database for products.",
                "Error",
                { confirm: "Ok" },
                true
            );
        } finally {
            setManualLoading(false);
        }
    };

    const onScan = async (scannerValue: string) => await searchForProducts(scannerValue, false, true);
    useScanner(onScan);

    const onClickSearchButtonHandler = async (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        const searchValue = productSearchInputRef.current?.value?.trim();
        if (!searchValue) {
            await showConfirmModal("Please enter a value to search for", "No Search Value", { confirm: "Ok" }, true);
            return;
        }
        await searchForProducts(searchValue, true, true);
    };

    useEffectOnce(() => {
        if (history.location.state?.previouslySearchedProducts) {
            setProducts(history.location.state.previouslySearchedProducts);
        }
    });

    return (
        <>
            {loading && <MobileLoading fullscreen />}

            <PrimaryHeader
                title="Product Manager"
                includeBackButton
                includeHomeButton
                backButtonText="< Inventory"
                customBackButtonPath="/mobile/admin/inventory"
            />

            <SecondaryHeader title="Search Products" nextButtonHandler={() => void 0} />

            <form
                onSubmit={async (e) => await onClickSearchButtonHandler(e)}
                style={{ padding: 10, backgroundColor: "#EDF0F4", display: "flex" }}
            >
                <input
                    ref={productSearchInputRef}
                    onFocus={(e) => e.currentTarget.select()}
                    style={{
                        padding: 10,
                        alignSelf: "stretch",
                        height: 40,
                        borderRadius: 4,
                        border: "1px solid #798394",
                        marginRight: 10,
                        width: "100%",
                    }}
                    placeholder="Search for part..."
                />
                <button
                    style={{ alignSelf: "stretch", height: 40, borderRadius: 4, border: "1px solid #798394", minWidth: 112 }}
                    type="submit"
                >
                    <b>Search</b>
                </button>
            </form>

            <MobileTable>
                <thead>
                    <tr>
                        <td>
                            <b>SKU</b>
                        </td>
                    </tr>
                </thead>
                <tbody>
                    {products.map((x) => {
                        return (
                            <tr
                                key={x.sku}
                                onClick={() =>
                                    history.push(`${location.pathname}/${x.id}`, {
                                        previouslySearchedProducts: products,
                                        backPath: location.pathname,
                                    })
                                }
                            >
                                <td>{x.sku}</td>
                            </tr>
                        );
                    })}
                </tbody>
            </MobileTable>

            {createElement(confirmModal)}

            {/* <Switch>
                <Route exact path={`/mobile/admin/inventory/productmanager/:productId`}>
                    <Product />
                </Route>
            </Switch> */}
        </>
    );
};

export default ProductManager;
