import { useContext, useState } from "react";
import { Redirect, useHistory, useLocation, useParams } from "react-router-dom";
import ErrorDisplay from "../../../../components/misc/ErrorDisplay";
import ModelType from "../../../../enums/ModelType";
import { GraphQLError, useMutation, useQuery } from "@shane32/graphql";
import GroupBy from "../../../../helpers/GroupBy";
import MobileLoading from "../../components/MobileLoading";
import PrimaryHeader from "../../components/PrimaryHeader";
import SecondaryHeader from "../../components/SecondaryHeader";
import MobileTable from "../../components/tables/MobileTable";
import ScannerToneContext from "../../contexts/ScannerToneContext";
import { ISearchBarcodesQueryResult, ISearchBarcodesQueryVariables, searchBarcodesQuery } from "../../graphs/queries/SearchBarcodesQuery";
import useScanner from "../../hooks/useScanner";
import SelectionType from "../../../../enums/SelectionType";

interface IProductQueryResult {
    product: {
        id: string;
        sku: string;
        locationProducts: Array<{
            stockOnHand: number;
            location: {
                id: string;
                name: string;
            };
        }>;
    };
}

const productQuery = `
query ($id: ID!) {
  product(id: $id) {
    id
    sku
    locationProducts {
      stockOnHand
      location {
        id
        name
      }
    }
  }
}
`;

interface ISearchParams {
    productId: string;
}

const StockTakeScanBin = () => {
    const params = useParams<ISearchParams>();
    const history = useHistory();
    const location = useLocation();
    const urlSearchParams = new URLSearchParams(location.search);
    const searchFromUrl = urlSearchParams.get("search");
    const productSelectionType: SelectionType = (urlSearchParams.get("productSelectionType") ?? SelectionType.None) as SelectionType;
    const locationSelectionType: SelectionType = (urlSearchParams.get("locationSelectionType") ?? SelectionType.None) as SelectionType;
    const [manualLoading, setManualLoading] = useState(false);
    const [runSearchBarcodes] = useMutation<ISearchBarcodesQueryResult, ISearchBarcodesQueryVariables>(searchBarcodesQuery);
    const { playPositiveTone, playNegativeTone } = useContext(ScannerToneContext);
    const onScan = async (scannerValue: string) => {
        if (loading || manualLoading || groupedLocationProducts.size < 1) return;
        try {
            setManualLoading(true);
            const res = await runSearchBarcodes({ variables: { search: scannerValue, userEntered: false } });
            const barcodeLocations = res.data.searchBarcodes.filter((x) => x.type === ModelType.Location);
            if (barcodeLocations.length < 1) return alert(`Found ${barcodeLocations.length} locations matching value '${scannerValue}'.`);
            if (barcodeLocations.length > 1)
                return alert(`Found ${barcodeLocations.length} locations matching value '${scannerValue}'. There should only be one`);
            var matchingLocation = Array.from(groupedLocationProducts.keys()).find((locationId) => locationId === barcodeLocations[0].id);
            if (matchingLocation) {
                playPositiveTone();
                let urlSP = new URLSearchParams();
                urlSP.set("locationSelectionType", SelectionType.Scanned);
                if (searchFromUrl) urlSP.set("search", searchFromUrl);
                let loc = `/mobile/stocktake/stocktake/${matchingLocation}`;
                if (urlSP.getAll.length > 0) loc += `?${urlSP.toString()}`;
                history.push(loc);
            } else {
                playNegativeTone();
            }
        } catch (error: any) {
            playNegativeTone();
            alert((error as GraphQLError)?.message ?? "Unknown error");
        } finally {
            setManualLoading(false);
        }
    };
    useScanner(onScan);

    const { loading, refetch, data, error } = useQuery<IProductQueryResult, { id: string }>(productQuery, {
        variables: { id: params.productId },
        fetchPolicy: "no-cache",
    });

    if (error) return <ErrorDisplay onClick={refetch}>{error.message}</ErrorDisplay>;

    const locationProducts = data?.product.locationProducts ?? [];

    //we group by location id because there could be products in the same location, but they have different
    //sale ids attached to them, making them look like they are in a different location
    const groupedLocationProducts = GroupBy(locationProducts, (x) => x.location.id);

    if (groupedLocationProducts.size === 1) {
        let urlSP = new URLSearchParams();
        urlSP.set("productId", params.productId);
        urlSP.set("searchedProductId", params.productId);
        urlSP.set("returnComponent", "StockTakeSearch");
        //if there is only one product, pass the product selection type to the next page using the "locationSelectionType" param
        urlSP.set("locationSelectionType", locationSelectionType);
        urlSP.set("productSelectionType", productSelectionType);
        if (searchFromUrl) urlSP.set("search", searchFromUrl);
        return <Redirect to={`/mobile/stocktake/stocktake/${locationProducts[0].location.id}?${urlSP.toString()}`} />;
    }

    let backButtonPath = "/mobile/stocktake/stocktakesearch";
    if (searchFromUrl) {
        let q = new URLSearchParams();
        q.set("search", searchFromUrl);
        backButtonPath += `?${q.toString()}`;
    }

    return (
        <>
            {loading || manualLoading ? <MobileLoading fullscreen /> : <></>}

            <PrimaryHeader
                title={`${data?.product.sku ?? ""}`}
                includeBackButton
                includeHomeButton
                backButtonText="< Search"
                customBackButtonPath={backButtonPath}
            />

            <SecondaryHeader title={"║▌Scan Bin"} nextButtonHandler={() => void 0} />

            {!data ? (
                <>No results!</>
            ) : groupedLocationProducts.size < 1 ? (
                <>SKU '{data!.product.sku}' has no locations</>
            ) : (
                <MobileTable>
                    <thead>
                        <tr>
                            <td>Bin</td>
                            <td>Qty</td>
                        </tr>
                    </thead>
                    <tbody>
                        {Array.from(groupedLocationProducts, ([key, values]) => {
                            const locationId = key;
                            const locationName = values[0].location.name;
                            const locationStockOnHand = values.reduce((accumulator, location) => accumulator + location.stockOnHand, 0);

                            return (
                                <tr
                                    onClick={() => {
                                        let urlSP = new URLSearchParams();
                                        urlSP.set("productId", params.productId);
                                        urlSP.set("searchedProductId", params.productId);
                                        urlSP.set("returnComponent", "StockTakeScanBin");
                                        urlSP.set("locationSelectionType", SelectionType.Selected);
                                        urlSP.set("productSelectionType", productSelectionType);
                                        if (searchFromUrl) urlSP.set("search", searchFromUrl);
                                        history.push(`/mobile/stocktake/stocktake/${locationId}?${urlSP.toString()}`);
                                    }}
                                    key={locationId}
                                >
                                    <td>{locationName}</td>
                                    <td>{locationStockOnHand}</td>
                                </tr>
                            );
                        })}
                    </tbody>
                </MobileTable>
            )}
        </>
    );
};

export default StockTakeScanBin;
