import { Fragment, useState } from "react";
import AuthorizedAnchor from "../AuthorizedAnchor";
import styles from "./Attachments.module.scss";
import { Link } from "react-router-dom";
import { Dropdown } from "react-bootstrap";
import { useMutation } from "@shane32/graphql";

const printLabelMutation = `
mutation ($attachmentId: ID!, $printerId: ID!) {
    api {
      print {
        attachment(attachmentId: $attachmentId) {
          print(printerId: $printerId)
        }
      }
    }
  }
`;

export interface IAttachment {
    id: string;
    dateUploaded: Date;
    url: string;
    data: string;
    isLabel: boolean;
    trackingNumber: string | null;
    labelProvider: {
        name: string;
    } | null;
    labelProviderShipmentId: string | null;
    shippingServiceId: string | null;
    filename: string;
}

export interface IAttachmentWithSaleId extends IAttachment {
    saleId?: string;
}

interface IAttachmentGroup {
    title: string;
    attachments: IAttachmentWithSaleId[];
}

interface IPrinter {
    id: string;
    description: string;
    type: string;
    active: boolean;
}

interface IPrintLabelResult {
    api: {
        print: {
            attachment: {
                print: boolean;
            };
        };
    };
}

interface IPrintLabelVariables {
    attachmentId: string;
    printerId: string;
}

interface IProps {
    attachments: Array<IAttachmentGroup>;
    deleteAttachmentAsync: (attachmentId: string) => Promise<void>;
    labelPrinters?: IPrinter[];
}

const Attachments = (props: IProps) => {
    const [isPrinting, setIsPrinting] = useState(false);
    const [runLabelPrint] = useMutation<IPrintLabelResult, IPrintLabelVariables>(printLabelMutation);

    const onPrintLabel = (modified: IPrinter, attachmentId: string) => {
        if (isPrinting) {
            return;
        }
        setIsPrinting(true);
        runLabelPrint({
            variables: {
                attachmentId: attachmentId,
                printerId: modified.id,
            },
        })
            .then(
                (ret) => {
                    alert("Print Success!");
                },
                (err) => {
                    console.error(`Error trying to print attachment`, err);
                    alert(err.message);
                }
            )
            .finally(() => {
                setIsPrinting(false);
            });
    };

    return (
        <div style={{ display: "flex", flexDirection: "column", gap: 20 }}>
            {props.attachments.map((attachmentGroup, a) => {
                return !attachmentGroup.attachments.length ? (
                    <Fragment key={a}></Fragment>
                ) : (
                    <div key={a}>
                        <h5>{attachmentGroup.title}</h5>
                        <div className={styles.rowsContainer}>
                            {attachmentGroup.attachments.map((attachment, b) => {
                                return (
                                    <div key={attachment.id} className={styles.attachmentRow}>
                                        <AuthorizedAnchor href={attachment.url} target="_blank" rel="noreferrer">
                                            {attachment.filename}
                                        </AuthorizedAnchor>
                                        {attachment.saleId ? (
                                            <Link to={`/sales/${attachment.saleId}/attachments`}>SO-{attachment.saleId}</Link>
                                        ) : null}
                                        {attachment.labelProvider ? <div>{attachment.labelProvider.name}</div> : null}
                                        {attachment.labelProviderShipmentId ? <div>{attachment.labelProviderShipmentId}</div> : null}
                                        <div className={styles.buttonsContainer}>
                                            {attachment.isLabel && props.labelPrinters ? (
                                                <Dropdown className="mb-3">
                                                    <Dropdown.Toggle disabled={isPrinting} variant="primary">
                                                        Print
                                                    </Dropdown.Toggle>
                                                    <Dropdown.Menu variant="secondary">
                                                        {props.labelPrinters.map((printer) => (
                                                            <Dropdown.Item
                                                                onClick={() => onPrintLabel(printer, attachment.id)}
                                                                key={printer.id}
                                                                defaultValue={printer.id}
                                                            >
                                                                {printer.description}
                                                            </Dropdown.Item>
                                                        ))}
                                                    </Dropdown.Menu>
                                                </Dropdown>
                                            ) : null}
                                            {!attachment.saleId ? (
                                                <button
                                                    onClick={async () => await props.deleteAttachmentAsync(attachment.id)}
                                                    className="btn btn-danger"
                                                >
                                                    Delete
                                                </button>
                                            ) : null}
                                        </div>
                                    </div>
                                );
                            })}
                        </div>
                    </div>
                );
            })}
        </div>
    );
};

export default Attachments;
