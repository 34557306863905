import { Redirect, useHistory, useParams } from "react-router-dom";
import ErrorDisplay from "../../../../components/misc/ErrorDisplay";
import PurchaseInvoiceStatus from "../../../../enums/PurchaseInvoiceStatus";
import { useQuery } from "@shane32/graphql";
import MobileLoading from "../../components/MobileLoading";
import PrimaryHeader from "../../components/PrimaryHeader";
import SecondaryHeader from "../../components/SecondaryHeader";
import MobileTable from "../../components/tables/MobileTable";

const purchaseOrderQuery = `
query ($id: ID!) {
  purchaseOrder(id: $id) {
    id
    supplier {
      name
    }
    invoices {
      id
      status
      invoiceNumber
      invoiceDate
      trackingNumbers {
        trackingNumber
      }
      lineItems {
        expectedQuantity
        actualQuantity
        purchaseOrderLineItem {
          productId
        }
      }
    }
  }
}
`;

interface IPurchaseOrderQueryResult {
    purchaseOrder: {
        id: string;
        supplier: {
            name: string;
        };
        invoices: Array<{
            id: string;
            status: PurchaseInvoiceStatus;
            invoiceNumber: string;
            invoiceDate: string;
            trackingNumbers: Array<{
                trackingNumber: string;
            }>;
            lineItems: Array<{
                expectedQuantity: number;
                actualQuantity: number;
                purchaseOrderLineItem: {
                    productId: string | null;
                };
            }>;
        }>;
    };
}

interface IPurchaseOrderQueryVariables {
    id: string;
}

export interface ISelectPurchaseInvoiceHistoryState {
    purchaseInvoiceIdsMatchingPreviouslyScannedProduct: Array<string>;
    previouslyScannedProductId: string;
}

interface IRouteParams {
    purchaseOrderId: string;
}

const SelectInvoice = () => {
    const history = useHistory<ISelectPurchaseInvoiceHistoryState>();
    const params = useParams<IRouteParams>();
    const urlSearchParams = new URLSearchParams(history.location.search);
    const { loading, data, refetch, error } = useQuery<IPurchaseOrderQueryResult, IPurchaseOrderQueryVariables>(purchaseOrderQuery, {
        fetchPolicy: "no-cache",
        variables: {
            id: params.purchaseOrderId,
        },
    });

    if (error || (!loading && !data))
        return <ErrorDisplay onClick={refetch}>{error?.message ?? "Fetching data for purchase orders failed"}</ErrorDisplay>;

    if (!params.purchaseOrderId) return <Redirect to={`/mobile/intake/selectpurchaseorder`} />;

    const invoices = (data?.purchaseOrder?.invoices ?? [])
        .filter((x) => x.status === PurchaseInvoiceStatus.Authorized)
        .filter((x) => history.location.state?.purchaseInvoiceIdsMatchingPreviouslyScannedProduct?.includes(x.id) ?? true);
    const primaryHeaderTitle = `ZPO-${data?.purchaseOrder?.id ?? ""} ${data?.purchaseOrder.supplier.name ?? ""}`;

    return (
        <>
            {loading ? <MobileLoading fullscreen /> : <></>}

            <PrimaryHeader
                title={primaryHeaderTitle}
                includeBackButton
                backButtonText="< Purchase Order"
                customBackButtonPath={`/mobile/intake/selectpurchaseorder?${urlSearchParams.toString()}`}
                includeHomeButton
            />

            <SecondaryHeader title="Select Invoice" nextButtonHandler={() => void 0} />

            <MobileTable ignoreSpacing>
                <thead>
                    <tr>
                        <td>Invoice</td>
                        <td>Tracking</td>
                        {!history.location.state?.previouslyScannedProductId ? <></> : <td>Remaining</td>}
                        <td>Invoice Date</td>
                    </tr>
                </thead>
                <tbody>
                    {invoices.map((invoice, a) => {
                        return (
                            <tr
                                key={invoice.id}
                                id={invoice.id}
                                onClick={() => {
                                    let url = `/mobile/intake/${params.purchaseOrderId}/${
                                        invoice.id
                                    }/scanbarcode?${urlSearchParams.toString()}`;
                                    if (history.location.state?.previouslyScannedProductId) {
                                        const urlSP = new URLSearchParams([["partId", history.location.state?.previouslyScannedProductId]]);
                                        url += `${urlSP.toString()}`;
                                    }
                                    history.push(url);
                                }}
                            >
                                <td>{invoice.invoiceNumber}</td>
                                <td>{invoice.trackingNumbers.map((t) => t.trackingNumber).join(", ")}</td>
                                {!history.location.state?.previouslyScannedProductId ? (
                                    <></>
                                ) : (
                                    <td>
                                        {invoice.lineItems
                                            .filter(
                                                (lineItem) =>
                                                    lineItem.purchaseOrderLineItem.productId ===
                                                    history.location.state?.previouslyScannedProductId
                                            )
                                            .map((lineItem) => lineItem.expectedQuantity - lineItem.actualQuantity)
                                            .reduce((a, b) => a + b, 0)}
                                    </td>
                                )}
                                <td>{new Date(invoice.invoiceDate).toLocaleDateString()}</td>
                            </tr>
                        );
                    })}
                </tbody>
            </MobileTable>
        </>
    );
};

export default SelectInvoice;
