import { createElement, Fragment, useState } from "react";
import { Modal } from "react-bootstrap";
import { Route, useHistory, useLocation } from "react-router-dom";
import PrimaryHeader from "../../components/PrimaryHeader";
import MobileTable from "../../components/tables/MobileTable";
import { IAdminRoute, TAdminComponent } from "./AdminController";

interface IProps {
    adminRoute: IAdminRoute;
    components: TAdminComponent[];
}

export interface ICreateElementProps {
    absolutePath: string;
}

const AdminGenericComponent = (props: IProps) => {
    const history = useHistory();
    const location = useLocation();
    const [modalManager, setModalManager] = useState<Record<string, boolean>>({});
    const openModal = (e: React.MouseEvent<HTMLTableRowElement, MouseEvent>) => setModalManager({ [e.currentTarget.id]: true });
    const closeModal = (key: string) => setModalManager({ [key]: false });

    return (
        <>
            {location.pathname !== `/mobile/admin/${props.adminRoute.pathName}` ? (
                <></>
            ) : (
                <>
                    <PrimaryHeader
                        title={props.adminRoute.displayName}
                        includeBackButton
                        includeHomeButton
                        backButtonText="< Admin"
                        customBackButtonPath="/mobile/admin"
                    />

                    <MobileTable>
                        <thead>
                            <tr>
                                <td>Function</td>
                            </tr>
                        </thead>
                        <tbody>
                            {props.components.map((c, i) => {
                                return (
                                    <tr
                                        key={i}
                                        id={i.toString()}
                                        onClick={c.openInModal ? openModal : () => history.push(`${props.adminRoute.pathName}/${c.path}`)}
                                    >
                                        <td>{c.displayName}</td>
                                    </tr>
                                );
                            })}
                        </tbody>
                    </MobileTable>
                </>
            )}

            {props.components.map((c, i) => {
                return (
                    <Modal show={modalManager[i]} onHide={() => closeModal(i.toString())} key={c.component + i.toString()}>
                        <Modal.Header style={{ height: 15 }} closeButton />
                        {createElement(() => c.component)}
                    </Modal>
                );
            })}

            {props.components.map((c, a) => {
                if (c.openInModal) return <></>;
                const p = `/mobile/admin/${props.adminRoute.pathName}/${c.path}`;
                return (
                    <Fragment key={"a" + a}>
                        <Route exact={c.exact} path={p} key={"a" + a}>
                            {createElement(() => c.component)}
                        </Route>
                        {(c.childRoutes ?? []).map((cr, b) => {
                            if (cr.openInModal) return <></>;
                            return (
                                <Fragment key={"b" + b}>
                                    <Route exact={c.exact} path={`${p}/${cr.path}`} key={"b" + b}>
                                        {createElement(() => cr.component)}
                                    </Route>
                                </Fragment>
                            );
                        })}
                    </Fragment>
                );
            })}
        </>
    );
};

export default AdminGenericComponent;
