import { GraphQLError, useMutation, useQuery } from "@shane32/graphql";
import { createElement, useContext, useState } from "react";
import { Redirect, useHistory, useLocation, useParams } from "react-router-dom";
import ModelType from "../../../../enums/ModelType";
import MobileButton from "../../components/buttons/MobileButton";
import MobileButtonCol from "../../components/buttons/MobileButtonCol";
import MobileButtonContainer from "../../components/buttons/MobileButtonContainer";
import MobileButtonRow from "../../components/buttons/MobileButtonRow";
import ActiveProductImages from "../../components/images/ActiveProductImages";
import MobileLoading from "../../components/MobileLoading";
import PrimaryHeader from "../../components/PrimaryHeader";
import SecondaryHeader from "../../components/SecondaryHeader";
import MobileTable from "../../components/tables/MobileTable";
import MobileTableTitle from "../../components/tables/MobileTableTitle";
import ScannerToneContext from "../../contexts/ScannerToneContext";
import {
    formatMultipleMatchesString,
    ISearchBarcodesQueryResult,
    ISearchBarcodesQueryVariables,
    searchBarcodesQuery,
} from "../../graphs/queries/SearchBarcodesQuery";
import useConfirm from "../../hooks/useConfirm";
import useScanner from "../../hooks/useScanner";
import { IStationQueryResult, IStationQueryVariables, stationQuery } from "../../models/graphql/station/Station";

interface IParams {
    stationId?: string;
}

const ScanBin = () => {
    const history = useHistory();
    const location = useLocation();
    const params = useParams<IParams>();
    const [manualLoading, setManualLoading] = useState(false);
    const { confirmModal, showConfirmModal } = useConfirm();
    const [runSearchBarcodes] = useMutation<ISearchBarcodesQueryResult, ISearchBarcodesQueryVariables>(searchBarcodesQuery);
    const urlSearchParams = new URLSearchParams(location.search);
    const activeLocationId = urlSearchParams.get("activeLocationId");
    const { playPositiveTone, playNegativeTone } = useContext(ScannerToneContext);

    const {
        loading: stationLoading,
        data: stationData,
        error: stationError,
    } = useQuery<IStationQueryResult, IStationQueryVariables>(stationQuery, {
        fetchPolicy: "no-cache",
        variables: !params.stationId ? undefined : { stationId: params.stationId },
        skip: !params.stationId,
    });

    const onScan = async (scannerValue: string) => {
        try {
            setManualLoading(true);
            const res = await runSearchBarcodes({ variables: { search: scannerValue, userEntered: false } });
            const barcodeLocations = res.data.searchBarcodes.filter((x) => x.type === ModelType.Location);
            if (barcodeLocations.length > 0) {
                if (barcodeLocations.length === 1) {
                    let scannedLocation = allLocations.find((x) => x.id === barcodeLocations[0].id);
                    if (scannedLocation) {
                        if (scannedLocation.products.reduce((acc, v) => acc + v.stockOnHand, 0) < 1) {
                            throw new Error(
                                `Location '${scannedLocation.name}' has no products. Please scan a location at the station with products to pack.`
                            );
                        }
                        playPositiveTone();
                        history.push(`/mobile/pack/${params.stationId}/${scannedLocation.id}`);
                    } else {
                        playNegativeTone();
                    }
                } else {
                    await showConfirmModal(
                        formatMultipleMatchesString(res.data.searchBarcodes, scannerValue),
                        "Multiple Matches In Database",
                        { confirm: "Confirm" },
                        true
                    );
                }
            } else {
                playNegativeTone();
            }
        } catch (error: any) {
            playNegativeTone();
            alert((error as GraphQLError)?.message ?? error ?? "Unknown error");
        } finally {
            setManualLoading(false);
        }
    };
    useScanner(onScan);

    if (stationError) {
        alert(stationError.message);
        return <Redirect to="/mobile/pack/scanstation" />;
    }

    //sort locations by name, then concat any stash locations, then set selected location at the start of the array
    let cartLocations = (stationData?.station.carts ?? []).filter((pickZone) => pickZone.isCart).flatMap((cart) => cart.locations);
    cartLocations.sort((a, b) => a.name.localeCompare(b.name));
    let stashLocations = [...(stationData?.station.stationSet.stashPickZone.locations ?? [])];
    stashLocations.sort((a, b) => a.name.localeCompare(b.name));
    const allLocations = cartLocations.concat(stashLocations);
    //filter out all products that are in the active location that do not have a sale id, as these products are not able to be packed
    allLocations.forEach((location) => {
        location.products = location.products.filter((product) => product.saleId);
    });
    let remainingLocations = cartLocations.concat(stashLocations).filter((l) => l.products.reduce((acc, v) => acc + v.stockOnHand, 0) > 0);
    const activeBinIndex = remainingLocations.findIndex((location) => location.id === activeLocationId);
    if (activeBinIndex > -1) {
        const activeBin = remainingLocations.splice(activeBinIndex, 1)[0];
        remainingLocations.unshift(activeBin);
    }
    const firstLocation = remainingLocations.shift();

    const onClickHandler = (locationId?: string) => {
        if (!locationId) return;
        history.push(`/mobile/pack/${params.stationId}/${locationId}`);
    };

    return (
        <>
            {manualLoading || stationLoading ? <MobileLoading fullscreen /> : <></>}

            <PrimaryHeader
                title={`${stationData?.station.stationSet.name ?? ""}`}
                includeBackButton
                includeHomeButton
                backButtonText="< Stations"
                customBackButtonPath="/mobile/pack/scanstation"
            />

            <SecondaryHeader
                title={"Select Bin"}
                secondaryTitle={`${firstLocation?.name ?? ""}`}
                nextButtonHandler={() => onClickHandler(firstLocation?.id)}
            />

            <MobileTable isActiveTable>
                <thead>
                    <tr>
                        <td>Bin</td>
                        <td>Hand</td>
                        <td>Bin</td>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td style={{ color: "black" }}>
                            {!firstLocation ? "-" : firstLocation.products.reduce((acc, v) => acc + v.stockOnHand, 0)}
                        </td>
                        <td style={{ color: "black" }}>{0}</td>
                        <td style={{ color: "black" }}>{0}</td>
                    </tr>
                    <tr>
                        <td style={{ color: "black" }}>{!firstLocation ? "-" : firstLocation.name}</td>
                        <td style={{ color: "black" }}>{`-`}</td>
                        <td style={{ color: "black" }}>{`-`}</td>
                    </tr>
                </tbody>
            </MobileTable>

            <div style={{ padding: "10px 10px 0 10px" }}>
                <ActiveProductImages sku={null} />
            </div>

            {/* category */}
            <div style={{ padding: "0 10px", marginTop: "5px", display: "flex", justifyContent: "space-between" }}>
                <div style={{ fontSize: 12 }}>
                    Category: <b>{"-"}</b>
                </div>
            </div>

            <MobileButtonContainer>
                <MobileButtonRow>
                    <MobileButtonCol>
                        <MobileButton disabled>{`Stow (0) To Package`}</MobileButton>
                    </MobileButtonCol>
                    <MobileButtonCol>
                        <MobileButton disabled>-</MobileButton>
                        <MobileButton disabled>+</MobileButton>
                        <MobileButton disabled>Max</MobileButton>
                    </MobileButtonCol>
                </MobileButtonRow>
                <MobileButtonRow>
                    <MobileButtonCol>
                        <MobileButton disabled>Auto Pack (Off)</MobileButton>
                    </MobileButtonCol>
                    <MobileButtonCol>
                        <MobileButton disabled>Auto Print (On)</MobileButton>
                    </MobileButtonCol>
                    <MobileButtonCol>
                        <MobileButton disabled>Box Full</MobileButton>
                    </MobileButtonCol>
                </MobileButtonRow>
                <MobileButtonRow>
                    <MobileButton disabled>Bulk Print Labels</MobileButton>
                </MobileButtonRow>
            </MobileButtonContainer>

            <MobileTableTitle>Other Locations</MobileTableTitle>
            <MobileTable>
                <thead>
                    <tr>
                        <td>Bin</td>
                        <td>Bin</td>
                    </tr>
                </thead>
                <tbody>
                    {remainingLocations.map((location) => {
                        return (
                            <tr key={location.id} onClick={() => onClickHandler(location.id)}>
                                <td>{location.name}</td>
                                <td>{location.products.reduce((acc, v) => acc + v.stockOnHand, 0)}</td>
                            </tr>
                        );
                    })}
                </tbody>
            </MobileTable>

            {createElement(confirmModal)}
        </>
    );
};

export default ScanBin;
