import { GraphQLClient, GraphQLContext } from "@shane32/graphql";
import * as dayjs from "dayjs";
import * as utc from "dayjs/plugin/utc";
import "react-app-polyfill/ie11"; //must be first line in index.tsx
import { createRoot } from "react-dom/client";
import { BrowserRouter, Route, Switch } from "react-router-dom";
import App from "./App";
import { Authentication, AuthContainer, AuthCallback, AuthLogoutCallback } from "@zboxglobal/zboxauth";
import { MessageController } from "./contexts/MessageContext";
import { RulerController } from "./contexts/RulerContext";
import {
    ApiBaseUrl,
    AuthBaseUrl,
    GraphQlBaseUrl,
    InitializeAuthListener,
    WebSocketApiBaseUrl,
    ZbDbApiUrl,
    SalesDbApiUrl,
} from "./globalVariables";
import "./index.css";
import { GlobalController } from "./contexts/GlobalContext";
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-alpine.css";

//polyfills
if (!Array.prototype.forEach) Array.prototype.forEach = Array.prototype.map; // eslint-disable-line no-extend-native

//other
dayjs.extend(utc.default);

const auth = new Authentication({
    baseUrl: AuthBaseUrl + "api/jwtauth",
    adminUrl: "",
    clientId: "7a36a805-ffa0-41f0-9142-b0f0d12b5b0e",
    callbackUrl: window.location.protocol + "//" + window.location.host + "/auth_callback",
    logoutCallbackUrl: window.location.protocol + "//" + window.location.host + "/auth_logout_callback",
});
const autoLoginPromise = auth.TryAutoLogin();

const client = new GraphQLClient({
    url: ApiBaseUrl + GraphQlBaseUrl,
    webSocketUrl: WebSocketApiBaseUrl + GraphQlBaseUrl,
    transformRequest: (config) => {
        return auth.UpdateToken().then(() => {
            const token = auth.GetAccessToken(); //null if not logged in
            if (token) {
                const config2 = { ...config };
                if (!config2.headers) config2.headers = {};
                (config2.headers as Record<string, string>)["Authorization"] = "Bearer " + token;
                return config2;
            } else {
                return config;
            }
        });
    },
    generatePayload: () => {
        return auth.UpdateToken().then(() => {
            const token = auth.GetAccessToken(); //null if not logged in
            if (token) {
                return {
                    // eslint-disable-next-line @typescript-eslint/naming-convention
                    Authorization: "Bearer " + token,
                };
            } else {
                return {};
            }
        });
    },
});

export const ZbDbGraphQLClient = new GraphQLClient({
    url: ZbDbApiUrl,
    transformRequest: (config) => {
        return auth.UpdateToken().then(() => {
            const token = auth.GetAccessToken(); //null if not logged in
            if (token) {
                const config2 = { ...config };
                if (!config2.headers) config2.headers = {};
                (config2.headers as Record<string, string>)["Authorization"] = "Bearer " + token;
                return config2;
            } else {
                return config;
            }
        });
    },
});

export const SalesDbGraphQLClient = new GraphQLClient({
    url: SalesDbApiUrl,
    transformRequest: (config) => {
        return auth.UpdateToken().then(() => {
            const token = auth.GetAccessToken(); //null if not logged in
            if (token) {
                const config2 = { ...config };
                if (!config2.headers) config2.headers = {};
                (config2.headers as Record<string, string>)["Authorization"] = "Bearer " + token;
                return config2;
            } else {
                return config;
            }
        });
    },
});

InitializeAuthListener(auth, client);

const root = createRoot(document.getElementById("root")!);
root.render(
    <GraphQLContext.Provider value={{ client: client }}>
        <AuthContainer client={auth}>
            <BrowserRouter>
                <Switch>
                    <Route path="/auth_callback">
                        <AuthCallback defaultPath="/">
                            <p>Logging in; please wait.</p>
                        </AuthCallback>
                    </Route>
                    <Route path="/auth_logout_callback">
                        <AuthLogoutCallback defaultPath="/">
                            <p>Logging out; please wait.</p>
                        </AuthLogoutCallback>
                    </Route>
                    <Route>
                        <RulerController>
                            <MessageController>
                                <GlobalController>
                                    <App autoLoginPromise={autoLoginPromise} />
                                </GlobalController>
                            </MessageController>
                        </RulerController>
                    </Route>
                </Switch>
            </BrowserRouter>
        </AuthContainer>
    </GraphQLContext.Provider>
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
//serviceWorker.unregister();
