import { Link, useHistory, useLocation } from "react-router-dom";
import React from "react";
import { Button, Col, Form, InputGroup, Nav, Row } from "react-bootstrap";
import { LinkContainer } from "react-router-bootstrap";
import PageHeader from "../../components/pageheader/PageHeader";
import { AgGridReact } from "ag-grid-react";
import { GraphQLContext, useQuery } from "@shane32/graphql";
import { ColDef, GridApi, GridReadyEvent, ICellRendererParams, IDatasource, IGetRowsParams } from "ag-grid-community";
import StringHelper from "../../helpers/StringHelper";
import FormatDate from "../../helpers/FormatDate";
import ReturnStatus from "../../enums/ReturnStatus";
import QueryString from "../../helpers/querystring";

interface IReturnQueryResult {
    returns: {
        totalCount: number;
        items: Array<IReturn>;
    };
}

interface ITabCountQueryResult {
    tabDraft: ITabCount;
    tabAuthorized: ITabCount;
    tabPendingRefund: ITabCount;
    tabCompleted: ITabCount;
    tabVoid: ITabCount;
}

interface ITabCount {
    totalCount: number;
}

//Interface for the returns to display
interface IReturn {
    id: string;
    createdAt: string;
    originalSaleId: string;
    originalSale: {
        id: string;
        total: number;
        shippingAddress: {
            fullName: string | null;
        } | null;
        salesChannel: {
            name: string;
        } | null;
    };
}

const TabsCountQuery = `
{
  tabDraft:returns(statuses: [DRAFT]) {
    totalCount
  }
  tabAuthorized:returns(statuses: [AUTHORIZED]) {
    totalCount
  }
  tabPendingRefund:returns(statuses: [PENDING_REFUND]) {
    totalCount
  }
  tabCompleted:returns(statuses: [COMPLETED]) {
    totalCount
  }
  tabVoid:returns(statuses: [VOID]) {
    totalCount 
  }
}`;

const ReturnQuery = `
query ($after: String, $search: String, $statuses: [ReturnStatus!], $sortOrder: ReturnsSortOrder!) {
    returns(after: $after, search: $search, statuses: $statuses, sortOrder: $sortOrder) {
      totalCount
      items {
        id
        createdAt
        originalSaleId
        originalSale {
          id
          total
          shippingAddress {
            fullName
          }
          salesChannel {
            name
          }
        }
      }
    }
  }
`;

interface IReturnQueryVariables {
    after: string | null;
    statuses: ReturnStatus[] | null;
    search: string | null;
    sortOrder: ReturnsSortOrder;
}

const enum ReturnsSortOrder {
    SalesChannelNameAscending = "SALES_CHANNEL_NAME_ASCENDING",
    SalesChannelNameDescending = "SALES_CHANNEL_NAME_DESCENDING",
    IdAscending = "ID_ASCENDING",
    IdDescending = "ID_DESCENDING",
    CustomerNameAscending = "CUSTOMER_NAME_ASCENDING",
    CustomerNameDescending = "CUSTOMER_NAME_DESCENDING",
    OriginalSaleIdAscending = "ORIGINAL_SALE_ID_ASCENDING",
    OriginalSaleIdDescending = "ORIGINAL_SALE_ID_DESCENDING",
    CreationDateAscending = "CREATION_DATE_ASCENDING",
    CreationDateDescending = "CREATION_DATE_DESCENDING",
    OriginalSaleTotalAscending = "ORIGINAL_SALE_TOTAL_ASCENDING",
    OriginalSaleTotalDescending = "ORIGINAL_SALE_TOTAL_DESCENDING",
}

const ReturnsIndex = () => {
    //TabCountQuery
    const { data: dataTabCounts } = useQuery<ITabCountQueryResult>(TabsCountQuery, {
        fetchPolicy: "cache-and-network",
    });
    const location = useLocation();
    const history = useHistory();
    const [gridApi, setGridApi] = React.useState<GridApi | null>(null);
    const search = new QueryString(location.search).getString("search");
    const [searchInput, setSearchInput] = React.useState(search || "");

    //GraphQL handle
    const graphQLContext = React.useContext(GraphQLContext);
    // the getrows function must be within a callback that has no dependencies, or the grid will not work properly
    // for this reason, we need to move location.pathname and search into a ref
    const locationRef = React.useRef({ pathname: location.pathname, search });
    locationRef.current = { pathname: location.pathname, search };
    const lastRunRef = React.useRef({ pathname: location.pathname, search });
    const [searchTotalCount, setSearchTotalCount] = React.useState<number | undefined>(undefined);
    const loadingGridId = React.useRef(0);
    const getRowsFunc = React.useCallback(
        (params: IGetRowsParams) => {
            const id = loadingGridId.current + 1;
            loadingGridId.current = id;
            lastRunRef.current = locationRef.current;
            setSearchTotalCount(undefined);
            let statuses: Array<ReturnStatus> | null = null;
            switch (locationRef.current.pathname) {
                case "/returns/draft":
                    statuses = [ReturnStatus.Draft];
                    break;
                case "/returns/authorized":
                    statuses = [ReturnStatus.Authorized];
                    break;
                case "/returns/pendingrefund":
                    statuses = [ReturnStatus.PendingRefund];
                    break;
                case "/returns/completed":
                    statuses = [ReturnStatus.Completed];
                    break;
                case "/returns/void":
                    statuses = [ReturnStatus.Void];
                    break;
                default:
                    statuses = [ReturnStatus.Authorized, ReturnStatus.Completed];
            }
            let isAscending = true;
            let sortOrder: ReturnsSortOrder = ReturnsSortOrder.IdAscending; // default sort for this tab
            if (params.sortModel.length > 0) {
                console.log(params.sortModel[0].sort);
                if (params.sortModel[0].sort === "asc") {
                    isAscending = true;
                } else if (params.sortModel[0].sort === "desc") {
                    isAscending = false;
                }
                switch (params.sortModel[0].colId) {
                    case "salesChannel":
                        sortOrder = isAscending ? ReturnsSortOrder.SalesChannelNameAscending : ReturnsSortOrder.SalesChannelNameDescending;
                        break;
                    case "id":
                        sortOrder = isAscending ? ReturnsSortOrder.IdAscending : ReturnsSortOrder.IdDescending;
                        break;
                    case "customerName":
                        sortOrder = isAscending ? ReturnsSortOrder.CustomerNameAscending : ReturnsSortOrder.CustomerNameDescending;
                        break;
                    case "originalSaleId":
                        sortOrder = isAscending ? ReturnsSortOrder.OriginalSaleIdAscending : ReturnsSortOrder.OriginalSaleIdDescending;
                        break;
                    case "createdAt":
                        sortOrder = isAscending ? ReturnsSortOrder.CreationDateAscending : ReturnsSortOrder.CreationDateDescending;
                        break;
                    case "originalSaleTotal":
                        sortOrder = isAscending
                            ? ReturnsSortOrder.OriginalSaleTotalAscending
                            : ReturnsSortOrder.OriginalSaleTotalDescending;
                        break;
                }
            }
            let after = params.startRow === 0 ? null : params.startRow.toString();
            let ret = graphQLContext.client.ExecuteQueryRaw<IReturnQueryResult, IReturnQueryVariables>({
                query: ReturnQuery,
                variables: {
                    after: after,
                    statuses: statuses,
                    search: locationRef.current.search || null,
                    sortOrder: sortOrder,
                },
            });

            ret.result.then(
                //success
                (result) => {
                    //failure
                    if (result.errors) {
                        console.log("Error fetching data: 1", result.errors);
                        return;
                    }
                    //update total count
                    if (loadingGridId.current === id) {
                        setSearchTotalCount(result.data?.returns.totalCount ?? 0);
                    }
                    //feed data into AG Grid
                    params.successCallback(result.data!.returns.items, result.data!.returns.totalCount);
                },
                //failure
                (result) => {
                    params.failCallback();
                    console.log("Error fetching data: 2", result);
                }
            );
        },
        [graphQLContext.client]
    ); //note: including graphQLContext.client because it will never change anyway

    React.useEffect(() => {
        // when pressing back or doing history.push, this will trigger the grid to refresh
        if (lastRunRef.current.pathname !== location.pathname || lastRunRef.current.search !== search) gridApi?.onFilterChanged();
    }, [location.pathname, search, gridApi]);

    const agGrid = React.useMemo(() => {
        //Columns for the ag grid
        const columns: ColDef<IReturn, any>[] = [
            {
                field: "salesChannel" as any,
                headerName: "Sales Channel",
                flex: 1,
                cellRenderer: (params: ICellRendererParams<IReturn>) => <>{params.data?.originalSale.salesChannel?.name}</>,
            },
            {
                field: "id",
                headerName: "Return #",
                cellRenderer: (params: ICellRendererParams<IReturn>) => <Link to={`/returns/${params.data?.id}`}>{params.data?.id}</Link>,
                flex: 1,
            },
            {
                field: "customerName" as any,
                headerName: "Customer",
                cellRenderer: (params: ICellRendererParams<IReturn>) => <>{params.data?.originalSale.shippingAddress?.fullName}</>,
                flex: 1,
            },
            {
                field: "originalSaleId" as any,
                headerName: "Original Sale #",
                cellRenderer: (params: ICellRendererParams<IReturn>) => (
                    <Link to={`/sales/${params.data?.originalSale.id}`}>{params.data?.originalSale.id}</Link>
                ),
                flex: 1,
            },
            {
                field: "createdAt",
                headerName: "Creation Date",
                cellRenderer: (params: ICellRendererParams<IReturn>) => (
                    <>
                        {StringHelper.IsNullOrWhitespace(params?.data?.createdAt)
                            ? null
                            : FormatDate.ConvertDateToLocalDateMMDDYYYY(params!.data!.createdAt)}
                    </>
                ),
                flex: 1,
            },
            {
                field: "originalSaleTotal" as any,
                headerName: "Original Sale Total",
                cellRenderer: (params: ICellRendererParams<IReturn>) => <>${params.data?.originalSale.total.toFixed(2)}</>,
                flex: 1,
            },
        ];

        const onGridReady = (params: GridReadyEvent) => {
            setGridApi(params.api);
            let dataSource: IDatasource = {
                getRows: getRowsFunc,
            };
            params.api.setDatasource(dataSource);
        };

        return (
            <AgGridReact
                columnDefs={columns}
                defaultColDef={{
                    suppressMovable: true,
                    resizable: true,
                    minWidth: 110,
                    sortable: true,
                }}
                components={{
                    loadingRenderer: function (params: ICellRendererParams) {
                        if (params.value !== undefined) {
                            return params.value;
                        } else {
                            return <img src="https://www.ag-grid.com/example-assets/loading.gif" alt="Loading..." />;
                        }
                    },
                }}
                onGridReady={onGridReady}
                animateRows={true}
                domLayout="autoHeight"
                rowModelType={"infinite"}
                paginationPageSize={30}
                cacheOverflowSize={2}
                maxConcurrentDatasourceRequests={2}
                infiniteInitialRowCount={1}
                maxBlocksInCache={2}
                pagination={true}
                enableCellTextSelection={true}
                ensureDomOrder={true}
            ></AgGridReact>
        );
    }, [getRowsFunc]);

    const onClickDraftReturn = () => {
        alert("Please create the return from the returns tab on the original sale.");
        return false;
    };

    const formatTabCount = (value: ITabCount | undefined) => (value ? ` (${value.totalCount})` : "");

    const onSubmitSearchForm = (event: React.BaseSyntheticEvent) => {
        event.preventDefault();
        history.push({
            pathname: "/returns/searchresults",
            search: "search=" + encodeURIComponent(searchInput),
        });
    };

    return (
        <>
            <PageHeader>Returns</PageHeader>
            <Row>
                <Col>
                    <Form.Label>Search</Form.Label>
                </Col>
            </Row>
            <Row>
                <Col md="4">
                    <Form onSubmit={onSubmitSearchForm}>
                        <InputGroup>
                            <Form.Control
                                id="searchValue"
                                type="text"
                                value={searchInput}
                                onChange={(e) => setSearchInput(e.target.value)}
                            />
                            <Button type="submit" variant="primary">
                                Search
                            </Button>
                        </InputGroup>
                    </Form>
                </Col>
                <Col md="3">
                    <Button onClick={onClickDraftReturn} variant="white">
                        Draft a return
                    </Button>
                </Col>
            </Row>
            <Nav variant="tabs" defaultActiveKey={location.pathname} className="mb-3 mt-3">
                <Nav.Item>
                    <LinkContainer to="/returns/draft" exact>
                        <Nav.Link>Draft ({dataTabCounts?.tabDraft.totalCount})</Nav.Link>
                    </LinkContainer>
                </Nav.Item>
                <Nav.Item>
                    <LinkContainer to="/returns" exact>
                        <Nav.Link>Authorized{formatTabCount(dataTabCounts?.tabAuthorized)}</Nav.Link>
                    </LinkContainer>
                </Nav.Item>
                <Nav.Item>
                    <LinkContainer to="/returns/pendingrefund" exact>
                        <Nav.Link>Pending Refund{formatTabCount(dataTabCounts?.tabPendingRefund)}</Nav.Link>
                    </LinkContainer>
                </Nav.Item>
                <Nav.Item>
                    <LinkContainer to="/returns/completed" exact>
                        <Nav.Link>Completed{formatTabCount(dataTabCounts?.tabCompleted)}</Nav.Link>
                    </LinkContainer>
                </Nav.Item>
                <Nav.Item>
                    <LinkContainer to="/returns/void" exact>
                        <Nav.Link>Void{formatTabCount(dataTabCounts?.tabVoid)}</Nav.Link>
                    </LinkContainer>
                </Nav.Item>
                {search ? (
                    <Nav.Item>
                        <LinkContainer to="/returns/searchresults" exact>
                            <Nav.Link>
                                Search Results
                                {searchTotalCount === undefined ? null : ` (${searchTotalCount})`}
                            </Nav.Link>
                        </LinkContainer>
                    </Nav.Item>
                ) : null}
            </Nav>
            <div className="ag-theme-alpine mt-3">{agGrid}</div>
        </>
    );
};
export default ReturnsIndex;
