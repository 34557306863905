import { useContext, useEffect, useRef, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import ErrorDisplay from "../../../../components/misc/ErrorDisplay";
import ModelType from "../../../../enums/ModelType";
import { GraphQLError, useMutation, useQuery } from "@shane32/graphql";
import useEffectOnce from "../../../../hooks/useEffectOnce";
import MobileLoading from "../../components/MobileLoading";
import PrimaryHeader from "../../components/PrimaryHeader";
import SecondaryHeader from "../../components/SecondaryHeader";
import MobileTable from "../../components/tables/MobileTable";
import ScannerToneContext from "../../contexts/ScannerToneContext";
import useScanner from "../../hooks/useScanner";
import SelectionType from "../../../../enums/SelectionType";

const locationQuery = `
query ($id: ID!) {
  location(id: $id) {
    id
    warehouse {
      name
    }
    name
  }
}
`;

interface ILocationQueryVariables {
    id: string;
}

interface ILocationQueryResult {
    location: {
        id: string;
        warehouse: {
            name: string;
        };
        name: string;
    };
}

const productQuery = `
query ($id: ID!) {
  product(id: $id) {
    id
    sku
  }
}
`;

interface IProductQueryVariables {
    id: string;
}

interface IProductQueryResult {
    product: {
        id: string;
        sku: string;
    };
}

interface ISearchBarcode {
    id: string;
    type: ModelType;
}

interface ISearchBarcodesQueryResult {
    searchBarcodes: ISearchBarcode[];
}

const searchBarcodesQuery = `
query ($search: String!, $userEntered: Boolean!) {
  searchBarcodes(search: $search, userEntered: $userEntered) {
    id
    type
  }
}
`;

const acceptedModelTypes: ModelType[] = [ModelType.Location, ModelType.Product];

interface IState {
    results: ISearchDetail[];
    hasSearched: boolean;
    searchBarcodeVariables: ISearchBarcodesQueryVariables;
    selectionType: SelectionType;
}

interface ISearchBarcodesQueryVariables {
    search: string;
    userEntered: boolean;
}

interface ISearchDetail {
    id: string;
    type: ModelType;
    description: string;
}

const StockTakeSearch = () => {
    const history = useHistory();
    const location = useLocation();
    const [manualLoading, setManualLoading] = useState(false);
    const urlSearchParams = new URLSearchParams(location.search);
    const searchFromUrl = urlSearchParams.get("search");
    const inputRef = useRef<HTMLInputElement>(null);
    const searchComponentRef = useRef<HTMLDivElement>(null);
    const primaryHeaderRef = useRef<HTMLDivElement>(null);
    const secondaryHeaderRef = useRef<HTMLDivElement>(null);
    const mounted = useRef(true);
    const [runLocationQuery] = useMutation<ILocationQueryResult, ILocationQueryVariables>(locationQuery);
    const [runProductQuery] = useMutation<IProductQueryResult, IProductQueryVariables>(productQuery);
    const [state, setState] = useState<IState>({
        results: [],
        hasSearched: false,
        searchBarcodeVariables: {
            search: "",
            userEntered: true,
        },
        selectionType: SelectionType.None,
    });
    const { playPositiveTone } = useContext(ScannerToneContext);
    const onScan = (scannerValue: string) => {
        setState({
            ...state,
            hasSearched: true,
            searchBarcodeVariables: {
                search: scannerValue,
                userEntered: false,
            },
            selectionType: SelectionType.Scanned,
        });
    };
    useScanner(onScan);

    const { loading, refetch, data, error } = useQuery<ISearchBarcodesQueryResult, ISearchBarcodesQueryVariables>(searchBarcodesQuery, {
        fetchPolicy: "no-cache",
        variables: !state.searchBarcodeVariables.search
            ? undefined
            : {
                  search: state.searchBarcodeVariables.search,
                  userEntered: state.searchBarcodeVariables.userEntered,
              },
        skip: !state.searchBarcodeVariables.search,
    });

    const searchBarcodesResult =
        data?.searchBarcodes.filter((searchBarcode) => acceptedModelTypes.some((modelType) => modelType === searchBarcode.type)) ?? [];

    useEffectOnce(() => {
        if (inputRef?.current && searchFromUrl) {
            inputRef.current.value = searchFromUrl;
        }
    });

    useEffect(() => {
        return () => {
            mounted.current = false;
        };
    }, [location.pathname]);

    useEffect(() => {
        if ((data?.searchBarcodes ?? []).length > 0) {
            (async () => {
                try {
                    setManualLoading(true);
                    let results: ISearchDetail[] = [];
                    for (let sbr of searchBarcodesResult) {
                        if (mounted.current) {
                            switch (sbr.type) {
                                case ModelType.Location:
                                    const location = (
                                        await runLocationQuery({
                                            variables: { id: sbr.id },
                                        })
                                    ).data.location;
                                    results.push({
                                        description: `${location.name} (${location.warehouse.name})`,
                                        id: location.id,
                                        type: ModelType.Location,
                                    });
                                    break;
                                case ModelType.Product:
                                    const product = (await runProductQuery({ variables: { id: sbr.id } })).data.product;
                                    results.push({ description: product.sku, id: product.id, type: ModelType.Product });
                                    break;
                                default:
                                    break;
                            }
                        }
                    }
                    setState({ ...state, results: results });
                } catch (error: any) {
                    alert((error as GraphQLError)?.message ?? "Unknown error");
                } finally {
                    setManualLoading(false);
                }
            })();
        }
    }, [data?.searchBarcodes]); // eslint-disable-line react-hooks/exhaustive-deps

    if (error) return <ErrorDisplay onClick={refetch}>{error.message}</ErrorDisplay>;

    const go = () => {
        if (inputRef && inputRef.current && inputRef.current.value) {
            setState({
                ...state,
                hasSearched: true,
                searchBarcodeVariables: {
                    search: inputRef.current.value.trim(),
                    userEntered: true,
                },
                selectionType: SelectionType.Searched,
            });
        }
    };

    if (!loading && searchBarcodesResult.length === 1) {
        let urlSP = new URLSearchParams();
        urlSP.set("search", state.searchBarcodeVariables.search);
        switch (searchBarcodesResult[0].type) {
            case ModelType.Location:
                playPositiveTone();
                urlSP.set("returnComponent", "StockTakeSearch");
                urlSP.set("locationSelectionType", state.selectionType);
                history.push(`/mobile/stocktake/stocktake/${searchBarcodesResult[0].id}?${urlSP.toString()}`);
                break;
            case ModelType.Product:
                playPositiveTone();
                urlSP.set("productSelectionType", state.selectionType);
                history.push(`/mobile/stocktake/stocktakescanbin/${searchBarcodesResult[0].id}?${urlSP.toString()}`);
                break;
            default:
                break;
        }
    }

    const componentRefs = [
        primaryHeaderRef.current?.clientHeight,
        secondaryHeaderRef.current?.clientHeight,
        searchComponentRef.current?.clientHeight,
    ];
    const marginTop = componentRefs.reduce((a, b) => (a ?? 0) + (b ?? 0), 0) ?? 0;

    return (
        <>
            {loading || manualLoading ? <MobileLoading fullscreen /> : <></>}

            <PrimaryHeader
                ref={primaryHeaderRef}
                title={`Select Location`}
                includeBackButton
                includeHomeButton
                backButtonText="< Roles"
                customBackButtonPath="/mobile/selectrole"
            />

            <SecondaryHeader ref={secondaryHeaderRef} title={"║▌Scan/Type SKU/Bin"} nextButtonHandler={() => void 0} />

            <div ref={searchComponentRef} style={{ padding: 10, backgroundColor: "#EDF0F4", display: "flex" }}>
                <input
                    ref={inputRef}
                    style={{
                        padding: 10,
                        alignSelf: "stretch",
                        height: 40,
                        borderRadius: 4,
                        border: "1px solid #798394",
                        marginRight: 10,
                        width: "100%",
                    }}
                    placeholder="Enter location or product sku..."
                />
                <button
                    style={{ alignSelf: "stretch", height: 40, borderRadius: 4, border: "1px solid #798394", minWidth: 112 }}
                    onClick={go}
                >
                    <b>GO</b>
                </button>
            </div>

            <div style={{ position: "fixed", overflowY: "auto", bottom: 0, top: 0, marginTop: marginTop }}>
                {(state.results.length ?? 0) < 1 ? (
                    <>
                        {!state.hasSearched
                            ? ""
                            : searchBarcodesResult.length > 0
                            ? `Loading data for ${searchBarcodesResult.length} results`
                            : "No results found!"}
                    </>
                ) : (
                    <MobileTable>
                        <thead style={{ position: "sticky", top: 0 }}>
                            <tr>
                                <td>Description</td>
                                <td>Type</td>
                            </tr>
                        </thead>
                        <tbody>
                            {state.results.map((x, i) => {
                                let urlSP = new URLSearchParams();
                                const match = searchBarcodesResult.at(i);
                                let onClick = () => history.replace(`/mobile/stocktake/stocktakesearch`);
                                if (match)
                                    switch (match.type) {
                                        case ModelType.Location:
                                            urlSP.set("locationSelectionType", SelectionType.Searched);
                                            onClick = () => history.push(`/mobile/stocktake/stocktake/${match.id}?${urlSP.toString()}`);
                                            break;
                                        case ModelType.Product:
                                            urlSP.set("locationSelectionType", SelectionType.None);
                                            urlSP.set("productSelectionType", SelectionType.Searched);
                                            onClick = () =>
                                                history.push(`/mobile/stocktake/stocktakescanbin/${match.id}?${urlSP.toString()}`);
                                            break;
                                        default:
                                            break;
                                    }
                                return (
                                    <tr key={i + x.id + x.type} onClick={() => onClick()}>
                                        <td>{x.description}</td>
                                        <td>{x.type}</td>
                                    </tr>
                                );
                            })}
                        </tbody>
                    </MobileTable>
                )}
            </div>
        </>
    );
};

export default StockTakeSearch;
