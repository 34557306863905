import React, { createElement, useContext, useRef, useState } from "react";
import { useHistory, useLocation, useParams } from "react-router-dom";
import ModelType from "../../../../enums/ModelType";
import { GraphQLError, useMutation, GraphQLContext } from "@shane32/graphql";
import useEffectOnce from "../../../../hooks/useEffectOnce";
import MobileButton from "../../components/buttons/MobileButton";
import MobileButtonContainer from "../../components/buttons/MobileButtonContainer";
import MobileButtonRow from "../../components/buttons/MobileButtonRow";
import ActiveProductImages from "../../components/images/ActiveProductImages";
import MobileLoading from "../../components/MobileLoading";
import PrimaryHeader from "../../components/PrimaryHeader";
import SecondaryHeader from "../../components/SecondaryHeader";
import MobileTable from "../../components/tables/MobileTable";
import MobileTableTitle from "../../components/tables/MobileTableTitle";
import ScannerToneContext from "../../contexts/ScannerToneContext";
import useScanner from "../../hooks/useScanner";
import { ISearchBarcodesQueryResult, ISearchBarcodesQueryVariables, searchBarcodesQuery } from "../../graphs/queries/SearchBarcodesQuery";
import { Modal } from "react-bootstrap";
import useConfirm from "../../hooks/useConfirm";
import SelectionType from "../../../../enums/SelectionType";
import useMultiMatchSelectorModal, { MultiMatchDataType } from "../../hooks/useMultiMatchSelectorModal";
import useLocationInputModal from "../../hooks/useLocationInputModal";
import useLostSkuModal from "../../hooks/useLostSkuModal";
import { useActionBarContext } from "../../contexts/ActionBarContext/ActionBarContext";

const productsByLocationProperties = `
location {
    id
    name
}
product {
    id
    sku
    description
    barcodes {
      barcode
    }
}
stockOnHand
`;

const productsByLocationBaseQuery = `
    productsByLocation(locationId: $locationId) {
        ${productsByLocationProperties}
    }
`;

const moveProductToBinMutation = `
    mutation ($moveProductsToBin: [MoveProductToBin!]!, $locationId: ID!) {
      inventory {
        moveProductsToBin(moveProductsToBin: $moveProductsToBin)
      }
      query {
        ${productsByLocationBaseQuery}
      }
    }
`;

interface IMoveProductToBin {
    fromLocationId: string;
    toLocationId: string;
    productId: string;
    quantity: number;
    productSelectionType: SelectionType;
    fromLocationSelectionType: SelectionType;
    toLocationSelectionType: SelectionType;
}

interface IMoveProductsToBinMutationVariables {
    moveProductsToBin: IMoveProductToBin[];
    locationId: string;
}

interface IMoveProductsToBinMutationResult {
    query: IProductsByLocationQueryResult;
}

const productQuery = `
query ($productId: ID!) {
    product(id: $productId) {
      id
      sku
      locationProducts {
        location {
          name
        }
        stockOnHand
      }
    }
  }
`;

interface IProductQueryResult {
    product: {
        id: string;
        sku: string;
        locationProducts: Array<{
            location: {
                name: string;
            };
            stockOnHand: number;
        }>;
    };
}

interface IProductQueryVariables {
    productId: string;
}

interface IMoveProductToBin {
    fromLocationId: string;
    toLocationId: string;
    productId: string;
    quantity: number;
    productSelectionType: SelectionType;
    fromLocationSelectionType: SelectionType;
    toLocationSelectionType: SelectionType;
}

const productsByLocationQueryStockTake = `
    query ($locationId: ID!) {
      ${productsByLocationBaseQuery}
      location(id: $locationId) {
        name
      }
    }
`;

const stockTakeMutation = `
    mutation ($locationId: ID!, $productId: ID!, $quantity: Decimal!, $productSelectionType: SelectionType!, $locationSelectionType: SelectionType!) {
        inventory {
            stockTake(locationId: $locationId, productId: $productId, quantity: $quantity, productSelectionType: $productSelectionType, locationSelectionType: $locationSelectionType)
        }
        query {
            ${productsByLocationBaseQuery}
        }
    }
`;

interface IStockTakeMutationResult {
    inventory: {
        stockTake: string;
    };
    query: IProductsByLocationQueryResult;
}

interface IStockTakeMutationVariables {
    locationId: string;
    productId: string;
    quantity: number;
    productSelectionType: SelectionType;
    locationSelectionType: SelectionType;
}

interface IProductByLocation {
    location: {
        id: string;
        name: string;
    };
    product: {
        id: string;
        sku: string;
        description: string;
        barcodes: Array<{
            barcode: string;
        }>;
    };
    stockOnHand: number;
}

interface IProductsByLocationQueryResult {
    productsByLocation: IProductByLocation[];
    location: {
        name: string;
    };
}

interface ISearchParams {
    locationId: string;
}

interface IState {
    manualLoading: boolean;
    productsByLocation: IProductByLocation[];
    completedItems: IProductByLocation[];
    changeCount: number;
    locationName: string;
    showAddProductModal: boolean;
    showAddOrPrintBarcodeComponent: boolean;
}

const StockTake = () => {
    const actionBarContext = useActionBarContext();
    const history = useHistory();
    const location = useLocation();
    const [runSearchBarcodes] = useMutation<ISearchBarcodesQueryResult, ISearchBarcodesQueryVariables>(searchBarcodesQuery);
    const [runStockTakeMutation] = useMutation<IStockTakeMutationResult, IStockTakeMutationVariables>(stockTakeMutation);
    const graphQLContext = React.useContext(GraphQLContext);
    const params = useParams<ISearchParams>();
    const { playPositiveTone, playNegativeTone } = useContext(ScannerToneContext);
    const addProductInputRef = useRef<HTMLInputElement>(null);
    const { confirmModal, showConfirmModal } = useConfirm();
    const { lostSkuModal, showLostSkuModal, isStockTakeLoading } = useLostSkuModal();
    const urlSearchParams = new URLSearchParams(location.search);
    const productIdFromUrl = urlSearchParams.get("productId"); //if there are multiple products in the bin, this is used as the sort order
    const searchFromUrl = urlSearchParams.get("search"); //the search value used in the first search component
    const searchedProductIdFromUrl = urlSearchParams.get("searchedProductId"); //the initial product id a user might have searched for
    const returnComponentFromUrl = urlSearchParams.get("returnComponent"); //determines the component to return to when using the back button in the primary header
    const locationSelectionType: SelectionType = (urlSearchParams.get("locationSelectionType") ?? SelectionType.None) as SelectionType;
    const [productSelectionType, setProductSelectionType] = useState<SelectionType>(
        //on page refresh, this will be reset back to whatever is in
        //the url (if there is anything), which for the stock take is fine.
        (urlSearchParams.get("productSelectionType") ?? SelectionType.None) as SelectionType
    );
    const [state, setState] = useState<IState>({
        changeCount: 0,
        completedItems: [],
        manualLoading: false,
        productsByLocation: [],
        locationName: "",
        showAddProductModal: false,
        showAddOrPrintBarcodeComponent: false,
    });
    const { multiMatchSelectorModal, showMultiMatchSelectorModal, open: isMultiMatchSelectorModalOpen } = useMultiMatchSelectorModal();
    const { locationInputModal, showLocationInputModal, open: isLocationInputModalOpen } = useLocationInputModal();
    const [runProductQuery] = useMutation<IProductQueryResult, IProductQueryVariables>(productQuery);
    const [runMoveProductToBinMutation] = useMutation<IMoveProductsToBinMutationResult, IMoveProductsToBinMutationVariables>(
        moveProductToBinMutation
    );

    const onScan = async (scannerValue: string) => {
        if (state.manualLoading || state.showAddProductModal || isMultiMatchSelectorModalOpen || isLocationInputModalOpen) return;
        try {
            setState({ ...state, manualLoading: true });
            const res = await runSearchBarcodes({ variables: { search: scannerValue, userEntered: false } });
            const barcodeProducts = res.data.searchBarcodes.filter((x) => x.type === ModelType.Product);
            if (barcodeProducts.length < 1) return playNegativeTone();
            let scannedProductId = barcodeProducts[0].id;
            if (barcodeProducts.length > 1) {
                const selected = await showMultiMatchSelectorModal(
                    "Select Correct Product SKU",
                    barcodeProducts.map((x) => x.id),
                    MultiMatchDataType.Product
                );
                if (!selected) return;
                scannedProductId = selected.id;
            }
            //check where the scanned product is. could either be in "productsByLocation" or "completedItems" variables.
            if (state.productsByLocation.map((x) => x.product.id).includes(scannedProductId)) {
                setProductSelectionType(SelectionType.Scanned);
                playPositiveTone();
                itemsInBinTableRowClickHandler(scannedProductId);
            } else if (state.completedItems.map((x) => x.product.id).includes(scannedProductId)) {
                setProductSelectionType(SelectionType.Scanned);
                playPositiveTone();
                stockTakeCompletedTableRowClickHandler(scannedProductId);
            } else {
                playNegativeTone();
            }
        } catch (error: any) {
            playNegativeTone();
            alert((error as GraphQLError)?.message ?? "Unknown error");
        } finally {
            setState({ ...state, manualLoading: false });
        }
    };
    useScanner(onScan);

    useEffectOnce(() => {
        setState({ ...state, manualLoading: true });
        graphQLContext.client
            .ExecuteQueryRaw<IProductsByLocationQueryResult, ISearchParams>({
                query: productsByLocationQueryStockTake,
                variables: {
                    locationId: params.locationId,
                },
            })
            .result.then(
                (result) => {
                    if (result.errors) {
                        setState({ ...state, manualLoading: false });
                        return alert(result.errors.map((x) => x.message).join(", "));
                    } else if (result.networkError) {
                        setState({ ...state, manualLoading: false });
                        return alert("A network error occured.");
                    } else {
                        if (result.data && result.data?.productsByLocation) {
                            setState({
                                ...state,
                                productsByLocation: result.data?.productsByLocation.sort((a, b) =>
                                    a.product.sku.localeCompare(b.product.sku)
                                ),
                                manualLoading: false,
                                locationName: result.data?.location.name ?? "N/A",
                            });
                        }
                    }
                },
                (result) => {
                    setState({ ...state, manualLoading: false });
                }
            );
    });

    const decrement = () => {
        if (state.productsByLocation.length > 0) {
            let newValue = state.changeCount - 1;
            if (newValue < -Math.abs(state.productsByLocation.find((product) => product.product.id === productIdFromUrl)?.stockOnHand ?? 0))
                return;
            else setState({ ...state, changeCount: newValue });
        }
    };

    const increment = () => setState({ ...state, changeCount: state.changeCount + 1 });

    const confirmStock = () => {
        if (!firstProduct) return;
        let completedItemsCopy = [...state.completedItems];
        completedItemsCopy.push({ ...firstProduct, stockOnHand: firstProduct.stockOnHand + state.changeCount });
        setState({ ...state, manualLoading: true });
        runStockTakeMutation({
            variables: {
                locationId: firstProduct!.location.id,
                productId: firstProduct!.product.id,
                quantity: firstProduct!.stockOnHand + state.changeCount,
                locationSelectionType: locationSelectionType,
                productSelectionType: productSelectionType,
            },
        })
            .then((res) => {
                //remove any product that has already had stock verified from the array
                let products = res.data.query.productsByLocation;
                const completedProductIds = completedItemsCopy.map((x) => x.product.id);
                completedProductIds.forEach((id) => {
                    const index = products.findIndex((x) => x.product.id === id);
                    if (index > -1) products.splice(index, 1);
                });
                products.sort((a, b) => a.product.sku.localeCompare(b.product.sku));

                setState({
                    ...state,
                    productsByLocation: products,
                    manualLoading: false,
                    changeCount: 0,
                    completedItems: completedItemsCopy,
                });

                setProductSelectionType(SelectionType.None);

                playPositiveTone();
            })
            .catch((error: GraphQLError) => {
                alert(error.message);
                setState({ ...state, manualLoading: false });
            });
    };

    const cancel = () => setState({ ...state, changeCount: 0 });

    //moves clicked tr element to the top of the list
    const itemsInBinTableRowClickHandler = (productId: string) => {
        setProductSelectionType(SelectionType.Selected);
        setState({ ...state, changeCount: 0 });
        window.scrollTo(0, 0);
        urlSearchParams.set("productId", productId);
        history.replace(`${location.pathname}?${urlSearchParams.toString()}`);
    };

    const stockTakeCompletedTableRowClickHandler = (productId: string) => {
        let productsByLocationCopy = [...state.productsByLocation];
        let completedItemsCopy = [...state.completedItems];
        const productIndex = completedItemsCopy.findIndex((x) => x.product.id === productId);
        if (productIndex < 0) return;
        const matchingProduct = completedItemsCopy.splice(productIndex, 1)[0];
        productsByLocationCopy.sort((a, b) => a.product.sku.localeCompare(b.product.sku));
        completedItemsCopy.sort((a, b) => a.product.sku.localeCompare(b.product.sku));
        productsByLocationCopy.unshift(matchingProduct);
        setProductSelectionType(SelectionType.Selected);
        setState({
            ...state,
            completedItems: completedItemsCopy,
            productsByLocation: productsByLocationCopy,
            changeCount: 0,
        });
        window.scrollTo(0, 0);
        urlSearchParams.set("productId", matchingProduct.product.id);
        history.replace(`${location.pathname}?${urlSearchParams.toString()}`);
    };

    const addProduct = async () => {
        try {
            if (state.changeCount !== 0)
                return await showConfirmModal(
                    "The active products stock count has changed, but hasn't been saved! Please save or cancel the changes first!",
                    "Stock Change",
                    undefined,
                    true
                );
            const addProductInputValue = addProductInputRef?.current?.value?.trim();
            if (state.manualLoading || !addProductInputRef || !addProductInputRef.current || !addProductInputValue) return;
            setState({ ...state, manualLoading: true });

            const res = await runSearchBarcodes({ variables: { search: addProductInputValue, userEntered: true } });
            const barcodes = (res.data?.searchBarcodes ?? []).filter((x) => x.type === ModelType.Product);
            if (barcodes.length < 1) return alert(`Found ${barcodes.length} products matching value '${addProductInputValue}'.`);
            if (barcodes.length > 1)
                return alert(`Found ${barcodes.length} products matching value '${addProductInputValue}'. There should only be one.`);
            const productId = barcodes[0].id;

            //make sure the added product isn't already in the current location
            const existingProduct = state.productsByLocation.concat(state.completedItems).find((pbl) => pbl.product.id === productId);
            if (existingProduct) {
                return await showConfirmModal(
                    `This location already contains product '${existingProduct.product.sku}'!`,
                    "Product Already Exists",
                    undefined,
                    true
                );
            }

            const stockTakeRes = await runStockTakeMutation({
                variables: {
                    locationId: params.locationId,
                    productId: productId,
                    quantity: 1,
                    locationSelectionType: locationSelectionType,
                    productSelectionType: productSelectionType,
                },
            });
            if (!stockTakeRes?.data) {
                return await showConfirmModal("Stock take mutation returned a null result!", "Error", undefined, true);
            }
            const resData = stockTakeRes.data;
            //remove any product that has already had stock verified from the array
            let products = resData.query.productsByLocation ?? [];
            let completedItemsCopy = [...state.completedItems];
            const completedProductIds = completedItemsCopy.map((x) => x.product.id);
            completedProductIds.forEach((id) => {
                const index = products.findIndex((x) => x.product.id === id);
                if (index > -1) products.splice(index, 1);
            });

            //change url to make the added product the first item in the interface
            urlSearchParams.set("productId", productId);
            history.push(`${location.pathname}?${urlSearchParams.toString()}`);

            state.changeCount = 0;
            state.productsByLocation = products;
            state.completedItems = completedItemsCopy;

            setProductSelectionType(SelectionType.None);

            playPositiveTone();
        } catch (error: any) {
            alert((error as GraphQLError)?.message ?? "Unknown error");
        } finally {
            setState({
                ...state,
                showAddProductModal: false,
                manualLoading: false,
            });
        }
    };

    const transfer = async () => {
        try {
            setState({ ...state, manualLoading: true });

            if (!firstProduct) return alert("First item is undefined");

            if (state.changeCount < 1) return alert(`Please select an amount of products to move.`);

            if (state.changeCount > firstProduct.stockOnHand)
                return alert(`You can't move more than ${firstProduct.stockOnHand} products.`);

            //show modal where user types in name of location they want to stow product(s)
            let locationName = (
                await showLocationInputModal({
                    modalTitle: "Enter a Location Name",
                    locations: (
                        await runProductQuery({ variables: { productId: firstProduct.product.id } })
                    ).data.product.locationProducts.map((x) => {
                        return {
                            locationName: x.location.name,
                            stockOnHand: x.stockOnHand,
                        };
                    }),
                    sku: firstProduct.product.sku,
                })
            )?.trim();
            if (!locationName) return;

            let location: { id: string; name: string } | undefined = undefined;
            const res = await runSearchBarcodes({ variables: { search: locationName, userEntered: true } });
            const barcodeLocations = (res.data?.searchBarcodes ?? []).filter((x) => x.type === ModelType.Location);
            if (barcodeLocations.length > 0) {
                if (barcodeLocations.length > 1) {
                    const selectedLocation = await showMultiMatchSelectorModal(
                        "Select Location",
                        barcodeLocations.map((x) => x.id),
                        MultiMatchDataType.Location
                    );
                    if (!selectedLocation) return;
                    location = { id: selectedLocation.id, name: selectedLocation.name };
                } else location = { id: barcodeLocations[0].id, name: locationName };
            } else {
                return playNegativeTone();
            }

            if (!location) return playNegativeTone();

            const entry: IMoveProductToBin = {
                fromLocationId: params.locationId,
                productId: firstProduct.product.id,
                quantity: state.changeCount,
                toLocationId: location.id,
                fromLocationSelectionType: locationSelectionType,
                productSelectionType: productSelectionType,
                toLocationSelectionType: SelectionType.Searched,
            };

            const transferData = (
                await runMoveProductToBinMutation({
                    variables: {
                        moveProductsToBin: [entry],
                        locationId: params.locationId,
                    },
                })
            ).data;
            //update the items in the current bin
            //remove any product that has already had stock verified from the array
            let products = transferData.query.productsByLocation;
            products.sort((a, b) => a.product.sku.localeCompare(b.product.sku));
            state.changeCount = 0;
            state.productsByLocation = products;
            playPositiveTone();
            actionBarContext.setActionBarContent(`Transfered ${entry.quantity} to ${location.name}`);
        } catch (error: any) {
            alert((error as GraphQLError)?.message ?? "Unknown error");
        } finally {
            setState({ ...state, manualLoading: false });
        }
    };

    const runLostSku = async () => {
        if (!firstProduct || isStockTakeLoading) return;

        let completedProducts: IProductByLocation[] = [];
        let notCompletedProducts: IProductByLocation[] = [];

        try {
            setState({ ...state, manualLoading: true });
            var response = await showLostSkuModal<IProductsByLocationQueryResult>(
                {
                    locationId: params.locationId,
                    productId: firstProduct.product.id,
                    locationName: state.locationName,
                    locationSelectionType: locationSelectionType,
                    productSelectionType: productSelectionType,
                    productSku: firstProduct.product.sku,
                    quantityInBin: firstProduct.stockOnHand,
                },
                `productsByLocation(locationId: "${params.locationId}") {
                    ${productsByLocationProperties}
                }`
            );
            if (!response) return;

            const productsInLocation = response.productsByLocation ?? [];

            //the completedProducts array needs to contain all of the products that were previusly verified, including the most recent one
            let completedProductIds = state.completedItems.map((x) => x.product.id).concat(firstProduct.product.id);
            completedProducts = productsInLocation.filter((x) => completedProductIds.includes(x.product.id));
            notCompletedProducts = productsInLocation.filter((x) => !completedProductIds.includes(x.product.id));

            notCompletedProducts.sort((a, b) => a.product.sku.localeCompare(b.product.sku));

            setProductSelectionType(SelectionType.None);

            urlSearchParams.delete("productId");
            history.replace(`${location.pathname}?${urlSearchParams.toString()}`);

            playPositiveTone();
        } catch (error: any) {
            alert((error as GraphQLError)?.message ?? (error as Error)?.message ?? "Unknown error");
        } finally {
            setState({
                ...state,
                productsByLocation: notCompletedProducts,
                changeCount: 0,
                completedItems: completedProducts,
                manualLoading: false,
            });
        }
    };

    const firstProduct = state.productsByLocation.find((product) => product.product.id === productIdFromUrl);
    const productsByLocation = state.productsByLocation.filter((product) => product.product.id !== productIdFromUrl);

    let backButtonText = "< Scan Location";
    let customBackButtonPath = `/mobile/stocktake/`;
    if (returnComponentFromUrl) {
        if (returnComponentFromUrl === "StockTakeSearch") {
            backButtonText = "< Search";
            customBackButtonPath += `stocktakesearch`;
        } else if (returnComponentFromUrl === "StockTakeScanBin" && searchedProductIdFromUrl) {
            customBackButtonPath += `stocktakescanbin/${searchedProductIdFromUrl}`;
        } else customBackButtonPath += `stocktakesearch`;
    } else customBackButtonPath += `stocktakesearch`;
    let q = new URLSearchParams();
    if (searchFromUrl) {
        q.set("search", searchFromUrl);
        customBackButtonPath += `?${q.toString()}`;
    }

    return (
        <>
            {state.manualLoading ? <MobileLoading fullscreen /> : <></>}

            <PrimaryHeader
                title={state.locationName}
                includeHomeButton
                includeBackButton
                backButtonText={backButtonText}
                customBackButtonPath={customBackButtonPath}
            />

            <SecondaryHeader title="Confirm Stock" nextButtonHandler={confirmStock} />

            <MobileTable isActiveTable>
                <thead>
                    <tr>
                        <td>Before</td>
                        <td>Change</td>
                        <td>After</td>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td>{!firstProduct ? "-" : firstProduct.stockOnHand}</td>
                        <td>{!firstProduct ? "-" : state.changeCount < 1 ? state.changeCount : `+${state.changeCount}`}</td>
                        <td>{!firstProduct ? "-" : firstProduct.stockOnHand + state.changeCount}</td>
                    </tr>
                    <tr>
                        <td>{!firstProduct ? "-" : firstProduct.location.name}</td>
                        <td>{!firstProduct ? "-" : firstProduct.product.sku ? firstProduct.product.sku : "N/A"}</td>
                        <td>-</td>
                    </tr>
                </tbody>
            </MobileTable>

            {/* image set */}
            <div style={{ padding: "10px 10px 0 10px" }}>
                <ActiveProductImages sku={firstProduct?.product.sku} />
            </div>

            <div style={{ paddingLeft: 10, paddingRight: 10, paddingTop: 10 }}>
                {/* category */}
                <div style={{ fontSize: 12 }}>
                    Description: <b>{!firstProduct ? "-" : firstProduct.product.description}</b>
                </div>
            </div>

            <MobileButtonContainer>
                <MobileButtonRow>
                    <MobileButton disabled={!firstProduct} onClick={decrement}>
                        -
                    </MobileButton>
                    <MobileButton disabled={!firstProduct} onClick={increment}>
                        +
                    </MobileButton>
                    <MobileButton disabled={!firstProduct} onClick={confirmStock}>
                        Confirm Stock
                    </MobileButton>
                </MobileButtonRow>
                <MobileButtonRow>
                    <MobileButton onClick={() => setState({ ...state, showAddProductModal: true })}>Add Product</MobileButton>
                    <MobileButton
                        disabled={!firstProduct || firstProduct.stockOnHand - state.changeCount === firstProduct.stockOnHand}
                        onClick={cancel}
                    >
                        Cancel
                    </MobileButton>
                </MobileButtonRow>
                <MobileButtonRow>
                    <MobileButton
                        disabled={!firstProduct}
                        onClick={() => history.push(`${location.pathname}/${firstProduct?.product?.id}${location.search}`)}
                    >
                        Add or Print Barcode
                    </MobileButton>
                    <MobileButton disabled={!firstProduct} onClick={async () => await runLostSku()}>
                        Update Qty
                    </MobileButton>
                </MobileButtonRow>
                <MobileButtonRow>
                    <MobileButton disabled={!firstProduct} onClick={async () => await transfer()}>
                        Transfer ({state.changeCount}) to Bin "Any"
                    </MobileButton>
                </MobileButtonRow>
            </MobileButtonContainer>

            {productsByLocation.length < 1 ? (
                <></>
            ) : (
                <div>
                    <MobileTableTitle>Other Items in Bin</MobileTableTitle>
                    <MobileTable>
                        <tbody>
                            {productsByLocation.map((p, i) => {
                                //if (i < 1) return <React.Fragment key={p.product.id}></React.Fragment>;
                                return (
                                    <tr onClick={() => itemsInBinTableRowClickHandler(p.product.id)} key={p.product.id} id={p.product.id}>
                                        <td>{p.product.description}</td>
                                        <td>{p.product.sku}</td>
                                        <td>{p.stockOnHand}</td>
                                    </tr>
                                );
                            })}
                        </tbody>
                    </MobileTable>
                </div>
            )}

            {state.completedItems.length < 1 ? (
                <></>
            ) : (
                <div>
                    <MobileTableTitle>Stock Take Completed</MobileTableTitle>
                    <MobileTable>
                        <tbody>
                            {state.completedItems.map((p, i) => {
                                return (
                                    <tr
                                        onClick={() => stockTakeCompletedTableRowClickHandler(p.product.id)}
                                        key={p.product.id}
                                        id={p.product.id}
                                    >
                                        <td>{p.product.description}</td>
                                        <td>{p.product.sku}</td>
                                        <td>{p.stockOnHand}</td>
                                    </tr>
                                );
                            })}
                        </tbody>
                    </MobileTable>
                </div>
            )}

            <Modal show={state.showAddProductModal} onHide={() => setState({ ...state, showAddProductModal: false })}>
                <Modal.Header closeButton>
                    <Modal.Title>Add Product</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <input
                        autoFocus
                        style={{ width: "100%", height: 38, borderRadius: 4 }}
                        ref={addProductInputRef}
                        placeholder="Enter product SKU or barcode"
                    />
                </Modal.Body>
                <Modal.Footer style={{ width: "100%" }}>
                    <MobileButtonRow>
                        <MobileButton onClick={async () => await addProduct()}>Add Product</MobileButton>
                        <MobileButton onClick={() => setState({ ...state, showAddProductModal: false })}>Close</MobileButton>
                    </MobileButtonRow>
                </Modal.Footer>
            </Modal>

            {createElement(confirmModal)}

            {createElement(multiMatchSelectorModal)}

            {createElement(locationInputModal)}

            {lostSkuModal}
        </>
    );
};

export default StockTake;
