import { GraphQLError, useMutation, useQuery } from "@shane32/graphql";
import { createElement, CSSProperties, useCallback, useEffect, useState } from "react";
//import { Modal } from "react-bootstrap";
import { Redirect, useHistory, useLocation, useParams } from "react-router-dom";
import PrinterType from "../../../../../enums/PrinterType";
import SaleFulfillmentStatus from "../../../../../enums/SaleFulfillmentStatus";
import SaleStatus from "../../../../../enums/SaleStatus";
import MobileLoading from "../../../components/MobileLoading";
import PrimaryHeader from "../../../components/PrimaryHeader";
//import MobileRadio from "../../../components/radios/MobileRadio";
//import MobileRadioContainer from "../../../components/radios/MobileRadioContainer";
import SecondaryHeader from "../../../components/SecondaryHeader";
import useSelectModal, { ISelect } from "../../../hooks/useSelectModal";
import { forgeURLSearchParams, ILastPrintedProperties, IPackURLSearchParams, typedParse } from "../ScanSku";
import ProductType from "../../../../../enums/ProductType";
import useSpecialModal from "../../../hooks/useSpecialModal";
import PackageType from "../../../../../enums/PackageType";
import MobileButton from "../../../components/buttons/MobileButton";

const saleFulfillmentStationQuery = `
query ($saleFulfillmentId: ID!, $stationId: ID!, $warehouseId: ID!) {
    saleFulfillment(id: $saleFulfillmentId) {
      status
      shipDate
      shippingServiceId
      packageId
      sale {
        id
        shippingServiceId
        status
        shippingService {
          name
          shippingCarrier {
            name
          }
          dimensionalWeightDivisor
        }
      }
      lineItems {
        packageQuantity
        saleLineItem {
          product {
            storageWeight
            shippingWeight
            sku
            type
          }
        }
      }
    }
    station(id: $stationId) {
      stationSet {
        id
        name
        printers {
          id
          description
          type
          stationSetId
        }
      }
    }
    labelProviders {
      items {
        id
        name
      }
    }
    shippingServices {
        items {
            id
            name
            active
            shippingCarrier {
                name
            }
        }
    }
    mobile {
      getOpenSaleFulfillmentState(
        warehouseId: $warehouseId
        saleFulfillmentId: $saleFulfillmentId
      ) {
        doItemsRemain
      }
    }
  }
`;

export interface ISaleFulfillmentStationResponse {
    saleFulfillment: {
        status: SaleFulfillmentStatus;
        shipDate: string | null;
        shippingServiceId: string | null;
        packageId: string | null;
        sale: {
            id: string;
            shippingServiceId: string;
            status: SaleStatus;
            shippingService: {
                name: string;
                shippingCarrier: {
                    name: string;
                };
                dimensionalWeightDivisor: number | null;
            } | null;
        };
        lineItems: Array<{
            packageQuantity: number;
            saleLineItem: {
                product: {
                    shippingWeight: number | null;
                    storageWeight: number | null;
                    packageType: PackageType | null;
                    sku: string;
                    type: ProductType;
                };
            };
        }>;
    } | null;
    station: {
        stationSet: {
            id: string;
            name: string;
            printers: Array<{
                id: string;
                description: string;
                type: PrinterType;
                stationSetId: string | null;
            }>;
        };
    } | null;
    labelProviders: {
        items: Array<{
            id: string;
            name: string;
        }>;
    };
    shippingServices: {
        items: Array<{
            id: string;
            name: string;
            active: boolean;
            shippingCarrier: {
                name: string;
            };
        }>;
    };
    mobile: {
        getOpenSaleFulfillmentState: {
            doItemsRemain: boolean;
        };
    };
}

interface ISaleFulfillmentStationQueryVariables {
    saleFulfillmentId: string;
    stationId: string;
    warehouseId: string;
}

interface ISearchParams {
    stationId: string;
    locationId: string;
}

const generateShippingLabelMutation = `
mutation ($labelProviderId: ID!, $saleFulfillmentId: ID!, $shippingServiceId: ID, $weight: Decimal, $printerId: ID!) {
  api {
    shippingLabel {
      generateShippingLabel(labelProviderId: $labelProviderId, saleFulfillmentId: $saleFulfillmentId, serviceId: $shippingServiceId, weight: $weight) {
        print(printerId: $printerId)
      }
    }
  }
}
`;

interface IGenerateShippingLabelMutationVariables {
    labelProviderId: string;
    saleFulfillmentId: string;
    shippingServiceId: string | null;
    weight: number | null;
    printerId: string;
}

const changeSaleStatusMutation = `
mutation ($saleId: ID!, $oldSaleStatus: SaleStatus!, $newSaleStatus: SaleStatus!) {
  sale {
    changeStatus(id: $saleId, oldStatus: $oldSaleStatus, newStatus: $newSaleStatus) {
      status
    }
  }
}
`;

interface IChangeSaleStatusMutationVariables {
    saleId: string;
    oldSaleStatus: SaleStatus;
    newSaleStatus: SaleStatus;
}

interface IChangeSaleStatusMutationResult {
    sale: {
        changeStatus: {
            status: SaleStatus;
        };
    };
}

export const reprintShippingLabelMutation = `
mutation ($saleFulfillmentId: ID!, $printerId: ID!) {
  api {
    shippingLabel {
      reprintLabel(saleFulfillmentId: $saleFulfillmentId) {
        print(printerId: $printerId)
      }
    }
  }
}
`;

export interface IReprintShippingLabelMutationVariables {
    saleFulfillmentId: string;
    printerId: string;
}

export interface IReprintShippingLabelMutationResult {
    api: {
        shippingLabel: {
            reprintLabel: {
                print: boolean;
            };
        };
    };
}

const editSaleMutation = `
mutation ($original: SaleInput!, $modified: SaleInput!) {
    sale {
      edit(original: $original, modified: $modified) {
        id
      }
    }
  }
`;

interface IEditSaleMutationResult {
    sale: {
        edit: {
            id: string;
        };
    };
}

interface IEditSaleMutationVariables {
    original: ISaleInputModel;
    modified: ISaleInputModel;
}

interface ISaleInputModel {
    id: string;
    dropShip: boolean;
    status: SaleStatus;
    subtotal: number;
    tax: number;
    total: number;
    saleFulfillments: Array<ISaleFulfillmentInputModel> | null;
}

interface ISaleFulfillmentInputModel {
    id: string;
    status: SaleFulfillmentStatus;
    trackingNumber: string;
    packageNumber: number;
    hasInsurance: boolean;
    weight: number | null;
    shipDate: string | null;
}

const downloadShippingLabelMutation = `
mutation ($labelProviderId: ID!, $saleFulfillmentId: ID!, $shippingServiceId: ID, $weight: Decimal) {
  api {
    shippingLabel {
      generateShippingLabel(labelProviderId: $labelProviderId, saleFulfillmentId: $saleFulfillmentId, serviceId: $shippingServiceId, weight: $weight) {
        download
      }
    }
  }
}
`;

interface IDownloadShippingLabelMutationResult {
    api: {
        shippingLabel: {
            generateShippingLabel: {
                download: string;
            };
        };
    };
}

interface IDownloadShippingLabelMutationVariables {
    labelProviderId: string;
    saleFulfillmentId: string;
    shippingServiceId: string | null;
    weight: number | null;
}

interface IGenerateShippingLabelMutationVariables extends IDownloadShippingLabelMutationVariables {
    printerId: string;
}

interface ISaleFulfillment {
    id: string;
    status: SaleFulfillmentStatus;
    trackingNumber: string;
    packageNumber: number;
    hasInsurance: boolean;
    weight: number | null;
    shipDate: string | null;
    shippingServiceId: string | null;
}

interface ISaleQueryResult {
    sale: {
        id: string;
        dropShip: boolean;
        shippingService: {
            id: string;
            dimensionalWeightDivisor: number | null;
        } | null;
        status: SaleStatus;
        subtotal: number;
        tax: number;
        total: number;
        fulfillments: Array<ISaleFulfillment>;
    };
}

interface ISaleQueryVariables {
    saleId: string;
}

const saleQuery = `
query ($saleId: ID!) {
  sale(id: $saleId) {
    id
    dropShip
    shippingService {
        id
        dimensionalWeightDivisor
    }
    status
    subtotal
    tax
    total
    fulfillments {
      id
      status
      trackingNumber
      packageNumber
      hasInsurance
      weight
      shipDate
      shippingServiceId
    }
  }
}
`;

enum EDecimalMode {
    Tenths = "Tenths",
    Hundredths = "Hundredths",
}

const PrintLabel = () => {
    const params = useParams<ISearchParams>();
    const location = useLocation();
    const rawURLSearchParams = new URLSearchParams(decodeURIComponent(location.search));
    const urlSearchParams = typedParse<IPackURLSearchParams>(rawURLSearchParams.get("urlsp"));
    const history = useHistory();
    const { selectModal, showSelectModal } = useSelectModal();
    const { specialModal, showSpecialModal } = useSpecialModal();
    const [mutationLoading, setMutationLoading] = useState(false);
    const [reprintShippingLabel, setReprintShippingLabel] = useState(false);
    const [downloadButtonClickCount, setDownloadButtonClickCount] = useState(0);
    const [packageWeight, setPackageWeight] = useState(0);
    const [decimalMode, setDecimalMode] = useState(EDecimalMode.Tenths);
    // Increment/Decrement functions:
    const incrementWhole = useCallback(() => setPackageWeight((w) => w + 1), []);
    const incrementTenth = useCallback(() => setPackageWeight((w) => Math.max(0, +(w + 0.1).toFixed(2))), []);
    const incrementHundredth = useCallback(() => setPackageWeight((w) => Math.max(0, +(w + 0.01).toFixed(2))), []);

    const decrementWhole = useCallback(() => setPackageWeight((w) => Math.max(0, w - 1)), []);
    const decrementTenth = useCallback(() => setPackageWeight((w) => Math.max(0, +(w - 0.1).toFixed(2))), []);
    const decrementHundredth = useCallback(() => setPackageWeight((w) => Math.max(0, +(w - 0.01).toFixed(2))), []);

    const { loading, data, error } = useQuery<ISaleFulfillmentStationResponse, ISaleFulfillmentStationQueryVariables>(
        saleFulfillmentStationQuery,
        {
            fetchPolicy: "no-cache",
            variables: !urlSearchParams.activeSaleFulfillmentId
                ? undefined
                : {
                      saleFulfillmentId: urlSearchParams.activeSaleFulfillmentId,
                      stationId: params.stationId,
                      warehouseId: "1",
                  },
            skip: !urlSearchParams.activeSaleFulfillmentId,
        }
    );

    const [runGenerateShippingLabelMutation] = useMutation<{}, IGenerateShippingLabelMutationVariables>(generateShippingLabelMutation);
    // const [runPrintPackSlipMutation] = useMutation<IPrintPackSlipResult, IPrintPackSlipVariables>(printPackSlipMutation);
    const [runChangeSaleStatusMutation] = useMutation<IChangeSaleStatusMutationResult, IChangeSaleStatusMutationVariables>(
        changeSaleStatusMutation
    );
    const [runReprintShippingLabelMutation] = useMutation<{}, IReprintShippingLabelMutationVariables>(reprintShippingLabelMutation);

    const [runEditSaleMutation] = useMutation<IEditSaleMutationResult, IEditSaleMutationVariables>(editSaleMutation);

    const [runDownloadShippingLabelMutation] = useMutation<IDownloadShippingLabelMutationResult, IDownloadShippingLabelMutationVariables>(
        downloadShippingLabelMutation
    );

    // const [runDownloadPackSlipMutation] = useMutation<IDownloadPackSlipResult, IDownloadPackSlipVariables>(downloadPackSlipMutation);

    //special way to use the sale query because we only need to fetch sale data occasionally
    const [runSaleQuery] = useMutation<ISaleQueryResult, ISaleQueryVariables>(saleQuery);

    // const getPackSlip = async (download: boolean) => {
    //     if (!data || !data.station) return alert("Data is null");
    //     if (!data.station) return alert("Station data is null");
    //     if (!data.saleFulfillment) return alert("Sale fulfillment data is null");
    //     if (data.labelProviders.items.length < 1) return alert(`Error: No label providers found`);

    //     const defaultPackingSlipPrinterId = data?.station?.stationSet?.printers?.find((p) => p.type === PrinterType.Generic)?.id;

    //     const variables: IDownloadPackSlipVariables = { saleId: data.saleFulfillment.sale.id };

    //     if (download) {
    //         const result = await runDownloadPackSlipMutation({ variables: variables });
    //         const packSlipData = result.data?.api?.print?.sale?.download;
    //         if (!packSlipData) return alert("Error downloading pack slip data");
    //         const byteArray = getByteArray(packSlipData);
    //         var file = new Blob([byteArray], { type: "application/pdf;base64" });
    //         var fileURL = URL.createObjectURL(file);
    //         var fileLink = document.createElement(`a`);
    //         fileLink.href = fileURL;
    //         fileLink.download = `Pack_Slip`;
    //         fileLink.click();
    //         window.open(fileURL);
    //     } else {
    //         if (data.station.stationSet.printers.length < 1) return alert("There are no printers at this station");

    //         const selectedPackingSlipPrinterId =
    //             defaultPackingSlipPrinterId ??
    //             (await (
    //                 await showSelectModal(
    //                     "Select Label Printer",
    //                     data.station.stationSet.printers.map((p) => ({ id: p.id, description: p.description } as ISelect)),
    //                     defaultPackingSlipPrinterId
    //                 )
    //             )?.id);
    //         if (!selectedPackingSlipPrinterId) return;

    //         const printVariables: IPrintPackSlipVariables = { ...variables, printerId: selectedPackingSlipPrinterId };

    //         await runPrintPackSlipMutation({ variables: printVariables });
    //     }
    // };

    const confirm = async (weight: number | null, download: boolean) => {
        if (loading || mutationLoading) return;
        if (!data || !data.station) return alert("Data is null");
        if (!data.station) return alert("Station data is null");
        if (data.station.stationSet.printers.length < 1) return alert("There are no printers at this station");
        if (!data.saleFulfillment) return alert("Sale fulfillment data is null");
        if (data.labelProviders.items.length < 1) return alert(`Error: No label providers found`);
        if (!urlSearchParams.activeSaleFulfillmentId) return alert(`No sale fulfillment id provided.`);

        const singleItemFulfillment = data.saleFulfillment.lineItems.reduce((acc, val) => acc + val.packageQuantity, 0) === 1;
        if (!singleItemFulfillment && (!weight || +weight.toFixed(2) <= 0)) return;

        if (singleItemFulfillment) {
            var singleItem = data.saleFulfillment.lineItems[0].saleLineItem.product;
            if (
                !data.saleFulfillment.packageId &&
                singleItem.packageType === PackageType.Prepackaged &&
                !singleItem.storageWeight &&
                (!weight || +weight.toFixed(2) <= 0)
            )
                return;
        }

        const labelProvider = data.labelProviders.items.find((l) => l.name === "ShipStation");
        if (!labelProvider) return alert(`Error: No label provider with the name of 'ShipStation' found`);

        const defaultShippingLabelPrinterId = data?.station?.stationSet?.printers?.find((p) => p.type === PrinterType.Label)?.id;

        setMutationLoading(true);
        try {
            const mutationVariables: IDownloadShippingLabelMutationVariables = {
                labelProviderId: labelProvider.id,
                saleFulfillmentId: urlSearchParams.activeSaleFulfillmentId,
                shippingServiceId: data.saleFulfillment.sale.shippingServiceId,
                weight: weight ? +weight.toFixed(2) : null,
            };

            if (
                download &&
                !window.confirm(
                    "Are you sure you want to download the packing slip and shipping label? This will cause the fulfillment to be set as completed."
                )
            ) {
                return;
            }

            //await getPackSlip(download);

            let lastPrintedProperties: ILastPrintedProperties | undefined = undefined;

            if (download) {
                const result = await runDownloadShippingLabelMutation({ variables: mutationVariables });
                const shippingLabelData = result.data.api.shippingLabel.generateShippingLabel.download;
                const byteArray = getByteArray(shippingLabelData);
                var file = new Blob([byteArray], { type: "application/pdf;base64" });
                var fileURL = URL.createObjectURL(file);
                var fileLink = document.createElement(`a`);
                fileLink.href = fileURL;
                fileLink.download = `Shipping_Label`;
                fileLink.click();
                window.open(fileURL);
            } else {
                if (data.station.stationSet.printers.length < 1) return alert("There are no printers at this station");

                const selectedShippingLabelPrinterId =
                    defaultShippingLabelPrinterId ??
                    (await (
                        await showSelectModal(
                            "Select Label Printer",
                            data.station.stationSet.printers.map((p) => ({ id: p.id, description: p.description } as ISelect)),
                            defaultShippingLabelPrinterId
                        )
                    )?.id);
                if (!selectedShippingLabelPrinterId) return;

                const printMutationVariables: IGenerateShippingLabelMutationVariables = {
                    ...mutationVariables,
                    printerId: selectedShippingLabelPrinterId,
                };

                lastPrintedProperties = {
                    printerId: selectedShippingLabelPrinterId,
                    saleFulfillmentId: urlSearchParams.activeSaleFulfillmentId,
                };

                if (reprintShippingLabel)
                    await runReprintShippingLabelMutation({
                        variables: {
                            printerId: selectedShippingLabelPrinterId,
                            saleFulfillmentId: urlSearchParams.activeSaleFulfillmentId,
                        },
                    });
                else await runGenerateShippingLabelMutation({ variables: printMutationVariables });
            }

            if (!data.mobile.getOpenSaleFulfillmentState.doItemsRemain) {
                //this is run if no more items remain to be packed for an order
                await runChangeSaleStatusMutation({
                    variables: {
                        newSaleStatus: SaleStatus.Shipped,
                        oldSaleStatus: data.saleFulfillment.sale.status,
                        saleId: data.saleFulfillment.sale.id,
                    },
                });
            }

            //alert(`Label has been sent to printer '${printer.description}'`);
            history.replace(
                `/mobile/pack/${params.stationId}/${params.locationId}?${forgeURLSearchParams({
                    activeSaleId: urlSearchParams.activeSaleId,
                    autoPrintShippingLabels: urlSearchParams.autoPrintShippingLabels,
                    lastPrintedProperties: lastPrintedProperties,
                })}`
            );
        } catch (error: any) {
            const e = error as GraphQLError;
            let message = e?.message ?? "Unknown error";
            if (message.includes("No route to host") || message.includes("Timeout executing action")) {
                message += ". Please check that the printer is on.";
            }
            if (message.toLowerCase().includes("cannot generate another shipping label")) {
                setReprintShippingLabel(true);
            }
            alert(`SO-${data.saleFulfillment.sale.id}: ${message}`);
        } finally {
            setMutationLoading(false);
        }
    };

    useEffect(() => {
        (async () => {
            if (!data || !data.saleFulfillment) return;
            if (data.saleFulfillment.lineItems.reduce((acc, val) => acc + val.packageQuantity, 0) === 1) {
                //if there's a packageId, just immediately confirm
                if (data.saleFulfillment.packageId) {
                    await confirm(null, false);
                    return;
                }
                const singleProduct = data.saleFulfillment.lineItems[0].saleLineItem.product;
                //if the item is prepackaged, ensure that it has storageWeight
                if (singleProduct.packageType === PackageType.Prepackaged && singleProduct.storageWeight) {
                    await confirm(null, false);
                    return;
                }
            }
            const suggestedWeight = data.saleFulfillment.lineItems
                .filter((x) => x.saleLineItem.product.type === ProductType.Product)
                .reduce(
                    (accumulator, value) =>
                        accumulator + +((value.saleLineItem.product.shippingWeight ?? 0) * value.packageQuantity).toFixed(2),
                    0
                );
            setPackageWeight(suggestedWeight);
            const doAllItemsInPackageHaveAShippingWeightValue = data.saleFulfillment.lineItems.every(
                (li) => (li.saleLineItem.product.shippingWeight ?? 0) > 0
            );
            if (suggestedWeight > 0 && doAllItemsInPackageHaveAShippingWeightValue && urlSearchParams.autoPrintShippingLabels) {
                await confirm(suggestedWeight, false);
            } else {
                if (!doAllItemsInPackageHaveAShippingWeightValue) {
                    //get all skus that do not have a shipping weight value
                    const skusWithoutWeight = data.saleFulfillment.lineItems
                        .filter((li) => (li.saleLineItem.product.shippingWeight ?? 0) === 0)
                        .map((li) => li.saleLineItem.product.sku);
                    alert(
                        `Auto print disabled! The following SKUs do not have a shipping box weight value: ${skusWithoutWeight.join(", ")}`
                    );
                }
            }
            //setDefaultShippingLabelPrinterId(data?.station?.stationSet?.printers?.find((p) => p.type === PrinterType.Label)?.id);
            //setDefaultPackingSlipPrinterId(data?.station?.stationSet?.printers?.find((p) => p.type === PrinterType.Generic)?.id);
        })();
    }, [data]); // eslint-disable-line react-hooks/exhaustive-deps

    if (!params.stationId || !urlSearchParams.activeSaleFulfillmentId) return <Redirect to="/mobile/selectrole" />;

    if (!loading) {
        if (!data || error) {
            alert(`Error: ${error?.message ?? "Unknown error returned from query."}`);
            return <Redirect to="/mobile/pack/scanstation" />;
        }
        if (!data.saleFulfillment) {
            alert(`Error: Sale fulfillment data returned null for sale fulfillment id "${urlSearchParams.activeSaleFulfillmentId}"`);
            return <Redirect to="/mobile/pack/scanstation" />;
        }
        if (!data.station) {
            alert(`Error: Station data returned null for station id "${params.stationId}"`);
            return <Redirect to="/mobile/pack/scanstation" />;
        }
        if (data.saleFulfillment.status !== SaleFulfillmentStatus.InProgress) {
            alert(
                `Error: Could not find any "In Progress" sale fulfillments matching sale fulfillment id "${urlSearchParams.activeSaleFulfillmentId}"`
            );
            return <Redirect to="/mobile/pack/scanstation" />;
        }
        if (data.labelProviders.items.length < 1) {
            alert(`Error: No label providers found`);
            return <Redirect to="/mobile/pack/scanstation" />;
        }
    }

    const getByteArray = (data: string): Uint8Array => {
        var byteCharacters = atob(data);
        var byteNumbers = new Array(byteCharacters.length);
        for (var i = 0; i < byteCharacters.length; i++) {
            byteNumbers[i] = byteCharacters.charCodeAt(i);
        }
        return new Uint8Array(byteNumbers);
    };

    // const changePrinters = async () => {
    //     if (loading || mutationLoading) return;
    //     setShowChangePrinterModal(true);
    // };

    const addTrackingNumber = async () => {
        if (!data || !data.station) return alert("Data is null");
        if (!data.station) return alert("Station data is null");
        if (!data.saleFulfillment) return alert("Sale fulfillment data is null");
        if (!urlSearchParams.activeSaleId) return alert("No sale id found");
        if (!urlSearchParams.activeSaleFulfillmentId) return alert(`No sale fulfillment id provided.`);
        if (+packageWeight.toFixed(2) <= 0) return;
        if (loading || mutationLoading) return;

        try {
            setMutationLoading(true);
            //get sale
            const saleResult = await runSaleQuery({ variables: { saleId: urlSearchParams.activeSaleId } });
            const saleData = saleResult.data.sale;
            const r = await showSpecialModal(
                "Add Tracking Number",
                { elementTitle: "Tracking Number" },
                {
                    elementTitle: "Shipping Service (Override Sale)",
                    values: data.shippingServices.items
                        .filter((x) => x.active)
                        .sort((a, b) => a.name.localeCompare(b.name))
                        .map((x) => ({ description: `${x.shippingCarrier.name} - ${x.name}`, id: x.id })),
                    defaultValue: saleData.shippingService?.id ?? undefined,
                }
            );
            if (!r) return;
            //create original input model
            const originalFulfillments: ISaleFulfillmentInputModel[] = saleData.fulfillments.map((fulfillment) => {
                return {
                    id: fulfillment.id,
                    status: fulfillment.status,
                    trackingNumber: fulfillment.trackingNumber,
                    hasInsurance: fulfillment.hasInsurance,
                    packageNumber: fulfillment.packageNumber,
                    weight: fulfillment.weight,
                    shipDate: fulfillment.shipDate,
                    shippingServiceId: fulfillment.shippingServiceId,
                };
            });
            const original: ISaleInputModel = {
                id: saleData.id,
                status: saleData.status,
                dropShip: saleData.dropShip,
                subtotal: saleData.subtotal,
                tax: saleData.tax,
                total: saleData.total,
                saleFulfillments: originalFulfillments,
            };
            let modifiedFulfillments: ISaleFulfillmentInputModel[] = saleData.fulfillments.map((fulfillment) => {
                return {
                    id: fulfillment.id,
                    status: SaleFulfillmentStatus.Shipped,
                    trackingNumber: fulfillment.trackingNumber,
                    hasInsurance: fulfillment.hasInsurance,
                    packageNumber: fulfillment.packageNumber,
                    weight: +packageWeight.toFixed(2),
                    shipDate: new Date().toISOString(),
                    shippingServiceId: r.selectValue,
                };
            });
            //find the fulfillment that matches the current sale fulfillment id
            let activeFulfillment = modifiedFulfillments.find((f) => f.id === urlSearchParams.activeSaleFulfillmentId);
            if (!activeFulfillment) return alert(`Could not find a matching sale salefulfillment for sale id ${saleData.id}`);
            activeFulfillment.trackingNumber = r.inputValue;
            //create modified input model
            const modified: ISaleInputModel = {
                id: saleData.id,
                dropShip: saleData.dropShip,
                status: saleData.status,
                subtotal: saleData.subtotal,
                tax: saleData.tax,
                total: saleData.total,
                saleFulfillments: modifiedFulfillments,
            };

            //run mutation
            await runEditSaleMutation({ variables: { original, modified } });

            if (!data.mobile.getOpenSaleFulfillmentState.doItemsRemain) {
                //this is run if no more items remain to be packed for an order
                await runChangeSaleStatusMutation({
                    variables: {
                        newSaleStatus: SaleStatus.Shipped,
                        oldSaleStatus: data.saleFulfillment.sale.status,
                        saleId: data.saleFulfillment.sale.id,
                    },
                });
            }

            history.replace(
                `/mobile/pack/${params.stationId}/${params.locationId}?${forgeURLSearchParams({
                    activeSaleId: urlSearchParams.activeSaleId,
                    autoPrintShippingLabels: urlSearchParams.autoPrintShippingLabels,
                    lastPrintedProperties: urlSearchParams.lastPrintedProperties,
                })}`
            );
        } catch (error: any) {
            const e = error as GraphQLError;
            alert(`SO-${data.saleFulfillment.sale.id}: ` + (e?.message ?? "Unknown error"));
        } finally {
            setMutationLoading(false);
        }
    };

    const baseButtonStyle: CSSProperties = {
        backgroundColor: "#EDF0F4",
        boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.55)",
        borderRadius: 4,
        border: "1px",
        fontWeight: "bold",
    };
    const weightButtonWidth = 48;
    const weightButtonStyle: CSSProperties = { ...baseButtonStyle, width: weightButtonWidth, height: 36 };
    const confirmButtonStyle: CSSProperties = { ...baseButtonStyle, height: 36, width: "100%" };
    const fadedButtonStyle: CSSProperties = { ...confirmButtonStyle, color: "#6D6D84" };
    // const modalButtonStyle: CSSProperties = {
    //     flex: 1,
    //     height: 38,
    //     backgroundColor: "white",
    //     boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.55)",
    //     borderRadius: 4,
    //     border: "1px",
    //     fontWeight: 500,
    // };

    return (
        <>
            {loading || mutationLoading ? <MobileLoading fullscreen /> : <></>}

            <PrimaryHeader
                title={data?.station?.stationSet.name ?? ""}
                includeBackButton
                backButtonText="< Station"
                customBackButtonPath={`/mobile/pack/${params.stationId}/${params.locationId}?${forgeURLSearchParams({
                    ...urlSearchParams,
                })}`}
                includeHomeButton
            />

            <SecondaryHeader title="Confirm Weight" nextButtonHandler={() => confirm(packageWeight, false)} />

            <div style={{ margin: "0 15px", display: "flex", justifyContent: "center" }}>
                <div style={{ marginTop: 30, width: "100%", display: "flex", flexDirection: "column" }}>
                    <div style={{ display: "flex", justifyContent: "space-between", height: 120 }}>
                        <div style={{ width: 48, display: "flex", flexDirection: "column", justifyContent: "space-between" }}>
                            <MobileButton allowLongPress onClick={incrementWhole} style={weightButtonStyle}>
                                +
                            </MobileButton>
                            <MobileButton allowLongPress onClick={decrementWhole} style={weightButtonStyle}>
                                -
                            </MobileButton>
                        </div>
                        <div
                            style={{
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "center",
                                width: "100%",
                                flexDirection: "column",
                                position: "relative",
                            }}
                        >
                            <button
                                onClick={() =>
                                    setDecimalMode(decimalMode === EDecimalMode.Tenths ? EDecimalMode.Hundredths : EDecimalMode.Tenths)
                                }
                                style={{ ...baseButtonStyle, width: 100, fontWeight: "normal", height: 25, position: "absolute", top: 0 }}
                            >
                                {EDecimalMode[decimalMode]}
                            </button>
                            <div
                                style={{
                                    fontSize: 48,
                                    fontWeight: 700,
                                }}
                            >
                                {packageWeight.toFixed(2)} lbs
                            </div>
                        </div>
                        <div style={{ width: 48, display: "flex", flexDirection: "column", justifyContent: "space-between" }}>
                            <MobileButton
                                allowLongPress
                                onClick={() => (decimalMode === EDecimalMode.Tenths ? incrementTenth() : incrementHundredth())}
                                style={weightButtonStyle}
                            >
                                +
                            </MobileButton>
                            <MobileButton
                                allowLongPress
                                onClick={() => (decimalMode === EDecimalMode.Tenths ? decrementTenth() : decrementHundredth())}
                                style={weightButtonStyle}
                            >
                                -
                            </MobileButton>
                        </div>
                    </div>

                    <div style={{ height: 56, width: "100%", display: "flex", alignItems: "end" }}>
                        <button
                            disabled={+packageWeight.toFixed(2) <= 0}
                            style={confirmButtonStyle}
                            onClick={() => confirm(packageWeight, false)}
                        >
                            {reprintShippingLabel ? "Reprint Shipping Label" : "Confirm"}
                        </button>
                    </div>
                    {/* <div style={{ height: 56, width: "100%", display: "flex", alignItems: "end" }}>
                        <button style={confirmButtonStyle} onClick={async () => await changePrinters()}>{`Change Printers`}</button>
                    </div> */}
                    <div style={{ height: 56, width: "100%", display: "flex", alignItems: "end" }}>
                        <button
                            disabled={+packageWeight.toFixed(2) <= 0}
                            style={confirmButtonStyle}
                            onClick={async () => await addTrackingNumber()}
                        >{`Add Tracking Number`}</button>
                    </div>
                    <div style={{ height: 56, width: "100%", display: "flex", alignItems: "end" }}>
                        <button
                            style={downloadButtonClickCount < 3 ? fadedButtonStyle : confirmButtonStyle}
                            onClick={async () =>
                                downloadButtonClickCount < 3
                                    ? setDownloadButtonClickCount(downloadButtonClickCount + 1)
                                    : await confirm(packageWeight, true)
                            }
                        >{`Download`}</button>
                    </div>
                    <div style={{ height: 56, width: "100%", display: "flex", alignItems: "center" }}>
                        {loading ? (
                            <div>Loading selected shipping service...</div>
                        ) : data?.saleFulfillment?.sale?.shippingService ? (
                            <>
                                <b>Shipping Service:</b>&nbsp;{data?.saleFulfillment?.sale?.shippingService?.shippingCarrier?.name} -{" "}
                                {data?.saleFulfillment?.sale?.shippingService?.name}
                            </>
                        ) : (
                            "No 'Shipping Service' selected"
                        )}
                    </div>
                </div>
            </div>

            {/* <Modal
                show={showChangePrinterModal}
                onHide={() => {
                    setShowChangePrinterModal(false);
                    setDefaultPackingSlipPrinterId(defaultPackingSlipPrinterId);
                    setDefaultShippingLabelPrinterId(defaultShippingLabelPrinterId);
                }}
            >
                <Modal.Header closeButton style={{ color: "#CC0A3B", fontWeight: "bold" }}>
                    *Selections save on click!
                </Modal.Header>
                <Modal.Body>
                    <form>
                        <div style={{ fontSize: 16, fontWeight: "bold", marginBottom: 10 }}>Shipping Label Printer</div>
                        <MobileRadioContainer>
                            {data?.station?.stationSet?.printers?.map((p) => {
                                const defaultChecked = p.id === defaultShippingLabelPrinterId;
                                return (
                                    <MobileRadio
                                        defaultChecked={defaultChecked}
                                        id={p.id}
                                        key={p.id}
                                        label={p.description}
                                        name={"shippingLabelPrinter"}
                                        onClick={() => setDefaultShippingLabelPrinterId(p.id)}
                                        value={p.id}
                                    />
                                );
                            })}
                        </MobileRadioContainer>
                        <hr />
                        <div style={{ fontSize: 16, fontWeight: "bold", marginBottom: 10 }}>Packing Slip Printer</div>
                        <MobileRadioContainer>
                            {data?.station?.stationSet?.printers?.map((p) => {
                                const defaultChecked = p.id === defaultPackingSlipPrinterId;
                                return (
                                    <MobileRadio
                                        defaultChecked={defaultChecked}
                                        id={p.id}
                                        key={p.id}
                                        label={p.description}
                                        name={"packingSlipPrinter"}
                                        onClick={() => setDefaultPackingSlipPrinterId(p.id)}
                                        value={p.id}
                                    />
                                );
                            })}
                        </MobileRadioContainer>
                    </form>
                </Modal.Body>
                <Modal.Footer style={{ display: "flex" }}>
                    <button
                        style={{ ...modalButtonStyle, backgroundColor: "#0062CC", color: "white" }}
                        onClick={() => setShowChangePrinterModal(false)}
                    >
                        Confirm
                    </button>
                </Modal.Footer>
            </Modal> */}

            {createElement(selectModal)}

            {specialModal}
        </>
    );
};

export default PrintLabel;
