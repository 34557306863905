import { useContext } from "react";
import { useHistory } from "react-router";
import ErrorDisplay from "../../../../components/misc/ErrorDisplay";
import StationSetType from "../../../../enums/StationSetTypes";
import StationType from "../../../../enums/StationTypes";
import { useQuery } from "@shane32/graphql";
import MobileButton from "../../components/buttons/MobileButton";
import MobileButtonContainer from "../../components/buttons/MobileButtonContainer";
import MobileLoading from "../../components/MobileLoading";
import PrimaryHeader from "../../components/PrimaryHeader";
import SecondaryHeader from "../../components/SecondaryHeader";
import MobileTable from "../../components/tables/MobileTable";
import MobileTableTitle from "../../components/tables/MobileTableTitle";
import ScannerToneContext from "../../contexts/ScannerToneContext";
import useScanner from "../../hooks/useScanner";
import { findStationsQuery, IFindStationsQueryResult } from "../../models/graphql/find_stations/FindStations";
import { useSettingsValue } from "../../../../hooks/useSettingsValue";

interface IStationWithOrderAndCartQuantity {
    id: string;
    name: string;
    numberOfOrdersAtStation: number;
    numberOfCartsAtStation: number;
}

const ScanStation = () => {
    const history = useHistory();
    const { playPositiveTone, playNegativeTone } = useContext(ScannerToneContext);
    const {
        settingsValue: defaultWarehouseId,
        error: defaultWarehouseIdError,
        loading: defaultWarehouseIdLoading,
        refetch: defaultWarehouseIdRefetch,
    } = useSettingsValue("defaultWarehouseId");
    const {
        data: findStationsData,
        error: findStationsError,
        refetch: findStationsRefetch,
        loading,
    } = useQuery<IFindStationsQueryResult, {}>(findStationsQuery, {
        fetchPolicy: "no-cache",
        variables: !defaultWarehouseId
            ? undefined
            : {
                  warehouseId: defaultWarehouseId,
                  stationSetType: StationSetType.Pack,
                  stationType: StationType.Input,
              },
        skip: !defaultWarehouseId,
    });

    const isLoading = [loading, defaultWarehouseIdLoading].some((x) => x === true);

    const onScan = (scannerValue: string) => {
        const scanner = { scannerValue: scannerValue };
        if (!isLoading) {
            //find the matching selected station
            const matchingStation = findStationsData?.mobile.findStations.find(
                (station) => station.stationSet.name === scanner.scannerValue
            );
            if (matchingStation === undefined) {
                playNegativeTone();
                return alert(`'${scanner.scannerValue}' is not a valid station`);
            }
            playPositiveTone();
            history.push(`/mobile/pack/${matchingStation.id}/scanlocation`);
        }
    };
    useScanner(onScan);

    if (findStationsError) return <ErrorDisplay onClick={findStationsRefetch}>{findStationsError.message}</ErrorDisplay>;
    if (defaultWarehouseIdError) return <ErrorDisplay onClick={defaultWarehouseIdRefetch}>{defaultWarehouseIdError.message}</ErrorDisplay>;

    let stations =
        findStationsData?.mobile?.findStations?.map((station) => {
            //gets all distinct order ids at a station
            const stationCartsLocations = station.carts.flatMap((x) => x.locations);
            const stashPickZoneLocations = station.stationSet.stashPickZone.locations.map((x) => x);
            const allLocationsAtStation = stationCartsLocations.concat(stashPickZoneLocations);
            const productsAtStation = allLocationsAtStation.flatMap((x) => x.products);
            const distinctOrderIdsAtStation = new Set<string>();
            productsAtStation.forEach((x) => {
                distinctOrderIdsAtStation.add(x.saleId);
            });

            //gets the total number of products at a station
            //const itemsAtStationTotal = station.carts.flatMap(cart => cart.locations.flatMap(location => location.products))
            //    .reduce((a, b) => a + b.stockOnHand, 0);

            return {
                id: station.id,
                name: station.stationSet.name,
                numberOfCartsAtStation: station.carts.filter((cart) => cart.isCart).length,
                numberOfOrdersAtStation: distinctOrderIdsAtStation.size,
            } as IStationWithOrderAndCartQuantity;
        }) ?? [];

    stations.sort(
        (a, b) =>
            b.numberOfCartsAtStation - a.numberOfCartsAtStation ||
            b.numberOfOrdersAtStation - a.numberOfOrdersAtStation ||
            a.name.localeCompare(b.name)
    );

    return (
        <>
            {isLoading ? <MobileLoading fullscreen /> : <></>}

            <PrimaryHeader
                title="Stations"
                includeHomeButton
                includeBackButton
                backButtonText="< Roles"
                customBackButtonPath="/mobile/selectrole"
            />

            <SecondaryHeader title="║▌Scan Station" nextButtonHandler={() => void 0} />

            {isLoading ? (
                <></>
            ) : stations.length < 1 ? (
                <MobileTableTitle>🎉 No station with orders! 🎉</MobileTableTitle>
            ) : (
                <MobileTable>
                    <thead>
                        <tr>
                            <td>Station</td>
                            <td>Order Qty</td>
                            <td>Cart Qty</td>
                        </tr>
                    </thead>
                    <tbody>
                        {stations.map((station) => {
                            return (
                                <tr key={station.id} onClick={() => history.push(`/mobile/pack/${station.id}/scanlocation`)}>
                                    <td>{station.name}</td>
                                    <td>{station.numberOfOrdersAtStation}</td>
                                    <td>{station.numberOfCartsAtStation}</td>
                                </tr>
                            );
                        })}
                    </tbody>
                </MobileTable>
            )}

            <MobileButtonContainer>
                <MobileButton onClick={() => history.push("/mobile/admin/pack/findopensalefulfillments")}>
                    Find Open Sale Fulfillments
                </MobileButton>
            </MobileButtonContainer>
        </>
    );
};

export default ScanStation;
