import { Redirect, useHistory, useLocation, useParams } from "react-router-dom";
import ErrorDisplay from "../../../../components/misc/ErrorDisplay";
import { useQuery } from "@shane32/graphql";
import MobileLoading from "../../components/MobileLoading";
import PrimaryHeader from "../../components/PrimaryHeader";
import SecondaryHeader from "../../components/SecondaryHeader";
import AddOrPrintBarcode from "../../components/add_or_print_barcode/AddOrPrintBarcode";
import AddProductToPurchaseInvoiceButton from "../../components/buttons/AddProductToPurchaseInvoiceButton";

const purchaseInvoiceQuery = `
query ($purchaseInvoiceId: ID!) {
  purchaseInvoice(id: $purchaseInvoiceId) {
    invoiceNumber
  }
}
`;

interface IPurchaseInvoiceQueryResult {
    purchaseInvoice: {
        invoiceNumber: string;
    };
}

interface IPurchaseInvoiceQueryVariables {
    purchaseInvoiceId: string;
}

interface IRouteParams {
    purchaseOrderId: string;
    purchaseInvoiceId: string;
    productId: string;
}

const AddOrPrintBarcodeContainer = () => {
    const history = useHistory();
    const { search: searchQueryString } = useLocation();
    const params = useParams<IRouteParams>();
    const { loading, data, refetch, error } = useQuery<IPurchaseInvoiceQueryResult, IPurchaseInvoiceQueryVariables>(purchaseInvoiceQuery, {
        fetchPolicy: "no-cache",
        variables: { purchaseInvoiceId: params.purchaseInvoiceId },
    });
    const urlSearchParams = new URLSearchParams(searchQueryString);
    const addToHand = urlSearchParams.get("addToHand");
    const supplierId = urlSearchParams.get("supplierId");

    if (error || (!loading && !data))
        return <ErrorDisplay onClick={refetch}>{error?.message ?? "Fetching data for product failed"}</ErrorDisplay>;

    if (!params.purchaseOrderId) return <Redirect to={`/mobile/intake/selectpurchaseorder`} />;

    if (!params.purchaseInvoiceId) return <Redirect to={`/mobile/intake/${params.purchaseOrderId}/selectinvoice`} />;

    if (!params.productId) return <Redirect to={`/mobile/intake/${params.purchaseOrderId}/${params.purchaseInvoiceId}/scanbarcode`} />;

    const isNewItem = params.productId === "0";

    return (
        <>
            {loading ? <MobileLoading fullscreen /> : <></>}

            <PrimaryHeader
                title={`Invoice ${data?.purchaseInvoice?.invoiceNumber ?? ""}`}
                includeBackButton
                backButtonText="< Scan Barcode"
                customBackButtonPath={`/mobile/intake/${params.purchaseOrderId}/${params.purchaseInvoiceId}/scanbarcode?partId=${
                    params.productId
                }${addToHand ? `&addToHand=true` : ""}${supplierId ? `&supplierId=${supplierId}` : ""}`}
                includeHomeButton
            />

            <SecondaryHeader title="Scan Barcode" nextButtonHandler={() => void 0} />

            {isNewItem ? (
                <AddProductToPurchaseInvoiceButton
                    purchaseInvoiceId={params.purchaseInvoiceId}
                    purchaseInvoiceNumber={data?.purchaseInvoice?.invoiceNumber ?? ""}
                    addOrPrintBarcodeRedirectFunc={(productId: string) =>
                        history.replace(
                            `/mobile/intake/${params.purchaseOrderId}/${params.purchaseInvoiceId}/${productId}/addorprintbarcode?${
                                addToHand ? `addToHand=true` : ""
                            }
                            ${supplierId ? `&supplierId=${supplierId}` : ""}
                            `
                        )
                    }
                />
            ) : (
                <AddOrPrintBarcode
                    productId={params.productId}
                    onBarcodeSaveAction={() =>
                        history.replace(
                            `/mobile/intake/${params.purchaseOrderId}/${params.purchaseInvoiceId}/scanbarcode?partId=${params.productId}
                            ${addToHand ? `&addToHand=true` : ""}
                            ${supplierId ? `&supplierId=${supplierId}` : ""}
                            `
                        )
                    }
                />
            )}
        </>
    );
};

export default AddOrPrintBarcodeContainer;
