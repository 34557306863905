import { createElement, useState, useContext, useRef, CSSProperties } from "react";
import { GraphQLError, useMutation, useQuery } from "@shane32/graphql";
import { Modal } from "react-bootstrap";
import BarcodeLabelType from "../../../../enums/BarcodeLabelType";
import DropShipMode from "../../../../enums/DropShipMode";
import PrinterType from "../../../../enums/PrinterType";
import ProductType from "../../../../enums/ProductType";
import StationSetType from "../../../../enums/StationSetTypes";
import MobileButton from "../buttons/MobileButton";
import MobileButtonCol from "../buttons/MobileButtonCol";
import MobileButtonContainer from "../buttons/MobileButtonContainer";
import MobileButtonRow from "../buttons/MobileButtonRow";
import MobileTable from "../tables/MobileTable";
import MobileTableTitle from "../tables/MobileTableTitle";
import useConfirm from "../../hooks/useConfirm";
import useSelectModal, { ISelect } from "../../hooks/useSelectModal";
import ScannerToneContext from "../../contexts/ScannerToneContext";
import useScanner from "../../hooks/useScanner";
import ErrorDisplay from "../../../../components/misc/ErrorDisplay";
import printerSVG from "../../../../images/printer.svg";
import trashSVG from "../../../../images/trash.svg";
import MobileLoading from "../MobileLoading";
import styles from "./AddOrPrintBarcode.module.scss";
import ActiveProductImages from "../images/ActiveProductImages";
import PrimaryHeader from "../PrimaryHeader";
import SecondaryHeader from "../SecondaryHeader";

const productQueryBase = `
    id
    type
    sku
    unitOfMeasureId
    description
    dropShipMode
    active
    partNumbers {
        partNumber
        sortOrder
    }
    barcodes {
      sortOrder
      barcode
    }
`;

const editProductBarcodesMutation = `
mutation ($original: ProductInput!, $modified: ProductInput!) {
  product {
    edit(original: $original, modified: $modified) {
      ${productQueryBase}
    }
  }
}
`;

interface IBarcode {
    sortOrder: number;
    barcode: string;
}

interface IProductInputModel {
    id: string;
    type: ProductType;
    sku: string | null;
    unitOfMeasureId: string;
    description: string;
    dropShipMode: DropShipMode;
    active: boolean;
    barcodes: IBarcode[];
}

interface IEditProductBarcodesMutationVariables {
    original: IProductInputModel;
    modified: IProductInputModel;
}

interface IEditProductBarcodesMutationResult {
    product: {
        edit: IProduct;
    };
}

const printersQuery = `
query {
  printers {
    items {
      id
      description
      type
      stationSet {
        name
        type
      }
    }
  }
}
`;

interface IPrintersQueryVariables {}

interface IPrintersQueryResult {
    printers: {
        items: Array<{
            id: string;
            description: string;
            type: PrinterType;
            stationSet: {
                name: string;
                type: StationSetType;
            } | null;
        }>;
    };
}

const productQuery = `
query ($productId: ID!) {
  product(id: $productId) {
    ${productQueryBase}
  }
}
`;

interface IPartNumber {
    partNumber: string;
    sortOrder: number;
}

interface IProduct {
    id: string;
    type: ProductType;
    sku: string | null;
    unitOfMeasureId: string;
    description: string;
    dropShipMode: DropShipMode;
    active: boolean;
    partNumbers: Array<IPartNumber>;
    barcodes: Array<IBarcode>;
}

interface IProductQueryResult {
    product: IProduct;
}

interface IProductQueryVariables {
    productId: string;
}

const barcodeLabelMutation = `
mutation ($barcodeLabel: String!, $barcodeLabelType: BarcodeLabelType!, $printerId: ID!) {
  api {
    print {
      barcodeLabel(barcodeLabel: $barcodeLabel, barcodeLabelType: $barcodeLabelType) {
        print(printerId: $printerId)
      }
    }
  }
}
`;

interface IBarcodeLabelMutationVariables {
    barcodeLabel: string;
    barcodeLabelType: BarcodeLabelType;
    printerId: string;
}

enum EBarcodeEditType {
    Add = "ADD",
    Delete = "DELETE",
}

interface IProps {
    productId: string;
    onBarcodeSaveAction?: () => void;
    primaryHeader?: React.ReactElement<typeof PrimaryHeader>;
    includeSecondaryHeader?: boolean;
}

const AddOrPrintBarcode = (props: IProps) => {
    const [showBarcodeModal, setShowBarcodeModal] = useState(false);
    const [manualLoading, setManualLoading] = useState(false);
    const barcodeInputRef = useRef<HTMLInputElement>(null);
    //const partNumbersQuantityInputRef = useRef<HTMLInputElement>(null);
    const skusQuantityInputRef = useRef<HTMLInputElement>(null);
    //const barcodesQuantityInputRef = useRef<HTMLInputElement>(null);
    const { loading, data, refetch, error } = useQuery<IProductQueryResult, IProductQueryVariables>(productQuery, {
        fetchPolicy: "no-cache",
        variables: { productId: props.productId },
    });
    const { loading: printersLoading, data: printersData } = useQuery<IPrintersQueryResult, IPrintersQueryVariables>(printersQuery, {
        fetchPolicy: "no-cache",
    });
    const [runEditProductBarcodesMutation] = useMutation<IEditProductBarcodesMutationResult, IEditProductBarcodesMutationVariables>(
        editProductBarcodesMutation
    );
    const [runBarcodeLabelMutation] = useMutation<{}, IBarcodeLabelMutationVariables>(barcodeLabelMutation);
    const { confirmModal, showConfirmModal } = useConfirm();
    const { selectModal, showSelectModal } = useSelectModal();
    const { playPositiveTone, playNegativeTone } = useContext(ScannerToneContext);
    const onScan = (scannerValue: string) => {
        if (loading || manualLoading) return;
        if (scannerValue) {
            playPositiveTone();
            setShowBarcodeModal(true);
            setBarcode(scannerValue);
        } else {
            playNegativeTone();
        }
    };
    useScanner(onScan);

    if (error || (!loading && !data))
        return <ErrorDisplay onClick={refetch}>{error?.message ?? "Fetching data for product failed"}</ErrorDisplay>;

    const setBarcode = (barcode: string) => {
        if (!barcodeInputRef?.current) return;
        barcodeInputRef.current.value = barcode;
    };

    const editBarcodes = async (barcode: string | undefined, barcodeEditType: EBarcodeEditType) => {
        try {
            if (loading || manualLoading || printersLoading) return;
            setManualLoading(true);
            if (!barcode || !data) return;

            const newBarcodes = (() => {
                switch (barcodeEditType) {
                    case EBarcodeEditType.Add:
                        var oldHighestSortOrder = data.product.barcodes.sort((a, b) => b.sortOrder - a.sortOrder).find((x) => x)?.sortOrder;
                        var newHighestSortOrder = oldHighestSortOrder ? oldHighestSortOrder + 1 : 0;
                        return data.product.barcodes.concat([{ barcode: barcode, sortOrder: newHighestSortOrder } as IBarcode]);
                    case EBarcodeEditType.Delete:
                        return data.product.barcodes.filter((x) => x.barcode !== barcode);
                }
            })();

            const response = await runEditProductBarcodesMutation({
                variables: {
                    original: {
                        active: data.product.active,
                        barcodes: data.product.barcodes,
                        description: data.product.description,
                        dropShipMode: data.product.dropShipMode,
                        id: data.product.id,
                        sku: data.product.sku,
                        type: data.product.type,
                        unitOfMeasureId: data.product.unitOfMeasureId,
                    },
                    modified: {
                        active: data.product.active,
                        barcodes: newBarcodes,
                        description: data.product.description,
                        dropShipMode: data.product.dropShipMode,
                        id: data.product.id,
                        sku: data.product.sku,
                        type: data.product.type,
                        unitOfMeasureId: data.product.unitOfMeasureId,
                    },
                },
            });

            data.product = response.data.product.edit;
            props.onBarcodeSaveAction && props.onBarcodeSaveAction();
        } catch (error: any) {
            alert((error as GraphQLError)?.message ?? error?.message ?? "Unknown error");
        } finally {
            setShowBarcodeModal(false);
            setManualLoading(false);
        }
    };

    const printBarcode = async (value: string, barcodeLabelType: BarcodeLabelType, quantity?: number) => {
        try {
            if (loading || manualLoading || printersLoading || !value) return;
            setManualLoading(true);
            const printers = printersData?.printers?.items ?? [];
            const intakePrinters = printers.filter(
                (printer) => printer.type === PrinterType.Label && printer.stationSet?.type === StationSetType.Intake
            );
            if (intakePrinters.length < 1) {
                await showConfirmModal("No printers were found at any intake station", "No Printers", { confirm: "Confirm" }, true);
                return;
            }
            const printerId =
                intakePrinters.length === 1
                    ? intakePrinters[0].id
                    : intakePrinters.length < 1
                    ? null
                    : await (
                          await showSelectModal(
                              "Select Printer",
                              intakePrinters.map((printer) => ({ id: printer.id, description: printer.description } as ISelect))
                          )
                      )?.id;
            if (!printerId) return;
            for (let i = 0; i < (quantity ?? 1); i++) {
                await runBarcodeLabelMutation({
                    variables: { barcodeLabel: value, barcodeLabelType: barcodeLabelType, printerId: printerId },
                });
            }
        } catch (error: any) {
            alert((error as GraphQLError)?.message ?? "Unknown error");
        } finally {
            setManualLoading(false);
        }
    };

    const onKeyDownInputTypeNumberHandler = (e: React.KeyboardEvent<HTMLInputElement>) => {
        const acceptedKeys = ["Backspace", "ArrowUp", "ArrowDown", "ArrowLeft", "ArrowRight", "Delete"];
        if (isNaN(+e.key) && acceptedKeys.indexOf(e.key) < 0) {
            e.preventDefault();
        }
        if (e.key === "0" && !e.currentTarget.value) {
            e.preventDefault();
            e.currentTarget.value = "1";
        }
    };

    const printButtonStyle: CSSProperties = { color: "blue", textDecoration: "underline", border: "none", backgroundColor: "transparent" };
    const tdStyle: CSSProperties = {
        display: "flex",
        width: "100%",
        justifyContent: "end",
        gap: 10,
        alignItems: "center",
        margin: "10px 0",
    };
    const imgStyle: CSSProperties = { width: 25, height: 25 };
    const partNumbers = data?.product?.partNumbers ?? [];
    const skus = data?.product?.sku ? [data.product?.sku ?? ""] : [];
    const barcodes = data?.product?.barcodes ?? [];

    return (
        <>
            {loading || manualLoading || printersLoading ? <MobileLoading fullscreen /> : <></>}

            {props.primaryHeader}

            {props.includeSecondaryHeader && <SecondaryHeader nextButtonHandler={() => void 0} title={`${data?.product?.sku ?? ""}`} />}

            <MobileTable>
                <thead>
                    <tr>
                        <td>ID</td>
                        <td>Action</td>
                    </tr>
                </thead>
            </MobileTable>

            {/*Part Numbers*/}
            {partNumbers.length < 1 ? (
                <></>
            ) : (
                <div>
                    <MobileTableTitle>Part Numbers</MobileTableTitle>
                    <MobileTable>
                        <tbody>
                            {partNumbers
                                .sort((a, b) => a.sortOrder - b.sortOrder)
                                .map((partNumber, i) => {
                                    return (
                                        <tr key={partNumber.partNumber}>
                                            <td>{partNumber.partNumber}</td>
                                            <td style={tdStyle}>
                                                {/* <input
                                                    ref={partNumbersQuantityInputRef}
                                                    className={styles.inputTypeNumber}
                                                    type="number"
                                                    min={1}
                                                    defaultValue={1}
                                                    onKeyDown={(e) => onKeyDownInputTypeNumberHandler(e)}
                                                /> */}
                                                {/* <button
                                                    onClick={async () =>
                                                        await printBarcode(
                                                            partNumber.partNumber,
                                                            BarcodeLabelType.QR,
                                                            partNumbersQuantityInputRef?.current?.valueAsNumber
                                                        )
                                                    }
                                                    style={printButtonStyle}
                                                >
                                                    <img src={printerSVG} style={imgStyle} alt="an svg of a qr code" />
                                                </button> */}
                                            </td>
                                        </tr>
                                    );
                                })}
                        </tbody>
                    </MobileTable>
                </div>
            )}

            {/*Skus*/}
            {skus.length < 1 ? (
                <></>
            ) : (
                <div>
                    <MobileTableTitle>SKUs</MobileTableTitle>
                    <MobileTable>
                        <tbody>
                            {skus
                                .sort((a, b) => b.localeCompare(a))
                                .map((sku, i) => {
                                    return (
                                        <tr key={sku + i}>
                                            <td>{sku}</td>
                                            <td style={tdStyle}>
                                                <input
                                                    ref={skusQuantityInputRef}
                                                    className={styles.inputTypeNumber}
                                                    type="number"
                                                    min={1}
                                                    defaultValue={1}
                                                    onKeyDown={(e) => onKeyDownInputTypeNumberHandler(e)}
                                                />
                                                <button
                                                    onClick={async () =>
                                                        await printBarcode(
                                                            sku,
                                                            BarcodeLabelType.QR,
                                                            skusQuantityInputRef?.current?.valueAsNumber
                                                        )
                                                    }
                                                    style={printButtonStyle}
                                                >
                                                    <img src={printerSVG} style={imgStyle} alt="an svg of a qr code" />
                                                </button>
                                            </td>
                                        </tr>
                                    );
                                })}
                        </tbody>
                    </MobileTable>
                </div>
            )}

            {/*Barcodes*/}
            {barcodes.length < 1 ? (
                <></>
            ) : (
                <div>
                    <MobileTableTitle>Barcodes</MobileTableTitle>
                    <MobileTable>
                        <tbody>
                            {barcodes
                                .sort((a, b) => a.sortOrder - b.sortOrder)
                                .map((barcode, i) => {
                                    return (
                                        <tr key={barcode.barcode + i}>
                                            <td>{barcode.barcode}</td>
                                            <td style={tdStyle}>
                                                <button
                                                    onClick={async () => {
                                                        if (
                                                            !(await showConfirmModal(
                                                                "Are you sure you want to delete this barcode?",
                                                                "Delete Barcode?",
                                                                { confirm: "Yes", cancel: "No" }
                                                            ))
                                                        )
                                                            return;
                                                        await editBarcodes(barcode.barcode, EBarcodeEditType.Delete);
                                                    }}
                                                    style={printButtonStyle}
                                                >
                                                    <img src={trashSVG} style={imgStyle} alt="an svg of a qr code" />
                                                </button>
                                                {/* <input
                                                    ref={barcodesQuantityInputRef}
                                                    className={styles.inputTypeNumber}
                                                    type="number"
                                                    min={1}
                                                    defaultValue={1}
                                                    onKeyDown={(e) => onKeyDownInputTypeNumberHandler(e)}
                                                />
                                                <button
                                                    onClick={async () =>
                                                        await printBarcode(
                                                            barcode.barcode,
                                                            BarcodeLabelType.QR,
                                                            barcodesQuantityInputRef?.current?.valueAsNumber
                                                        )
                                                    }
                                                    style={printButtonStyle}
                                                >
                                                    <img src={printerSVG} style={imgStyle} alt="an svg of a qr code" />
                                                </button> */}
                                            </td>
                                        </tr>
                                    );
                                })}
                        </tbody>
                    </MobileTable>
                </div>
            )}

            {/*Buttons*/}
            <MobileButtonContainer>
                <MobileButtonRow>
                    <MobileButtonCol>
                        <MobileButton onClick={() => setShowBarcodeModal(true)}>Scan & Add Barcode</MobileButton>
                    </MobileButtonCol>
                </MobileButtonRow>
            </MobileButtonContainer>

            <Modal show={showBarcodeModal} onHide={() => setShowBarcodeModal(false)}>
                <Modal.Header closeButton>
                    <Modal.Title>Scan Barcode</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <input style={{ width: "100%", height: 38, borderRadius: 4 }} ref={barcodeInputRef} />
                </Modal.Body>
                <Modal.Footer style={{ width: "100%" }}>
                    <MobileButtonRow>
                        <MobileButton onClick={async () => editBarcodes(barcodeInputRef.current?.value, EBarcodeEditType.Add)}>
                            Save Barcode
                        </MobileButton>
                        <MobileButton onClick={() => setShowBarcodeModal(false)}>Close</MobileButton>
                    </MobileButtonRow>
                </Modal.Footer>
            </Modal>

            <div className={styles.imageContainer}>
                <ActiveProductImages sku={data?.product.sku} />
                <div>
                    <b>Category</b>: {data?.product?.description}
                </div>
            </div>

            {createElement(confirmModal)}

            {createElement(selectModal)}
        </>
    );
};

export default AddOrPrintBarcode;
